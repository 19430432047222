import React, { useEffect, useState } from 'react'
import CSVImporter, { CSVData } from 'components/FileUpload/CSVImport'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import { NoWrapText } from 'style'
import Table, { TableData } from 'components/modules/Table'
import Meta from 'components/modules/Head'
import { SchoolHoliday } from 'interfaces/SchoolHoliday'
import { processSchoolHolidayBatchCSVData, HeaderLabels, STANDARD_HEADERS } from 'lib/features/schoolHolidayBatchUpload'
import SchoolHolidaysService from 'services/SchoolHolidaysService'

const SchoolHolidayBulkUploadComponent = () => {
    const [holidayBatch, setHolidayBatch] = useState<SchoolHoliday[]>([])
    const [tableData, setTableData] = useState<TableData[]>()

    const handleCSVUploadComplete = async (csvData: CSVData[]) => {
        const headers = Object.keys(csvData[0])
        const headerLabels = Object.values(HeaderLabels)

        if (headers.length !== STANDARD_HEADERS.length) {
            toast.warn('Make sure to put correct headers (Name, Country Id, ...) as the first row of the CSV file!')
            return
        }

        for (let i = 0; i < headerLabels.length; i++) {
            if (headers[i] !== headerLabels[i]) {
                toast.warn(`${headers[i]} is not part of the standard headers (Name, Country Id, ...), check for typos!`)
                return
            }
        }

        const formattedHolidayBatchArray = await processSchoolHolidayBatchCSVData(csvData, toast)

        if (!formattedHolidayBatchArray || !formattedHolidayBatchArray.length) {
            toast.info('No school holiday batch data found')
            return
        }

        const progressText = document.getElementById('title')
        let count = 0
        const chunkSize = 50
        formattedHolidayBatchArray.unshift()
        for (let i = 0; i < formattedHolidayBatchArray.length; i += chunkSize) {
            const chunk = formattedHolidayBatchArray.slice(i, i + chunkSize)

            await SchoolHolidaysService.bulkSchoolHolidayUpload(chunk)
                // eslint-disable-next-line no-loop-func
                .then((holidays) => {
                    setHolidayBatch((prevHolidayBatch) => (prevHolidayBatch ? [...prevHolidayBatch, ...holidays] : holidays))
                    count += holidays.length
                })
                .catch((error: ApiError) => {
                    error.handleUnknown(`An error occurred while school holidays were being uploaded.`)
                })

            if (progressText) progressText.textContent = `Loading... Progress: ${i}/${csvData.length - 1}`
        }
        const text = `Imported ${count} school holidays.`
        if (progressText) progressText.textContent = text
        toast.info(text)
    }

    useEffect(() => {
        if (!holidayBatch?.length) return
        const table = holidayBatch.map((h, id) => ({
            id,
            columns: [
                <NoWrapText key={`name-${id}`}>{h.name}</NoWrapText>,
                h.countryId,
                new Date(h.endDate).toLocaleDateString(),
                new Date(h.startDate).toLocaleDateString(),
                h.isActive ? 'Yes' : 'No',
                h.regions.map((r) => r.id).join(', '),
            ],
        }))
        setTableData(table)
    }, [holidayBatch, setTableData])

    return (
        <section>
            <Meta title="Bulk School Holiday Upload" />
            <Stack>
                <h2>Bulk School Holiday Upload</h2>
                <Box>
                    <strong>Requirements:</strong>
                    <ul>
                        <li>
                            {`First row of the CSV file must contain correct Column Name: ${Object.values(HeaderLabels).join(
                                ', '
                            )} (in this order)`}
                        </li>
                        <li>{`All cell data values must be an exact match with values stored in the database`}</li>
                        <li>Dates must be in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ)</li>
                        <li>Region IDs must be comma-separated numbers</li>
                        <li>{`isActive must be either true or false`}</li>
                    </ul>
                    <br />
                    <strong>Example document:</strong>&nbsp;
                    <a href="/files/SchoolHolidayBatchUpload.csv" download="example_school_holiday_batch_upload.csv">
                        Download
                    </a>
                    <br />
                    <CSVImporter
                        title="CSV Upload area for school holiday data"
                        handleCSVUploadComplete={handleCSVUploadComplete}
                        showHeader
                    />
                    {tableData?.length ? (
                        <Table
                            columns={['Name', 'Country', 'End Date', 'Start Date', 'Is Active', 'Regions']}
                            data={tableData}
                            page={0}
                            totalPages={1}
                            totalElements={tableData.length}
                        />
                    ) : null}
                </Box>
            </Stack>
        </section>
    )
}

export default SchoolHolidayBulkUploadComponent
