import React from 'react'
import Table, { TableData } from 'components/modules/Table'
import { useEffect, useState } from 'react'
import { NoWrapText } from 'style'
import { PayoutBulkData } from './PayoutRequests'
import Stack from 'components/elements/Stack'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import PayoutRequestService from 'services/PayoutRequestService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import Button from 'components/elements/Button'
import { Body2, ButtonText, Headline5 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import JSZip from 'jszip'
import { formatAmount, formatDate } from 'utils'
import { generateCSVRowFromPayout, getPayoutCSVHeaders } from 'lib/features/payouts'
import { Currency } from 'interfaces/Transaction'
import { PayoutRequest } from 'interfaces/PayoutRequest'

const loadingAreas = {
    status: 'statusButton',
    export: 'exportButton',
    generate: 'generatingCsvFile',
}

interface Props {
    payoutBulkData: PayoutBulkData[]
}

const PayoutRequestActionDialog = ({ payoutBulkData: initialPayoutBulkData }: Props) => {
    const [payoutBulkData, setPayoutBulkData] = useState(initialPayoutBulkData)
    const [tableData, setTableData] = useState<TableData[]>()
    const zip = new JSZip()

    const areAllPayoutRequestAmountEmpty =
        payoutBulkData && payoutBulkData.every((p) => p.currency === 'null' && p.amount.toString() === 'null')
    const hasSomePayoutRequestAmountEmpty =
        payoutBulkData && payoutBulkData.some((p) => p.currency === 'null' && p.amount.toString() === 'null')
    const allowClosingInvoice = payoutBulkData && payoutBulkData.every((p) => p.status === 'OPEN')
    const allowInvoiceExport = payoutBulkData && payoutBulkData.every((p) => !!p.filePath && p.status === 'DONE')
    const allowSevDeskVoucherCreation = payoutBulkData && payoutBulkData.every((p) => p.currency === 'EUR')

    const generatePayoutTableData = (payoutBulkData: PayoutBulkData[]) =>
        setTableData(
            payoutBulkData.map((payout) => ({
                id: payout.slug,
                columns: [
                    payout.name,
                    payout.invoiceId,
                    payout.currency !== 'null' ? formatAmount(payout.amount, payout.currency as Currency) : 'Everything',
                    <NoWrapText key={`status-${payout.invoiceId}`}>{payout.status}</NoWrapText>,
                    payout?.filePath ? 'Yes' : 'No',
                ],
            }))
        )

    useEffect(() => {
        if (!payoutBulkData?.length) return

        generatePayoutTableData(payoutBulkData)
    }, [payoutBulkData])

    const generateBulkDataFromPayoutRequest = (payout: PayoutRequest): PayoutBulkData => ({
        slug: payout.uuid,
        invoiceId: `${payout.countryCode}${payout.invoiceID}`,
        name: payout.fullName ?? '',
        status: payout.status,
        filePath: payout.filePath,
        amount: payout.amount,
        currency: payout.currency,
        recipientEmail: payout.recipientEmail ?? '',
        iban: payout.iban ?? '',
        sortCode: payout.sortCode,
        accountNumber: payout.accountNumber,
        codeBank: payout.codeBank,
        accountType: payout.bankAccountType ? payout.bankAccountType.toLocaleUpperCase() : null,
        taxNumber: payout.taxNumber,
        branchCode: payout.branchCode,
    })

    const closeInvoices = async (promises: Promise<number | void>[], updatedBulkPayoutArray: PayoutBulkData[]) => {
        payoutBulkData.forEach(async ({ slug, invoiceId }) => {
            const promise = trackPromise(
                PayoutRequestService.close(slug)
                    .then((payout) => updatedBulkPayoutArray.push(generateBulkDataFromPayoutRequest(payout)))
                    .catch((error: ApiError) => {
                        if (error instanceof ApiError) {
                            if (error.type === ErrorTypes.NotFound) {
                                toast.error(`Payout request ${invoiceId} with this Uuid (${slug}) doesn't exist!`)
                                return
                            } else if (error.type === ErrorTypes.Duplicate) {
                                toast.error(`Payout request ${invoiceId} was already marked as done!`)
                                return
                            } else {
                                error.handleUnknown(`An error occurred while marking payout request ${invoiceId} as done.`)
                            }
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.status
            )
            promises.push(promise)
        })
    }

    const createSevDeskVoucher = async (promises: Promise<number | void>[], updatedBulkPayoutArray: PayoutBulkData[]) => {
        payoutBulkData.forEach(async ({ slug, invoiceId }) => {
            const promise = trackPromise(
                PayoutRequestService.createPayoutVoucher(slug)
                    .then((payout) => updatedBulkPayoutArray.push(generateBulkDataFromPayoutRequest(payout)))
                    .catch((error: ApiError) => {
                        if (error instanceof ApiError) {
                            if (error.type === ErrorTypes.NotFound) {
                                toast.error(`Payout request ${invoiceId} with this Uuid (${slug}) doesn't exist!`)
                                return
                            } else if (error.type === ErrorTypes.Duplicate) {
                                toast.error(`A voucher was already created for Payout request ${invoiceId}!`)
                                return
                            } else {
                                error.handleUnknown(
                                    `An error occurred while creating SevDesk Voucher for invoice: ${invoiceId}.`
                                )
                            }
                        } else {
                            throw error
                        }
                    }),

                loadingAreas.status
            )
            promises.push(promise)
        })
    }

    const setPayoutAmountToKnowerBalance = async (
        promises: Promise<number | void>[],
        updatedBulkPayoutArray: PayoutBulkData[]
    ) => {
        payoutBulkData.forEach(async ({ slug, invoiceId }) => {
            const promise = trackPromise(
                PayoutRequestService.setPayoutAmountToKnowerBalance(slug)
                    .then((payout) => updatedBulkPayoutArray.push(generateBulkDataFromPayoutRequest(payout)))
                    .catch((error: ApiError) => {
                        if (error instanceof ApiError) {
                            if (error.type === ErrorTypes.NotFound) {
                                toast.error(`Payout request ${invoiceId} with this Uuid (${slug}) doesn't exist!`)
                                return
                            } else {
                                error.handleUnknown('An error occurred while setting payout amount to knower balance.')
                            }
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.status
            )
            promises.push(promise)
        })
    }

    const createdVouchersForSelectedPayouts = async () => {
        if (!payoutBulkData.length) return

        const promises: Promise<number | void>[] = []

        const updatedPayoutBulkData: PayoutBulkData[] = []
        await createSevDeskVoucher(promises, updatedPayoutBulkData)

        await Promise.all(promises).then(() => {
            setPayoutBulkData(updatedPayoutBulkData)
        })
    }

    const setPayoutAmountToKnowerBalanceForSelectedPayouts = async () => {
        if (!payoutBulkData.length) return

        const promises: Promise<number | void>[] = []

        const updatedPayoutBulkData: PayoutBulkData[] = []
        await setPayoutAmountToKnowerBalance(promises, updatedPayoutBulkData)

        await Promise.all(promises).then(() => {
            setPayoutBulkData(updatedPayoutBulkData)
        })
    }

    const closeSelectedPayouts = async () => {
        if (!payoutBulkData.length) return

        if (
            !window.confirm(
                `Are you sure you want to mark the payout request as done? This reduces the balance of the user and sends an email with the invoice to the user.`
            )
        )
            return

        const promises: Promise<number | void>[] = []

        const updatedPayoutBulkData: PayoutBulkData[] = []
        await closeInvoices(promises, updatedPayoutBulkData)

        await Promise.all(promises).then(() => {
            setPayoutBulkData(updatedPayoutBulkData)
        })
    }

    const addInvoicePdfs = async (zip: JSZip, promises: Promise<void>[]) => {
        payoutBulkData.forEach(async (p) => {
            if (!p.filePath) return

            const promise = fetch(p.filePath)
                .then((data) => data.blob())
                .then((blob) => {
                    zip.file(`invoice_${p.invoiceId.slice(2)} (${p.invoiceId}).pdf`, blob)
                })
                .catch((e) => {
                    toast.error(`There was a problem when retrieving data for invoice ${p.invoiceId}`)
                    console.error(e)
                })
            promises.push(promise)
        })
    }

    const exportSelectedPayouts = async () => {
        if (!payoutBulkData.length) return

        const promises: Promise<void>[] = []

        await addInvoicePdfs(zip, promises)

        await Promise.all(promises).then(() => {
            zip.generateAsync({
                type: 'base64',
            }).then(function (content) {
                const link = document.createElement('a')
                link.href = 'data:application/zip;base64,' + content
                link.download = `invoices-${formatDate(new Date())}.zip`
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
        })
    }

    const validatePayouts = (useSortCode?: boolean) => {
        if (!payoutBulkData.length) return false

        const haveSomeEUPayouts = payoutBulkData.some((p) => p.invoiceId.slice(0, 2) !== 'GB')
        const haveSomeUKPayouts = payoutBulkData.some((p) => p.invoiceId.slice(0, 2) === 'GB')

        if (useSortCode && haveSomeEUPayouts) {
            toast.warn(
                'You have selected UK-Sort Code option, but there seems to be some EU payouts. Check the data and try again!'
            )

            return false
        }

        if (!useSortCode && haveSomeUKPayouts) {
            toast.warn(
                'You have selected EU-IBAN option, but there seems to be some UK payouts. Check the data and try again!'
            )
            return false
        }

        return true
    }

    const generatePayoutCSVFile = (options?: { useSortCode?: boolean; useClabe?: boolean; usePix?: boolean }) => {
        if (!payoutBulkData.length) return

        if (!validatePayouts(options?.useSortCode)) {
            return
        }

        let csv = getPayoutCSVHeaders(options).join(',') + '\n'

        payoutBulkData.forEach((row) => {
            csv += generateCSVRowFromPayout(row, options)
        })

        const csvFile = new Blob([csv], { type: 'text/csv' })

        const link = document.createElement('a')
        link.setAttribute('download', `Invoices-${formatDate(new Date())}.csv`)
        link.href = window.URL.createObjectURL(csvFile)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    return (
        <Stack>
            <Headline5 color={theme.colors.white} textAlign="center">
                Actions
            </Headline5>
            {!hasSomePayoutRequestAmountEmpty ? (
                <>
                    <Headline5 color={theme.colors.knowunityBlue}>1. Export Payouts as CSV</Headline5>
                    <ButtonText>Make sure the CSV file is correct before closing the payouts!</ButtonText>
                    <Button
                        noMargin
                        type="button"
                        onClick={() => generatePayoutCSVFile()}
                        fullWidth
                        hoverColor={theme.colors.deepSeaBlue}
                    >
                        {`Export Payout Requests as CSV (EU - IBAN)`}
                    </Button>

                    <Button
                        noMargin
                        type="button"
                        onClick={() => generatePayoutCSVFile({ useSortCode: true })}
                        fullWidth
                        hoverColor={theme.colors.deepSeaBlue}
                    >
                        {`Export Payout Requests as CSV (UK - Sort Code)`}
                    </Button>

                    <Button
                        noMargin
                        type="button"
                        onClick={() => generatePayoutCSVFile({ useClabe: true })}
                        fullWidth
                        hoverColor={theme.colors.deepSeaBlue}
                    >
                        {`Export Payout Requests as CSV (MX - Clabe)`}
                    </Button>
                    <Button
                        noMargin
                        type="button"
                        onClick={() => generatePayoutCSVFile({ usePix: true })}
                        fullWidth
                        hoverColor={theme.colors.deepSeaBlue}
                    >
                        {`Export Payout Requests as CSV (BR)`}
                    </Button>

                    <Headline5 color={theme.colors.knowunityBlue}>2. Close Payout</Headline5>
                    {allowSevDeskVoucherCreation ? (
                        <Button
                            noMargin
                            type="button"
                            onClick={createdVouchersForSelectedPayouts}
                            fullWidth
                            hoverColor={theme.colors.deepSeaBlue}
                        >
                            Create payout Vouchers on SevDesk
                        </Button>
                    ) : (
                        <Body2>{`If you want to create a voucher for selected payouts then all of them have to be in made in € - EUR Currency.`}</Body2>
                    )}
                    {allowClosingInvoice ? (
                        <Button
                            noMargin
                            type="button"
                            onClick={closeSelectedPayouts}
                            fullWidth
                            hoverColor={theme.colors.deepSeaBlue}
                        >
                            Mark Payout Requests as done
                        </Button>
                    ) : (
                        <Body2>{`If you want to mark selected payouts as done select only payouts with status "OPEN"`}</Body2>
                    )}
                    <Headline5 color={theme.colors.knowunityBlue}> 3. Export PDF Invoices</Headline5>
                    {allowInvoiceExport ? (
                        <Button
                            noMargin
                            type="button"
                            onClick={exportSelectedPayouts}
                            fullWidth
                            hoverColor={theme.colors.deepSeaBlue}
                        >
                            Download PDF Invoices for selected Payout Requests
                        </Button>
                    ) : (
                        <Body2>{`If you want to export invoice files select only payouts with status "DONE"`}</Body2>
                    )}
                </>
            ) : (
                <>
                    {areAllPayoutRequestAmountEmpty ? (
                        <>
                            <ButtonText>{`All the requests you've selected are "Everything" Payouts. Click on the button below to set the payout amount to the knower current balance for all ${payoutBulkData.length} payout/s.`}</ButtonText>
                            <Button
                                noMargin
                                type="button"
                                onClick={setPayoutAmountToKnowerBalanceForSelectedPayouts}
                                fullWidth
                                hoverColor={theme.colors.deepSeaBlue}
                            >
                                {`Set payout amounts to knower balance`}
                            </Button>
                        </>
                    ) : (
                        <ButtonText>{`It seems like you've selected some "Everything" payouts but you've also selected other payouts. In order to carry out actions on those payments select either only "Everything" or only standard payouts.`}</ButtonText>
                    )}
                </>
            )}
            <Headline5 color={theme.colors.white} textAlign="center">
                Selected entries
            </Headline5>
            {tableData ? (
                <Table
                    totalPages={0}
                    totalElements={0}
                    data={tableData}
                    columns={['Name', 'Invoice Id', 'Amount', 'Status', 'Has invoice attachment']}
                    page={0}
                />
            ) : null}
        </Stack>
    )
}

export default PayoutRequestActionDialog
