import ApiService from './ApiService'
import { SchoolFromSuggestion } from 'interfaces/SchoolSuggestion'
import { CountryCode } from 'interfaces/Country'
import { School } from 'interfaces/School'

const SchoolsService = {
    list(page: number, query: string, countryCode: CountryCode) {
        return ApiService.get(`/schools/search?page=${page}&query=${query}&country=${countryCode}`).then((response) => {
            return {
                schools: response.data.content as SchoolFromSuggestion[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    get(uuid: string) {
        return ApiService.get(`/schools/${uuid}`).then((response) => {
            return response.data as SchoolFromSuggestion
        })
    },

    create(data: Omit<SchoolFromSuggestion, 'uuid' | 'iconUrl'> & { countryId?: number }) {
        return ApiService.post(`/schools`, data).then((response) => {
            return response.data as SchoolFromSuggestion
        })
    },

    update(uuid: string, data: Omit<SchoolFromSuggestion, 'uuid' | 'iconUrl'>) {
        return ApiService.patch(`/schools/${uuid}`, data).then((response) => {
            return response.data as SchoolFromSuggestion
        })
    },

    search(query: string, country?: CountryCode) {
        return ApiService.get(
            `schools/search?query=${query}&academicTier=UNIVERSITY${country ? `&country=${country}` : ''}`
        ).then((response) => {
            return response.data.content as School[]
        })
    },
}

export default SchoolsService
