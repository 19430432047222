import React, { useCallback, useState } from 'react'
import KnowService from 'services/KnowService'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable, { RefactoredTableData } from 'components/modules/RefactoredTable'
import Box from 'components/elements/Box'
import routes from 'lib/constants/routes'
import SelectElement from 'components/inputs/SelectElement'
import { FilterFormGrid, NoWrapText } from 'style'
import ContentPreview from 'components/modules/ContentPreview/ContentPreview'
import { ContentTypes, Know, KnowStatus } from 'interfaces/Know'
import { ButtonLink } from 'components/elements/Button'
import { ButtonText } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import { formatDateTime } from 'utils'
import Modal from 'components/modules/Modal'
import { useToggleState } from 'utils/hooks/useToggleState'
import KnowStatusForm from './KnowStatusForm'
import SubjectService from 'pages/Subjects/SubjectService'
import { Subject } from 'interfaces/Subject'
import Search from 'components/elements/Search'
import KnowTransferForm from './KnowTransferForm'
import Tabs from 'components/modules/Tabs'
import KnowDeletingForm from './KnowDeletingForm'
import KnowInternalRatingForm from './KnowInternalRatingForm'
import CountriesService from 'services/CountriesService'

interface Props {
    knowerUuid: string
}

const loadingAreas = {
    container: 'knowerKnowsContainer',
}
const KnowerKnows = ({ knowerUuid }: Props) => {
    const [showKnowStatusModal, toggleKnowStatusModal] = useToggleState(false)
    const [knowsUuids, setKnowUuids] = useState<string[]>()
    const [exampleKnow, setExampleKnow] = useState<Know>()
    const [subject, setSubjects] = useState<Subject>()
    const [sortBy, setSortBy] = useState<string | null>(null)
    const [status, setStatus] = useState<KnowStatus>()
    const [searchTitleQuery, setSearchTitleQuery] = useState<string>('')
    const [subjectsOptions, setSubjectOptions] = useState<
        {
            label: string
            data: Subject | null
        }[]
    >()
    const [refetch, setRefetch] = useState(false)

    const knowStatusOptions = [
        {
            label: 'No filter',
            data: null,
        },
        ...Object.keys(KnowStatus).map((typeKey) => ({
            label: typeKey.replace(/([A-Z]+)/g, ' $1').replace(/^ /, ''),
            // eslint-disable-next-line
            // @ts-ignore
            data: KnowStatus[typeKey],
        })),
    ]

    const sortByOptions = [
        {
            label: 'Newest',
            data: 'date',
        },
        {
            label: 'Popularity',
            data: 'popularity',
        },
        {
            label: 'Likes',
            data: 'likes',
        },
    ]

    const fetchSubjects = useCallback((languageCode: string) => {
        trackPromise(
            CountriesService.list()
                .then(async (countries) => {
                    const countryLanguagesMap = new Map<string, string[]>()

                    await Promise.all(
                        countries.map(async (country) => {
                            const languages = await CountriesService.listLanguages(country.code)
                            countryLanguagesMap.set(
                                country.code,
                                languages.map((lang) => lang.code)
                            )
                        })
                    )

                    const countryCode = Array.from(countryLanguagesMap.entries()).find(([_, languages]) =>
                        languages.includes(languageCode)
                    )?.[0]

                    if (!countryCode) {
                        console.warn(`No country found for language code: ${languageCode}`)
                        return
                    }

                    return SubjectService.list(countryCode).then((subjects) => {
                        const options = subjects
                            .sort((a, b) => (a.name < b.name ? -1 : 1))
                            .map((s) => ({ label: s.name, data: s }))
                        setSubjectOptions([{ label: 'No filter', data: null }, ...options])
                    })
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while fetching the data.')
                })
        )
    }, [])

    const fetchData = useCallback(
        (page: number, limit?: number, nextCursor?: string) => {
            return trackPromise(
                KnowService.listByKnower(knowerUuid, nextCursor, status, limit, subject?.id, searchTitleQuery, sortBy)
                    .then((data) => {
                        setRefetch(false)
                        const isFilteredWithReview = status === KnowStatus.InReview
                        const redirectUuids = data.knows.map((k) => k.uuid)
                        const firstKnow = data?.knows?.length ? data.knows[0] : null

                        if (firstKnow) {
                            fetchSubjects(firstKnow.contentLanguage.code)
                            setExampleKnow(firstKnow)
                        }
                        return {
                            totalPages: data.nextCursor ? page + 2 : ++page,
                            totalElements: data.knows.length,
                            nextCursor: data.nextCursor,
                            elements: data.knows.map((k) => ({
                                id: k.uuid,
                                columns: [
                                    k.uuid,
                                    k.title,
                                    k?.knowType ? k.knowType?.name : 'not specified',
                                    <NoWrapText key={k.uuid}>{k.status}</NoWrapText>,
                                    k.subjects.map((s) => s.name).join(', '),
                                    k.grades.map((g) => g.shortName).join(', '),
                                    k.likes,
                                    k?.publishedOn ? formatDateTime(k.publishedOn) : 'not yet',
                                    <ContentPreview
                                        key={`preview-${k.uuid}`}
                                        contentType={ContentTypes.pdf}
                                        previewUrl={k.thumbnailLargeUrl}
                                    />,
                                    <ButtonLink
                                        key={`know-redirect-button-${k.uuid}`}
                                        fullWidth={false}
                                        to={
                                            isFilteredWithReview
                                                ? routes.knowRoute(k.uuid, { redirectUuids })
                                                : routes.knowRoute(k.uuid)
                                        }
                                    >
                                        <ButtonText textAlign="center" color={theme.colors.white}>
                                            Go to know
                                        </ButtonText>
                                    </ButtonLink>,
                                ],
                            })),
                        }
                    })
                    .catch((error: ApiError) => {
                        error.handleUnknown('An error occurred while getting knows of knower.')
                    }),
                loadingAreas.container
            )
        },
        [fetchSubjects, knowerUuid, searchTitleQuery, sortBy, status, subject?.id]
    )

    const handleBulkStatusClick = useCallback((elements: RefactoredTableData[]) => {
        setKnowUuids(elements.map(({ id }) => id.toString()))
        toggleKnowStatusModal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSuccess = () => {
        setKnowUuids(undefined)
        toggleKnowStatusModal()

        setTimeout(() => {
            setRefetch(true)
        }, 2000)
    }

    const handleUpdateSubject = (subject: Subject) => setSubjects(subject)

    const handleSearch = (query: string) => setSearchTitleQuery(query)

    const handleSortBy = (sortBy: string) => setSortBy(sortBy)

    const handleUpdateStatus = (value: KnowStatus) => setStatus(value)

    return (
        <Box>
            <FilterFormGrid gridAmount={2}>
                <SelectElement
                    label="Status"
                    noMargin
                    options={knowStatusOptions}
                    onUpdate={handleUpdateStatus}
                    defaultValue={KnowStatus.Public}
                />
                {subjectsOptions && (
                    <SelectElement
                        label="Filter by subject"
                        noMargin
                        options={subjectsOptions}
                        onUpdate={handleUpdateSubject}
                    />
                )}
            </FilterFormGrid>
            <FilterFormGrid gridAmount={2}>
                <SelectElement label="Sort by" noMargin options={sortByOptions} onUpdate={handleSortBy} />
                <Search onSearch={handleSearch} fullWidth label="Search by title..." />
            </FilterFormGrid>
            <RefactoredTable
                columns={[
                    '#',
                    'Title',
                    'Type',
                    'Status',
                    'Subjects',
                    'Grades',
                    'Likes',
                    'Published on',
                    'Preview',
                    'Action',
                ]}
                loadData={fetchData}
                onAllEntries={(elements) => handleBulkStatusClick(elements)}
                allEntriesButtonText="Perform actions on selected knows"
                allowTableLimitChange
                loadingArea={loadingAreas.container}
                refetchKey={`${status}_${refetch}_${subject}_${searchTitleQuery}_${sortBy}`}
            />
            {knowsUuids?.length && exampleKnow ? (
                <Modal
                    show={showKnowStatusModal}
                    onClose={toggleKnowStatusModal}
                    title={`Perform actions on ${knowsUuids.length} selected Knows`}
                >
                    <Tabs deactivateUrlParam>
                        <div data-label="Publish with Rating">
                            <KnowInternalRatingForm knowUuids={knowsUuids} onSuccess={onSuccess} />
                        </div>
                        <div data-label="Update status">
                            <KnowStatusForm knowUuids={knowsUuids} onSuccess={onSuccess} exampleKnow={exampleKnow} />
                        </div>
                        <div data-label="Transfer Knows">
                            <KnowTransferForm knowUuids={knowsUuids} onSuccess={onSuccess} />
                        </div>
                        <div data-label="Deleting Knows">
                            <KnowDeletingForm knowUuids={knowsUuids} onSuccess={onSuccess} />
                        </div>
                    </Tabs>
                </Modal>
            ) : null}
        </Box>
    )
}

export default KnowerKnows
