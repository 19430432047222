import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import Meta from 'components/modules/Head'
import SchoolSuggestionCreateForm from './SchoolSuggestionCreateForm'
import SchoolCreateService from 'services/SchoolCreateService'
import { SchoolSuggestion } from 'interfaces/SchoolSuggestion'
import { MetaBox } from 'pages/Knows/Know/Know.styles'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import { isValidLink } from 'utils/isValidHttpUrl'

interface Props extends RouteComponentProps<{ uuid: string }> {}

const loadingAreas = {
    container: 'schoolSuggestionContainer',
}

const SchoolSuggestionComponent = (props: Props) => {
    const [schoolSuggestion, setSchoolSuggestion] = useState<SchoolSuggestion | null>(null)
    const uuid = props.match.params.uuid

    const fetchData = useCallback(() => {
        if (!uuid) return

        trackPromise(
            SchoolCreateService.get(uuid)
                .then((suggestion) => {
                    setSchoolSuggestion(suggestion)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting competition.')
                }),
            loadingAreas.container
        )
    }, [uuid])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <section>
            <Meta title="School suggestion" />
            <h2>School suggestion</h2>
            <GoBackButton route={routes.schoolsSuggestionsRoute} routeName="School suggesions" />
            <section>
                <Box>
                    <Stack>
                        <MetaBox>
                            <h3>Information</h3>
                        </MetaBox>
                        <p>
                            <strong>Academic Tier:</strong>&nbsp;
                            {schoolSuggestion?.academicTier}
                        </p>
                        <p>
                            <strong>Website URL:</strong>&nbsp;
                            {schoolSuggestion?.websiteUrl ? (
                                isValidLink(schoolSuggestion.websiteUrl) ? (
                                    <a
                                        href={
                                            schoolSuggestion.websiteUrl.startsWith('http')
                                                ? schoolSuggestion.websiteUrl
                                                : `https://${schoolSuggestion.websiteUrl}`
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {schoolSuggestion.websiteUrl}
                                    </a>
                                ) : (
                                    `${schoolSuggestion.websiteUrl} (invalid URL)`
                                )
                            ) : (
                                'N/A'
                            )}
                        </p>
                    </Stack>
                </Box>
            </section>
            <SchoolSuggestionCreateForm schoolSuggestion={schoolSuggestion} schoolSuggestionUuid={uuid} />
        </section>
    )
}

export default SchoolSuggestionComponent
