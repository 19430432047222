import React, { useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import { FormHandles, SubmitHandler } from '@unform/core'
import Button from 'components/elements/Button'
import { Input } from 'components/inputs/Input'
import LoadingArea from 'components/elements/LoadingArea'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import KnowService from 'services/KnowService'
import { Know, KnowStatus } from 'interfaces/Know'
import KnowRejectModal from 'components/features/knows/KnowRejectModal'
import { useToggleState } from 'utils/hooks/useToggleState'
import SelectElement from 'components/inputs/SelectElement'
import { KnowRejectionReason } from 'interfaces/KnowRejectionReasons'
import { filterRejectionReasonsByVialionLevel } from 'lib/features/knows'

const loadingAreas = {
    save: 'saveKnow',
    form: 'knowForm',
}

interface Props {
    onSuccess: () => void
    exampleKnow: Know
    knowUuids: string[]
}

type FormData = {
    reason: string
}

const KnowStatusForm = ({ knowUuids, onSuccess, exampleKnow }: Props) => {
    const [selectedStatus, setSelectedStatus] = useState<KnowStatus>(KnowStatus.InReview)
    const [showRejectionModal, toggleRejectionModal] = useToggleState(false)
    const [selectedReason, setSelectedReason] = useState<string>('')
    const formRef = useRef<FormHandles>(null)

    const isRejectOptionShown = selectedStatus === KnowStatus.Rejected || selectedStatus === KnowStatus.PermanentlyRejected
    const [showContentSafetyOptions, toggleContentSafetyOptions] = useToggleState(false)
    const [contentQualityRejectionReasons, setQualityRejectionReasons] = useState<KnowRejectionReason[]>([])
    const [contentSafetyRejectionReasons, setSafetyRejectionReasons] = useState<KnowRejectionReason[]>([])

    useEffect(() => {
        KnowService.getRejectionReasons()
            .then((rejectionReasons) => {
                setQualityRejectionReasons(filterRejectionReasonsByVialionLevel(rejectionReasons, 'QUALITY'))
                setSafetyRejectionReasons(filterRejectionReasonsByVialionLevel(rejectionReasons, 'SAFETY'))
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting rejection reasons.')
                } else {
                    throw error
                }
            })
    }, [])

    const handleKnowBulkStatusUpdate = async (status: KnowStatus, reason: string | null) => {
        await knowUuids.map((id) =>
            trackPromise(
                KnowService.updateStatus(id, status, reason).catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while updating know status.')
                    }
                }),
                loadingAreas.save
            )
        )
        toast.success(`Status of ${knowUuids.length} knows have been changed!`)
        onSuccess()
    }

    const onRejectMessageClick = async (rejectionReason: KnowRejectionReason) => {
        await handleKnowBulkStatusUpdate(rejectionReason.newStatus, rejectionReason.name)
    }

    const handleSubmit: SubmitHandler<FormData> = async (data) => {
        formRef.current!.setErrors({})

        if (selectedStatus === KnowStatus.Draft) {
            toggleRejectionModal()
        } else {
            await handleKnowBulkStatusUpdate(selectedStatus, isRejectOptionShown ? selectedReason : data.reason)
        }
    }

    const rejectReasonsSource = showContentSafetyOptions ? contentSafetyRejectionReasons : contentQualityRejectionReasons

    const rejectReasons = rejectReasonsSource.map((reason) => ({
        label: reason.name,
        data: reason.name,
    }))

    const statusTypeOptions = Object.keys(KnowStatus).map((typeKey) => ({
        // eslint-disable-next-line
        // @ts-ignore
        label: KnowStatus[typeKey],
        // eslint-disable-next-line
        // @ts-ignore
        data: KnowStatus[typeKey],
    }))

    const handleStatusChanged = (status: KnowStatus) => setSelectedStatus(status)

    const rejectReasonChanged = (reason: string) => setSelectedReason(reason)

    return (
        <div>
            <LoadingArea area={loadingAreas.form}>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    {statusTypeOptions && (
                        <SelectElement label="Status" options={statusTypeOptions} onUpdate={handleStatusChanged} noMargin />
                    )}
                    {selectedStatus !== KnowStatus.Draft ? (
                        isRejectOptionShown ? (
                            <>
                                <SelectElement
                                    label="Reason"
                                    options={rejectReasons}
                                    onUpdate={rejectReasonChanged}
                                    noMargin
                                />
                                <Button
                                    type="button"
                                    fullWidth
                                    noMargin
                                    onClick={toggleContentSafetyOptions}
                                    hoverColor="#3a5791"
                                >
                                    {`Show Content ${showContentSafetyOptions ? 'Quality' : 'Safety'} Options`}
                                </Button>
                            </>
                        ) : (
                            <Input name="reason" placeholder="Reason" />
                        )
                    ) : null}

                    <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                        Update statuses
                    </Button>
                </Form>
            </LoadingArea>
            <KnowRejectModal
                know={exampleKnow}
                show={showRejectionModal}
                onClose={toggleRejectionModal}
                onReject={onRejectMessageClick}
            />
        </div>
    )
}

export default KnowStatusForm
