import { CountryCode } from './Country'
import { Currency } from './Transaction'

export enum PayoutDestination {
    Transaction = 'TRANSACTION',
    PayPal = 'PAYPAL',
    Donation = 'DONATION',
    AmazonGiftCard = 'AMAZON_GIFTCARD',
    SpotifyGiftCard = 'SPOTIFY_GIFTCARD',
    GooglePlayGiftCard = 'GOOGLE_PLAY_STORE_GIFTCARD',
    NetflixGiftCard = 'NETFLIX_GIFTCARD',
    DonationForUkraine = 'DONATION_FOR_UKRAINE',
}

enum PayoutStatus {
    Open = 'OPEN',
    Refused = 'REFUSED',
    Done = 'DONE',
}

export enum PayoutRejectionReason {
    WrongPayoutDetails = 'WRONG_PAYOUT_DETAILS',
    MultipleAccounts = 'MULTIPLE_ACCOUNTS',
    PlagiarisedContent = 'PLAGIARIZED_CONTENT',
}

export interface PayoutRequest {
    address: string | null
    amount: number
    closedOn: string | null
    createdOn: string
    currency: Currency
    destination: PayoutDestination
    filePath: string | null
    fullName: string | null
    iban: string | null
    invoiceID: number
    isMandateAccepted: boolean
    isOpen: true
    knowerUUID: string
    recipientEmail: string | null
    taxNumber: string | null
    uuid: string
    closedByUserUuid: string | null
    countryCode: CountryCode
    status: PayoutStatus
    sortCode: string | null
    accountNumber: string | null
    sevDeskVoucherId: string | null
    payPalBatchId: string | null
    payPalTransactionId: string | null
    payPalTransactionStatus: string | null
    payPalTransactionUrl: string | null
    ipAddressCountry: string | null
    postalCode: string | null
    //nequi
    phoneNumber: string | null
    bankAccountNumber: string | null
    bankAccountType: BankAccountType | null
    city: string | null
    firstName: string | null
    lastName: string | null
    codeBank: BanksCode | null
    documentNumber: string | null
    documentType: NequiDocumentType | null
    region: string | null
    branchCode: string | null
}

export interface PossiblePaymentRequestFraud {
    uuid: string
    createdOn: string
    knowerUuid: string
    amount: number
    currency: Currency
    destination: PayoutDestination
    status: PayoutStatus
    recipientEmail: string | null
    iban: string | null
    taxNumber: string | null
}

export enum NequiDocumentType {
    CC = 'Cédula de Ciudadanía (CC)',
    CE = 'Cédula de Extranjería (CE)',
    Passport = 'Passport',
}

export enum BankAccountType {
    Savings = 'Ahorros',
    Current = 'Corriente',
}

export enum Regions {
    Amazonas = 'Amazonas',
    Antioquia = 'Antioquia',
    Arauca = 'Arauca',
    Atlántico = 'Atlántico',
    Bolívar = 'Bolívar',
    Boyacá = 'Boyacá',
    Caldas = 'Caldas',
    Caquetá = 'Caquetá',
    Casanare = 'Casanare',
    Cauca = 'Cauca',
    Cesar = 'Cesar',
    Chocó = 'Chocó',
    Cundinamarca = 'Cundinamarca',
    Córdoba = 'Córdoba',
    DistritoCapital = 'Distrito Capital',
    Guainía = 'Guainía',
    Guaviare = 'Guaviare',
    Huila = 'Huila',
    LaGuajira = 'La Guajira',
    Magdalena = 'Magdalena',
    Meta = 'Meta',
    Nariño = 'Nariño',
    NortedeSantander = 'Norte de Santander',
    Putumayo = 'Putumayo',
    Quindío = 'Quindío',
    Risaralda = 'Risaralda',
    SanAndrés = 'San Andrés',
    Santander = 'Santander',
    Sucre = 'Sucre',
    Tolima = 'Tolima',
    Valle = 'Valle',
    Vaupés = 'Vaupés',
    Vichada = 'Vichada',
}

export enum BanksCode {
    BancoDeBogota = '01',
    BancoPopular = '02',
    BancoItau = '06',
    Bancolombia = '07',
    BancoCitibankColombia = '09',
    BancoGNBSudameris = '12',
    BBVA = '13',
    ScotiabankRedMultibancaColpatriaSA = '19',
    BancoDeOccidente = '23',
    BancoCajaSocial = '32',
    BancoAgrario = '40',
    BancoDaviviendaSA = '51',
    BancoAVVillas = '52',
    BancoWSA = '53',
    BancoCredifinancieraSA = '58',
    Bancamia = '59',
    BancoPichinchaSA = '60',
    Bancoomeva = '61',
    BancoFalabellaSA = '62',
    BancoFinandinaSA = '63',
    BancoSantanderDeNegocios = '65',
    BancoCooperativoCoopcentral = '66',
    MibancoSA = '67',
    BancoSerfinanza = '69',
    BancoUnion = '73',
}
