import React from 'react'

import Button, { ButtonLink } from 'components/elements/Button'
import { MetaBox } from '../Know.styles'
import { Know, Know as KnowInterface, KnowStatus } from 'interfaces/Know'
import KnowInternalRating from 'components/features/knows/KnowInternalRating'
import Stack from 'components/elements/Stack'
import { ReactComponent as BackIcon } from 'assets/icons/chefron-left.svg'
import routes from 'lib/constants/routes'
import theme from 'lib/constants/theme'
import { useActionsMenuBar } from 'utils/hooks/useActionsMenuBar'
import KnowRejectModal from 'components/features/knows/KnowRejectModal'

interface ActionsMenuSectionProps {
    know: KnowInterface
    updateKnow: (know: Know) => void
    isKnowInReview: boolean
    showEditKnowModal: boolean
}

export const ActionsMenuSection = ({ know, updateKnow, isKnowInReview, showEditKnowModal }: ActionsMenuSectionProps) => {
    const {
        handleControlButton,
        handlePublishButton,
        moveKnow,
        putKnowIntoExtendedReview,
        onKnowInternalRatingUpdate,
        showRejectModal,
        setShowRejectModal,
        rejectKnow,
        deleteKnow,
    } = useActionsMenuBar({ updateKnow, knowUuid: know.uuid, isKnowInReview, showEditKnowModal })

    const isKnowPublishable =
        isKnowInReview || know.status === KnowStatus.Rejected || know.status === KnowStatus.RejectionAppealed

    return (
        <MetaBox>
            <Stack>
                <ButtonLink to={routes.knowsRoute()} fullWidth noMargin icon={<BackIcon />}>
                    Go back to Knows
                </ButtonLink>
                {isKnowPublishable ? (
                    <Button
                        onClick={handlePublishButton}
                        fullWidth
                        noMargin
                        color="var(--green)"
                        hoverColor="var(--green-dark)"
                        title="Press 'p' to publish (keyboard shortcut)"
                    >
                        Publish know
                    </Button>
                ) : null}
                <Button
                    onClick={handleControlButton}
                    fullWidth
                    noMargin
                    color="var(--orange)"
                    hoverColor="var(--orange-dark)"
                    title="Press 'c' to control (keyboard shortcut)"
                >
                    Control know
                </Button>
                {!isKnowInReview && (
                    <Button
                        onClick={moveKnow}
                        fullWidth
                        noMargin
                        color={theme.colors.accentBlue}
                        hoverColor={theme.colors.darkerAccentBlue}
                        title="Press 'm' to move (keyboard shortcut)"
                    >
                        Transfer know
                    </Button>
                )}
                {isKnowInReview && (
                    <Button
                        onClick={putKnowIntoExtendedReview}
                        fullWidth
                        noMargin
                        color="var(--blue)"
                        hoverColor="var(--blue-dark)"
                        title="Press 'e' to extend review (keyboard shortcut)"
                    >
                        Extend review
                    </Button>
                )}
                <Button
                    onClick={deleteKnow}
                    noMargin
                    fullWidth
                    color="var(--red)"
                    hoverColor="var(--red-dark)"
                    title="Press 'd' to delete (keyboard shortcut)"
                >
                    Delete know
                </Button>

                <KnowInternalRating know={know} onUpdateKnow={onKnowInternalRatingUpdate} />
            </Stack>
            {showRejectModal && (
                <KnowRejectModal
                    know={know}
                    show={showRejectModal}
                    onClose={() => setShowRejectModal(false)}
                    onReject={rejectKnow}
                />
            )}
        </MetaBox>
    )
}
