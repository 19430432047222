import React, { useRef } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import Button from 'components/elements/Button'
import { Checkbox } from 'components/inputs/Input'
import UserService from 'services/UserService'
import { User, UserVerificationStatus as UserVerification } from 'interfaces/User'
import Box from 'components/elements/Box'
import { Body1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'

interface Props {
    user: User
    onUpdate(user: User): void
    userVerification?: UserVerification
}

interface FormData {
    isAmbassador: boolean
    isCommunityModerator: boolean
    isStaff: boolean
}

const UserVerificationStatus = (props: Props) => {
    const formRef = useRef<FormHandles>(null)

    const handleSubmit: SubmitHandler<FormData> = ({ isAmbassador, isCommunityModerator, isStaff }) => {
        formRef.current!.setErrors({})

        const requestBody = {
            isAmbassador,
            isCommunityModerator,
            isStaff,
        }

        UserService.updateVerificationStatus(props.user.uuid, requestBody)
            .then(() => {
                props.onUpdate({
                    ...props.user,
                    isAmbassador,
                    isCommunityModerator,
                })
                toast.success('The verification status has been saved.')
            })
            .catch((error: ApiError) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while updating verification status.')
                } else {
                    throw error
                }
            })
    }

    const initialData = {
        isAmbassador: props.user.isAmbassador,
        isCommunityModerator: props.userVerification?.isCommunityModerator,
        isStaff: props.userVerification?.isStaff,
    }

    return (
        <Box>
            <Body1 color={theme.colors.white}>Update user verification</Body1>
            <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                <Checkbox name="isAmbassador" placeholder="Ambassador" />
                <Checkbox name="isCommunityModerator" placeholder="Community Moderator" />
                <Checkbox name="isStaff" placeholder="Staff" />
                <Button fullWidth>Save</Button>
            </Form>
        </Box>
    )
}

export default UserVerificationStatus
