import React, { useCallback, useEffect, useRef } from 'react'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import UserService from 'services/UserService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { NewUser, User as UserInterface, UserVerificationStatus as UserVerification } from 'interfaces/User'
import { ApiError } from 'services/ApiService'
import { Form } from '@unform/web'
import { Input } from 'components/inputs/Input'
import { toast } from 'react-toastify'
import Button from 'components/elements/Button'
import { FormHandles, SubmitHandler } from '@unform/core/typings/types'
import { trackPromise } from 'react-promise-tracker'
import LoadingArea from 'components/elements/LoadingArea'
import { useState } from 'react'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import Tabs from 'components/modules/Tabs'
import UserGeneral from './UserGeneral'
import UserAction from './UserAction'
import routes from 'lib/constants/routes'
import { Body1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import Knower from 'pages/Knower/Knower'
import GoBackButton from 'components/elements/GoBackButton'
import UserNewsletterSettings from './UserNewsletterSettings'
import UserVerificationStatus from 'components/features/users/UserVerificationStatus'
import Stack from 'components/elements/Stack'
import UserSafety from './UserSafety'
import UserRoles from './UserRoles'
import Meta from 'components/modules/Head'
import UserSubscription from './UserSubscription'
import { Warning } from 'style'

interface Props extends RouteComponentProps<{ id: string }> {}

const loadingAreas = {
    save: 'saveUser',
    container: 'userContainer',
}

interface FormData {
    name: string
    email: string
}

const User = (props: Props) => {
    const addUserFormRef = useRef<FormHandles>(null)
    const [user, setUser] = useState<UserInterface>()
    const [userVerificationStatus, setUserVerificationStatus] = useState<UserVerification>()
    const history = useHistory()

    const userUuid = props.match.params.id
    const isNew = userUuid === 'create'

    const fetchData = useCallback(() => {
        trackPromise(
            UserService.get(userUuid)
                .then((user) => {
                    setUser(user)
                    UserService.getUserVerificationStatus(userUuid)
                        .then((userVerification) => {
                            setUserVerificationStatus(userVerification)
                        })
                        .catch((error) => {
                            if (error instanceof ApiError) {
                                error.handleUnknown('An error occurred while getting user.')
                            } else {
                                throw error
                            }
                        })
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting user.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [userUuid])

    useEffect(() => {
        !isNew && fetchData()
    }, [fetchData, isNew])

    const handleAddUserSubmit: SubmitHandler<FormData> = (data) => {
        addUserFormRef.current!.setErrors({})

        const newUser: NewUser = {
            countryCode: 'DE',
            name: data.name,
            email: data.email,
            interfaceLanguageCode: 'de',
        }

        trackPromise(
            UserService.create(newUser)
                .then((user) => {
                    toast.success(`An email with a link for setting the password has been sent to the user`)
                    history.push(routes.userRoute(user.uuid))
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, addUserFormRef.current!)
                        } else if (error.type === ErrorTypes.EmailInUse) {
                            toast.warn("Can't create user! This email is already in use.")
                            return
                        } else {
                            error.handleUnknown('An error occurred while adding the user.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    const updateUser = (user: UserInterface) => setUser(user)

    return (
        <section>
            <Meta title="User" />
            <h2>{isNew ? 'Create New User' : 'User'}</h2>
            <GoBackButton route={routes.usersRoute} routeName="Users" />

            <LoadingArea area={loadingAreas.container}>
                {user ? (
                    <Stack>
                        {user.deletedOn ? (
                            <Warning>{`This user was deleted on ${new Date(user.deletedOn).toUTCString()}!`}</Warning>
                        ) : null}
                        <Tabs>
                            <div data-label="General">
                                <UserGeneral user={user} userVerification={userVerificationStatus} />
                            </div>
                            <div data-label="Knower Information">
                                {user?.knowerUuid ? (
                                    <Knower knowerUuid={user.knowerUuid} />
                                ) : (
                                    <Body1 color={theme.colors.white} textAlign="center">
                                        This user is not a Knower!
                                    </Body1>
                                )}
                            </div>
                            <div data-label="Safety">
                                <UserSafety user={user} fetchData={fetchData} />
                            </div>
                            <div data-label="Newsletter Settings">
                                <UserNewsletterSettings user={user} />
                            </div>
                            <div data-label="Actions">
                                <Stack>
                                    <UserVerificationStatus
                                        user={user}
                                        onUpdate={updateUser}
                                        userVerification={userVerificationStatus}
                                    />
                                    <UserAction user={user} fetchData={fetchData} />
                                </Stack>
                            </div>
                            <div data-label="Subscription">
                                <UserSubscription user={user} onUpdate={updateUser} />
                            </div>
                            <div data-label="Roles">
                                <UserRoles user={user} fetchData={fetchData} />
                            </div>
                        </Tabs>
                    </Stack>
                ) : isNew ? (
                    <Form ref={addUserFormRef} onSubmit={handleAddUserSubmit}>
                        <Input name="name" placeholder="Name" />
                        <Input name="email" placeholder="Email" />
                        <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                            {isNew ? 'Create' : 'Save'}
                        </Button>
                    </Form>
                ) : (
                    <>
                        <Body1 color={theme.colors.white} textAlign="center">
                            <br />
                            {`User with this uuid doesn't exist. Check again if you have the correct uuid of the user!`}
                        </Body1>
                        <Body1 color={theme.colors.white} textAlign="center">
                            <br />
                            {`Feel free to contact the tech team if you can't resolve this problem.`}
                        </Body1>
                    </>
                )}
            </LoadingArea>
        </section>
    )
}

export default withRouter(User)
