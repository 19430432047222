/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors, uniqueBy } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Checkbox, FormSelect, Input, SelectMultiple, SelectOption, SelectOptionWithLabel } from 'components/inputs/Input'
import routes from 'lib/constants/routes'
import { Subject, SubjectCategory, UpdateSubject } from 'interfaces/Subject'
import SubjectService from './SubjectService'
import { Country, CountryCode } from 'interfaces/Country'
import { Grade } from 'interfaces/Grade'
import CountriesService from 'services/CountriesService'
import ModalSelect, { SelectOptionWithLabel as ModalSelectOptionWithLabel } from 'components/inputs/ModalSelect'
import { subjectIconUrl } from 'lib/constants/urls'
import GradeSelection from 'components/forms/GradeSelection/GradeSelection'
import LanguageService from 'services/LanguageService'
import { LanguageInterface } from 'interfaces/Language'
import { ExamType } from 'interfaces/ExamType'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import SchoolTypeService from 'services/SchoolTypeService'
import { SchoolType } from 'interfaces/SchoolType'
import styled from 'styled-components'
import { Body1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import Row from 'components/elements/Row'
import Box from 'components/elements/Box'
import { KnowledgeArea } from 'interfaces/KnowledgeArea'
import KnowledgeAreaService from 'services/KnowledgeAreaService'
import { useCountryOptions } from 'hooks/useCountryOptions'

interface FormData {
    sortId: string
    category: SelectOption<SubjectCategory>
    grades: Grade[]
    country: SelectOptionWithLabel<Country>
    iconName: SelectOption<string>
    longName: string
    englishName: string
    name: string
    language: SelectOptionWithLabel<LanguageInterface>
    examType: SelectOption<ExamType | null>
    synonyms: string
    useForSearchTermFilter: boolean
    isHidden: boolean
    schoolTypes: SelectOption<SchoolType>[]
    knowledgeAreas: SelectOption<KnowledgeArea>
}

interface Props {
    subject: Subject | null
    isNew: boolean
    updateSubject: (subject: Subject) => void
}

const loadingAreas = {
    form: 'subjectsForm',
    save: 'saveSubject',
    mappedSubjects: 'mappedSubjects',
}

const SearchSubjectWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
    }
    .subject-option {
        cursor: pointer;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
            fill: white;
        }
    }
`

const SubjectForm = ({ isNew, subject, updateSubject }: Props) => {
    const [initialData, setInitialData] = useState<FormData>()
    const [countryOptions, setCountryOptions] = useState<{ label: string; identifier: string; data: Country }[]>([])
    const [categoriesOptions, setCategoriesOptions] = useState<
        { label: string; identifier: string; data: SubjectCategory }[]
    >([])
    const [iconNameOptions, setIconNamesOptions] = useState<ModalSelectOptionWithLabel<string>[]>([])
    const [languageOptions, setLanguageOptions] = useState<SelectOptionWithLabel<LanguageInterface>[]>()
    const [knowledgeAreasOptions, setKnowledgeAreasOptions] = useState<SelectOptionWithLabel<KnowledgeArea>[]>()
    const [examTypeOptions, setExamTypeOptions] = useState<SelectOptionWithLabel<ExamType>[]>()
    const [schoolTypesOptions, setSchoolTypesOptions] = useState<SelectOptionWithLabel<SchoolType>[]>()
    const [mappedSubjects, setMappedSubjects] = useState<Subject[]>([])
    const [mappedSubjectOptions, setMappedSubjectOptions] = useState<Subject[]>([])
    const [selectedCountry, setSelectedCountry] = useState<Country>()
    const [subjectCountry, setSubjectCountry] = useState<CountryCode>('DE')
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()

    const formatIconNameLabel = (iconName: string) => iconName.split('.')[0].replaceAll('-', ' ')

    const fetchExamType = useCallback((countryId: number) => {
        CountriesService.listExamType(countryId)
            .then((exams) => {
                const examTypes = exams.map((exam) => ({
                    label: exam.name,
                    identifier: exam.uuid,
                    data: exam,
                }))
                setExamTypeOptions(examTypes)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    if (error.type === ErrorTypes.NotFound) {
                        console.error(`This country doesn't have exam boards.`)
                    } else {
                        error.handleUnknown('An error occurred while getting exam boards.')
                    }
                } else {
                    throw error
                }
            })
    }, [])

    const fetchSchoolTypes = useCallback((countryId: number) => {
        SchoolTypeService.list(countryId)
            .then((schoolTypes) => {
                setSchoolTypesOptions(
                    schoolTypes.map((schoolType) => ({
                        label: schoolType.name,
                        identifier: schoolType.id.toString(),
                        data: schoolType,
                    }))
                )
            })
            .catch((error: ApiError) => {
                error.handleUnknown('An error occurred while getting school types.')
            })
    }, [])

    useEffect(() => {
        trackPromise(
            CountriesService.list()
                .then((countries) => {
                    if (!countries) return

                    const targetCountries = countries.reverse()
                    setCountryOptions(targetCountries.map((c) => ({ label: c.name, identifier: c.id.toString(), data: c })))
                    const targetCountry = subject?.country ?? targetCountries[0]
                    isNew && setSelectedCountry(targetCountries[0])
                    fetchExamType(targetCountry.id)
                    fetchSchoolTypes(targetCountry.id)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('Unable to list countries.')
                        return
                    } else {
                        throw error
                    }
                }),
            loadingAreas.form
        )
        trackPromise(
            SubjectService.getCategories()
                .then((categories) => {
                    setCategoriesOptions(
                        categories.map((c) => ({
                            label: c.name,
                            identifier: c.id.toString(),
                            data: c,
                        }))
                    )
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting subject categories.')
                }),
            loadingAreas.form
        )
        trackPromise(
            KnowledgeAreaService.list()
                .then((knowledgeAreas) => {
                    const options: SelectOptionWithLabel<KnowledgeArea>[] = knowledgeAreas.map((knowledgeArea) => ({
                        label: `${knowledgeArea.name}`,
                        identifier: knowledgeArea.uuid,
                        data: knowledgeArea,
                    }))

                    setKnowledgeAreasOptions(options)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting knowledge areas.')
                }),
            loadingAreas.form
        )
        trackPromise(
            LanguageService.list(true)
                .then((languages) => {
                    const options = languages.map((lang) => ({
                        label: lang.englishName,
                        identifier: lang.id.toString(),
                        data: lang,
                    }))
                    setLanguageOptions(options)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting languages.')
                }),
            loadingAreas.form
        )
        trackPromise(
            SubjectService.getIconNames()
                .then((icons) => {
                    setIconNamesOptions(
                        icons.map((i) => ({
                            label: formatIconNameLabel(i.name),
                            identifier: i.name,
                            data: i.name,
                            description: '',
                            children: <img src={subjectIconUrl(i.name)} alt="Icon" />,
                        }))
                    )
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting icon names.')
                }),
            loadingAreas.form
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNew, subject?.country])

    useEffect(() => {
        if (!subject) return

        const {
            iconUrl,
            language,
            category,
            sortId,
            name,
            longName,
            country,
            grades,
            englishName,
            synonyms,
            useForSearchTermFilter,
        } = subject

        const iconNameSlug = iconUrl.split('/').pop()
        if (!iconNameSlug) return

        const initialData: FormData = {
            iconName: {
                identifier: iconNameSlug,
                data: iconNameSlug,
            },
            category: {
                identifier: category.id.toString(),
                data: category,
            },
            sortId: sortId.toString(),
            name: name ?? null,
            englishName: englishName ?? null,
            longName: longName ?? null,
            country: country
                ? { identifier: country?.id.toString(), data: country, label: country.englishName }
                : (null as any),
            grades: grades,
            language: language
                ? {
                      label: language.name,
                      identifier: language.id.toString(),
                      data: language,
                  }
                : (undefined as any),
            knowledgeAreas: subject.knowledgeArea
                ? {
                      label: subject.knowledgeArea.localizedName,
                      identifier: subject.knowledgeArea.uuid,
                      data: subject.knowledgeArea,
                  }
                : (undefined as any),
            examType: { identifier: subject.examType ? subject.examType.uuid : 'none', data: subject.examType },
            synonyms: synonyms.join(', '),
            useForSearchTermFilter: useForSearchTermFilter ?? false,
            isHidden: !!subject.isHidden,
            schoolTypes: subject.schoolTypes.map((schoolType) => ({
                identifier: schoolType.id.toString(),
                data: schoolType,
            })),
        }

        if (country) {
            setSelectedCountry(country)
        }
        if (subject?.mappedSubjects) {
            setMappedSubjects(subject.mappedSubjects)
        }

        setInitialData(initialData)
    }, [subject])

    const returnFilteredCategoriesOptions = (categories: { label: string; identifier: string; data: SubjectCategory }[]) => {
        if (!categories?.length) return []
        if (!selectedCountry) return categories

        return uniqueBy(
            'label',
            categories.filter((category) => category.data.countryId === selectedCountry.id)
        )
    }

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        formRef.current!.setErrors({})

        const requestBody: UpdateSubject = {
            name: data.name,
            sortId: parseInt(data.sortId),
            languageId: data.language.data.id,
            gradeIds: data.grades?.map((x) => x.id),
            countryId: data.country.data.id,
            categoryId: data.category.data.id,
            longName: data.longName,
            iconName: data.iconName.data,
            examTypeUuid: data.examType?.data ? data.examType.data.uuid : null,
            synonyms: data.synonyms.split(',')?.map((synonym) => synonym.trim()),
            englishName: data.englishName ?? null,
            useForSearchTermFilter: data.useForSearchTermFilter,
            isHidden: data.isHidden,
            schoolTypeIds: data.schoolTypes?.map((s) => s.data.id),
            mappedSubjectIds: mappedSubjects.length ? mappedSubjects.map((s) => s.id) : [],
            knowledgeAreaUuid: data.knowledgeAreas?.data?.uuid ?? null,
        }

        if (data.sortId === null) {
            formRef.current!.setFieldError(
                'sortId',
                `You need to input a sorting number! If you don't know what the sorting number should be, look at other similar subjects`
            )
            return
        }

        let handleFunc
        if (!isNew && subject?.id) {
            handleFunc = SubjectService.update(subject.id, requestBody)
        } else {
            handleFunc = SubjectService.create(requestBody)
        }

        trackPromise(
            handleFunc
                .then((subject) => {
                    updateSubject(subject)
                    toast.success('The changes to subject have been saved!')
                    history.push(routes.subjectRoute(subject.id))
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while saving subject.')
                    }
                }),
            loadingAreas.save
        )
    }

    const updateCountry = (country: Country) => {
        fetchExamType(country.id)
        fetchSchoolTypes(country.id)
        setSelectedCountry(country)
    }

    const fetchData = useCallback(() => {
        trackPromise(
            SubjectService.list(subjectCountry as any)
                .then((subjects) => {
                    setMappedSubjectOptions(subjects)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting subjects.')
                }),
            loadingAreas.mappedSubjects
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subjectCountry])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleAddMappedSubject = (subject: Subject): void => {
        setMappedSubjects([...mappedSubjects, subject])
        setMappedSubjectOptions(mappedSubjectOptions.filter((s) => s.id !== subject.id))
    }

    const handleDeleteMappedSubject = (subject: Subject): void => {
        setMappedSubjects(mappedSubjects.filter((s) => s.id !== subject.id))
    }

    const countrySubjectOptions = useCountryOptions()

    return (
        <div>
            <h3>{isNew ? 'Create new' : 'Update'} Subject</h3>
            <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                <Input name="name" placeholder="Name" />

                {countryOptions && (
                    <FormSelect name="country" options={countryOptions} placeholder="Countries" onUpdate={updateCountry} />
                )}
                {languageOptions && <FormSelect name="language" options={languageOptions} placeholder="Language" />}
                {knowledgeAreasOptions && (
                    <FormSelect name="knowledgeAreas" options={knowledgeAreasOptions} placeholder="Knowledge areas" />
                )}
                <Input
                    name="sortId"
                    type="number"
                    placeholder="Sorting number (the lower the number the higher the subject will be shown in the subject list - highest sorting number is 0)"
                />
                <Input name="longName" placeholder="Long name" />
                <Input name="englishName" placeholder="English name" />
                <GradeSelection
                    name="grades"
                    label="Grades"
                    defaultGrades={subject?.grades}
                    enableMultipleRegionsAndSchooltypes
                />
                {schoolTypesOptions && (
                    <SelectMultiple<SchoolType> name="schoolTypes" options={schoolTypesOptions} label="School Types" />
                )}
                {categoriesOptions && (
                    <FormSelect
                        name="category"
                        options={returnFilteredCategoriesOptions(categoriesOptions)}
                        placeholder="Subject category"
                    />
                )}
                {examTypeOptions?.length ? (
                    <FormSelect name="examType" options={examTypeOptions} placeholder="Exam type" noMargin />
                ) : null}
                <Input name="synonyms" placeholder="Synonyms (separated by commas)" />
                {iconNameOptions && <ModalSelect name="iconName" options={iconNameOptions} label="Icon" />}

                <Checkbox name="useForSearchTermFilter" placeholder="Use for Search Term Filter" />
                <Checkbox name="isHidden" placeholder="Is Hidden" />
                <br />
                <SearchSubjectWrapper>
                    <Body1 color={theme.colors.knowunityBlue}>Automatic Content Mapping</Body1>
                    <div className="row">
                        <Body1 color={theme.colors.white}>Selected subjects: </Body1>
                        {mappedSubjects?.length ? (
                            <Row>
                                {mappedSubjects.map((subject) => (
                                    <div
                                        className="subject-option"
                                        key={subject.id}
                                        onClick={() => handleDeleteMappedSubject(subject)}
                                    >
                                        {subject.name}
                                        <CloseIcon />
                                    </div>
                                ))}
                            </Row>
                        ) : (
                            'None'
                        )}
                    </div>
                    <Row>
                        {countrySubjectOptions && (
                            <FormSelect
                                name="Search for subjects from country"
                                options={countrySubjectOptions}
                                placeholder="Country"
                                onUpdate={(value) => setSubjectCountry(value as CountryCode)}
                            />
                        )}
                    </Row>
                    <div className="row">
                        {mappedSubjectOptions.length ? (
                            <>
                                {mappedSubjectOptions.map((subject) => (
                                    <div
                                        className="subject-option"
                                        key={subject.id}
                                        onClick={() => handleAddMappedSubject(subject)}
                                    >
                                        {subject.name}
                                    </div>
                                ))}
                            </>
                        ) : (
                            <Body1 color={theme.colors.body2Black}>Nothing found</Body1>
                        )}
                    </div>
                </SearchSubjectWrapper>
                <br />
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {isNew ? 'Save' : 'Update'}
                </Button>
            </Form>
        </div>
    )
}

export default SubjectForm
