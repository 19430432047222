import React, { useState } from 'react'
import UserService from '../../services/UserService'
import Search from 'components/elements/Search'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ButtonLink } from 'components/elements/Button'
import { useCallback } from 'react'
import RefactoredTable from 'components/modules/RefactoredTable'
import routes from 'lib/constants/routes'
import { ControlWrapper, FilterFormGrid } from 'style'
import { User } from 'interfaces/User'
import { Headline5 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import Meta from 'components/modules/Head'

interface Props {}

interface UserResponse {
    users: User[]
    page: number
    totalPages: number
    totalElements: number
}

const loadingAreas = {
    container: 'usersContainer',
}

const Users = (props: Props) => {
    const [searchQuery, setSearchQuery] = useState<string>()
    const [emailQuery, setEmailQuery] = useState<string>()
    const [username, setUsername] = useState<string>()

    const hasUserEnterQuery = !!username?.length || !!emailQuery?.length || !!searchQuery?.length

    const generateUsersTableData = (data: UserResponse) => ({
        totalPages: data?.totalPages ?? 0,
        totalElements: data?.totalElements ?? data.users?.length,

        elements: data?.users.map((u) => ({
            id: u.uuid,
            columns: [u.uuid, `${u.name} ${u.deletedOn ? `(🚫 Deleted)` : ''}`, u.email, u.username, u.knowerUuid],
            onClick: routes.userRoute(u.uuid),
        })),
    })

    const fetchData = useCallback(
        (page: number) => {
            let handleFunc
            if (username) {
                handleFunc = UserService.getByUsername(username)
            } else {
                handleFunc = UserService.list(page, searchQuery, emailQuery)
            }
            return trackPromise(
                handleFunc
                    .then((data) => {
                        if ('users' in data) {
                            return generateUsersTableData(data)
                        } else {
                            return generateUsersTableData({ users: [data], totalElements: 1, totalPages: 1, page: 1 })
                        }
                    })
                    .catch((error: ApiError) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting users.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [username, searchQuery, emailQuery]
    )

    return (
        <section>
            <Meta title="Users" />
            <h2>Users</h2>
            <FilterFormGrid gridAmount={2}>
                <Search onSearch={setSearchQuery} fullWidth label="⠀" />
                <Search onSearch={setEmailQuery} fullWidth label="Find by email (exact match)" />
            </FilterFormGrid>
            <FilterFormGrid gridAmount={2}>
                <ControlWrapper>
                    <ButtonLink to={routes.userCreateRoute} fullWidth icon={<AddIcon />} noMargin>
                        Add user
                    </ButtonLink>
                </ControlWrapper>
                <Search onSearch={setUsername} label="Find by username (exact match)" fullWidth addResetButton />
            </FilterFormGrid>
            {hasUserEnterQuery ? (
                <RefactoredTable
                    columns={['#', 'Name', 'Email address', 'Username', 'Knower']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                    refetchKey={`${searchQuery}_${username}_${emailQuery}`}
                />
            ) : (
                <Headline5
                    textAlign="center"
                    color={theme.colors.white}
                    style={{ paddingTop: 50 }}
                >{`Enter search details to see the Users!`}</Headline5>
            )}
        </section>
    )
}

export default Users
