import { useEffect, useRef, useState } from 'react'
import { ApiError } from 'services/ApiService'
import KnowService from 'services/KnowService'
//import ReportService from 'services/ReportService'
import { AcademicTier, KnowCreateData, Know as KnowInterface, KnowStatus } from 'interfaces/Know'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { FormData as MetaFormData } from 'components/features/knows/KnowMetaForm'
import { FormHandles } from '@unform/core'
import { badRequestFormErrors } from 'utils'
import { loadingAreas } from 'lib/constants/LoadingAreas'
import { trackPromise } from 'react-promise-tracker'
import ReportService from 'services/ReportService'
import { ReportStatus } from 'interfaces/Report'

interface UseKnowInformationSectionProps {
    know: KnowInterface
    onSuccessSubmitKnowEdit: (know: KnowInterface) => void
}

export const useKnowInformationSection = ({ know, onSuccessSubmitKnowEdit }: UseKnowInformationSectionProps) => {
    const metaFormRef = useRef<FormHandles>(null)
    const [reviewKnowsCount, setReviewKnowsCount] = useState<number>(0)
    const [publicKnowsCount, setPublicKnowsCount] = useState<number>(0)

    const fetchKnowContentPolicyReports = (page: number) => {
        const knowContentPolicyReports: string[] = []
        if (!know) return knowContentPolicyReports

        trackPromise(
            KnowService.listReviewHistory(know.uuid, page)
                .then((data) => {
                    data.content.forEach((c) => {
                        if (c.reason) knowContentPolicyReports.push(c.reason)
                    })
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            return
                        } else {
                            //error.handleUnknown('An error occurred while listing know review history')
                            console.error(error)
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.history
        )

        return knowContentPolicyReports
    }

    const fetchKnowerReports = (page: number) => {
        const knowerReports: string[] = []

        trackPromise(
            ReportService.listReports(page, null, null, ReportStatus.Open)
                .then((data) => {
                    data.content.forEach((c) => {
                        c.reports.forEach((r) => {
                            r.employeeReason && knowerReports.push(r.employeeReason)
                        })
                    })
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        //error.handleUnknown('An error occurred while getting reports.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.report
        )

        return knowerReports
    }

    const getKnowerLabels = () => {
        const labels: { tag: string; isGreen: boolean }[] = []

        if (know.knower.verified) {
            labels.push({ tag: 'Star-knower', isGreen: true })
        }

        const reports = fetchKnowContentPolicyReports(0).concat(fetchKnowerReports(0))
        const counReports: { [key: string]: number } = {}

        for (let i = 0; i < reports.length; i++) {
            const element = reports[i]
            if (counReports[element]) {
                counReports[element]++
            } else {
                counReports[element] = 1
            }
        }

        Object.keys(counReports).forEach((key) => {
            if (counReports[key] >= 5) labels.push({ tag: key, isGreen: false })
        })

        return labels
    }

    useEffect(() => {
        if (!know?.knower.uuid) {
            setReviewKnowsCount(0)
            setPublicKnowsCount(0)
        }

        KnowService.listAllByKnower(know.knower.uuid)
            .then((response) => {
                const reviewCount = response.knows.filter(
                    (know) => know.status === KnowStatus.InReview || know.status === KnowStatus.InExtendedReview
                ).length
                setReviewKnowsCount(reviewCount)

                const publicCount = response.knows.filter((know) => know.status === KnowStatus.Public).length
                setPublicKnowsCount(publicCount)
            })
            .catch((error) => {
                setReviewKnowsCount(0)
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting knower knows.')
                } else {
                    throw error
                }
            })
    }, [know])

    const handleSubmitKnowEdit = (data: MetaFormData) => {
        metaFormRef.current!.setErrors({})

        const isUniversityKnow = know.academicTier === AcademicTier.University

        if (!isUniversityKnow && data.subjects.length > 2) {
            metaFormRef.current!.setFieldError('subjects', 'You can select a maximum of two subjects per know.')
            return
        }
        if (!isUniversityKnow && data.grades.length > 3) {
            metaFormRef.current!.setFieldError('grades', 'You can select a maximum of three classes per know.')
            return
        }

        let knowCreateData: Partial<KnowCreateData> = {
            title: data.title,
            description: data.description,
            knowType: data.knowType.data,
            schoolTypes: data.schoolTypes?.map((st) => st.data),
        }

        if (isUniversityKnow) {
            knowCreateData = {
                ...knowCreateData,
                courseUuids: data.courses?.map((c) => c.uuid),
                universityAuthoredDate: data.universityAuthoredDate.data,
            }
        } else {
            knowCreateData = {
                ...knowCreateData,
                grades: data.grades?.map((g) => g.data),
                subjects: data.subjects?.map((s) => s.data),
                examBoardUuids: data.examBoards?.map((e) => e.data.uuid),
            }
        }

        trackPromise(
            KnowService.update(know.uuid, knowCreateData)
                .then((know) => {
                    onSuccessSubmitKnowEdit(know)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, metaFormRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while saving know.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.update
        )
    }

    return {
        getKnowerLabels,
        reviewKnowsCount,
        publicKnowsCount,
        handleSubmitKnowEdit,
        metaFormRef,
    }
}
