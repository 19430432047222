import { CourseFundraiseRequst } from 'interfaces/CourseFundraise'
import ApiService from './ApiService'

const CourseService = {
    fundraiseUpdate(uuid: string, data: CourseFundraiseRequst) {
        return ApiService.patch(`/courses/${uuid}/fundraise`, data).then((response) => {
            return response.data
        })
    },
}

export default CourseService
