import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Checkbox, FormSelect, Input, SelectOption } from 'components/inputs/Input'
import routes from 'lib/constants/routes'
import ExperimentsService from './ExperimentsService'
import { Experiment, ExperimentVariantWithoutIdentifier, UpdateExperiment } from 'interfaces/Experiment'
import Box from 'components/elements/Box'
import VariantForm from './VariantForm'
import { isIdentifierInvalid } from 'lib/features/experiments'

interface FormData {
    name: string
    identifier: string
    description: string
    jiraTicketUrl: string
    squad: SelectOption<string | null> | null
    cohort: SelectOption<string | null>
    group: SelectOption<string | null> | null
    minimumAppBuildNumber: string
    rolloutFraction: number | null
    isExclusiveCohort: boolean
}

interface Props {
    isNew: boolean
    experiment: Experiment | null
    updateExperiment: (experiment: Experiment) => void
}

const loadingAreas = {
    form: 'experimentForm',
    save: 'saveExperiment',
}

const ExperimentForm = ({ isNew, experiment, updateExperiment }: Props) => {
    const [initialData, setInitialData] = useState<FormData>()
    const [selectedGroup, setSelectedGroup] = useState<string | null>(experiment?.group ?? 'main')
    const [cohortsOptions, setCohortOptions] = useState<{ label: string; identifier: string; data: string | null }[]>()
    const [variants, setVariants] = useState<ExperimentVariantWithoutIdentifier[] | null>(experiment?.variants ?? null)
    const [squadOptions, setSquadOptions] = useState<{ label: string; identifier: string; data: string }[]>()
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()

    const groupOptions = useMemo(
        () =>
            ['main', 'monetisation', 'empty'].map((group) => ({
                label: group === 'empty' ? 'None (Runs overlapping with other experiments)' : group,
                identifier: group,
                data: group === 'empty' ? null : group,
            })),
        []
    )

    useEffect(() => {
        trackPromise(
            ExperimentsService.getCohorts()
                .then((cohorts) => {
                    const options = [
                        {
                            label: 'Everyone',
                            identifier: 'everyone',
                            data: null,
                        },
                        ...cohorts.map((cohort) => ({
                            label: cohort.description,
                            identifier: cohort.code,
                            data: cohort.code,
                        })),
                    ]
                    setCohortOptions(options)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('Unable to list cohorts.')
                        return
                    } else {
                        throw error
                    }
                }),
            loadingAreas.form
        )

        trackPromise(
            ExperimentsService.getSquads()
                .then((squads) => {
                    setSquadOptions(squads.map((squad) => ({ label: squad.name, identifier: squad.name, data: squad.name })))
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('Unable to list squads.')
                        return
                    } else {
                        throw error
                    }
                }),
            loadingAreas.form
        )
    }, [])

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        if (!cohortsOptions || !squadOptions) return

        if (experiment) {
            const selectedCohortOption = cohortsOptions.find((cohort) => cohort.data === experiment.cohortCode)
            const initialData: FormData = {
                name: experiment.name,
                description: experiment.description,
                identifier: experiment.identifier,
                squad: experiment.squad
                    ? {
                          data: experiment.squad,
                          identifier: experiment.squad,
                      }
                    : null,
                group: experiment.group
                    ? {
                          data: experiment.group,
                          identifier: experiment.group,
                      }
                    : null,
                cohort: selectedCohortOption
                    ? {
                          identifier: selectedCohortOption.data ?? 'everyone',
                          data: selectedCohortOption.data,
                      }
                    : {
                          identifier: 'everyone',
                          data: null,
                      },
                jiraTicketUrl: experiment.jiraTicketUrl,
                isExclusiveCohort: experiment.isExclusiveCohort,
                rolloutFraction: experiment.rolloutFraction,
                minimumAppBuildNumber: experiment.minimumAppBuildNumber ? experiment.minimumAppBuildNumber.toString() : '',
            }
            setInitialData(initialData)
        } else if (isNew) {
            // Preload from query parameters if it's a new experiment
            const squadParam = searchParams.get('squad')
            const selectedSquad = squadOptions?.find((s) => s.data === squadParam)
            const cohortParam = searchParams.get('cohort')
            const selectedCohort = cohortsOptions.find((c) => c.data === cohortParam)
            const groupParam = searchParams.get('group') ?? 'main'
            const selectedGroup = groupOptions.find((g) => g.data === groupParam)

            const initialData: FormData = {
                name: searchParams.get('name') || '',
                description: searchParams.get('description') || '',
                identifier: searchParams.get('identifier') || '',
                squad: selectedSquad
                    ? {
                          data: selectedSquad.data,
                          identifier: selectedSquad.identifier,
                      }
                    : null,
                cohort: selectedCohort
                    ? {
                          identifier: selectedCohort.data ?? 'everyone',
                          data: selectedCohort.data,
                      }
                    : {
                          identifier: 'everyone',
                          data: null,
                      },
                group: selectedGroup
                    ? {
                          data: selectedGroup.data,
                          identifier: selectedGroup.identifier,
                      }
                    : null,
                jiraTicketUrl: searchParams.get('jiraTicketUrl') || '',
                isExclusiveCohort: searchParams.get('isExclusiveCohort') === 'true',
                rolloutFraction: searchParams.get('rolloutFraction') ? Number(searchParams.get('rolloutFraction')) : null,
                minimumAppBuildNumber: searchParams.get('minimumAppBuildNumber') || '',
            }
            setInitialData(initialData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experiment, cohortsOptions, isNew, squadOptions])

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        formRef.current!.setErrors({})

        const { cohort, group, identifier, squad, minimumAppBuildNumber, jiraTicketUrl, rolloutFraction, ...rest } = data

        if (isIdentifierInvalid(identifier)) {
            formRef.current!.setFieldError(
                'identifier',
                'Identifier must consist of only lower case letters (a-z), numbers (0-9) and underscores (_).'
            )
            return
        }

        if (!jiraTicketUrl || !jiraTicketUrl.includes('notion.so/')) {
            formRef.current!.setFieldError('jiraTicketUrl', 'Please enter a valid Notion ticket url')
            return
        }

        if (!squad?.data) {
            formRef.current!.setFieldError('squad', 'Please which squad added the experiment')
            return
        }

        if (!!rolloutFraction && (+rolloutFraction > 99 || rolloutFraction < 1)) {
            formRef.current!.setFieldError('rolloutFraction', 'Please enter correct number (between 1-99)')
            return
        }

        const requestBody: UpdateExperiment = {
            cohortCode: cohort.data,
            variants: variants ?? [],
            squad: squad.data,
            group: group?.data ?? null,
            identifier,
            jiraTicketUrl,
            minimumAppBuildNumber: minimumAppBuildNumber ? +minimumAppBuildNumber : null,
            rolloutFraction: rolloutFraction ? +rolloutFraction : null,
            ...rest,
        }

        let handleFunc
        if (!isNew && experiment?.uuid) {
            const hasExperimentStarted = experiment.startOn ? new Date() > new Date(experiment.startOn) : false
            const updatingVariantsWarningText =
                'Updating the variants after the experiment started, could lead to major issues. This could cause users to be migrated from one variant to another. Are you sure that you want to continue?'

            if (hasExperimentStarted && !window.confirm(updatingVariantsWarningText)) {
                return
            }

            handleFunc = ExperimentsService.update(experiment.uuid, requestBody)
        } else {
            handleFunc = ExperimentsService.create(requestBody)
        }

        trackPromise(
            handleFunc
                .then((experiment) => {
                    updateExperiment(experiment)
                    toast.success('The changes to experiment have been saved!')
                    history.push(routes.experimentRoute(experiment.uuid))
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while saving experiment.')
                    }
                }),
            loadingAreas.save
        )
    }

    const changeVariants = (variants: ExperimentVariantWithoutIdentifier[]) => setVariants(variants)

    return (
        <Box>
            <h3>{isNew ? 'Create new' : 'Update'} Experiment</h3>
            <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                <Input name="name" placeholder="Name" />
                <Input name="identifier" placeholder="Identifier" />
                <Input name="description" placeholder="Description" />
                <Input
                    name="jiraTicketUrl"
                    placeholder={`Notion Ticket URL (you can enter multiple tickets separated by commas)`}
                />
                <Input name="minimumAppBuildNumber" placeholder="Minimum App Build Number" type="number" />
                <Input name="rolloutFraction" placeholder="Rollout Fraction (1-99)" type="number" />
                {squadOptions && <FormSelect name="squad" options={squadOptions} placeholder="Squad" />}
                {cohortsOptions && <FormSelect name="cohort" options={cohortsOptions} placeholder="Cohort" />}
                <FormSelect
                    name="group"
                    options={groupOptions}
                    placeholder="Group"
                    onUpdate={(group) => setSelectedGroup(group)}
                    tooltip="Experiments in the same group will run non-overlapping."
                />
                <Checkbox
                    name="isExclusiveCohort"
                    placeholder="Is cohort exclusive? (requires approval by product and tech leadership)"
                />
                <VariantForm
                    cohortsOptions={cohortsOptions}
                    variants={variants}
                    parentFormRef={formRef}
                    changeVariants={changeVariants}
                    selectedGroup={selectedGroup}
                />
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {isNew ? 'Save' : 'Update'}
                </Button>
            </Form>
        </Box>
    )
}

export default ExperimentForm
