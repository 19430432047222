import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import SubjectService from './SubjectService'
import { Subject, UpdateSubject } from 'interfaces/Subject'
import SubjectForm from './SubjectsForm'
import styled from 'styled-components'
import { getColorIndicator } from 'utils/subjects'
import { returnGradeLabel } from 'lib/features/grades'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import Meta from 'components/modules/Head'
import { toast } from 'react-toastify'
import Button from 'components/elements/Button'

interface Props extends RouteComponentProps<{ id: string }> {}

const loadingAreas = {
    container: 'subjectContainer',
    duplicate: 'subjectDuplicate',
}

const Container = styled.section`
    padding-bottom: 25px;
`

const SubjectComponent = (props: Props) => {
    const [subject, setSubject] = useState<Subject | null>(null)
    const history = useHistory()

    const id = props.match.params.id
    const isNew = !id || id === 'create'

    const updateSubject = (subject: Subject) => {
        setSubject(subject)
    }

    const fetchData = useCallback(() => {
        if (id && !isNew) {
            trackPromise(
                SubjectService.get(+id)
                    .then((subject) => {
                        updateSubject(subject)
                    })
                    .catch((error: ApiError) => {
                        error.handleUnknown('An error occurred while getting subject.')
                    }),
                loadingAreas.container
            )
        }
    }, [id, isNew])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const duplicateExperiment = () => {
        if (!subject) return null

        const requestBody: UpdateSubject = {
            name: subject.name,
            sortId: subject.sortId,
            languageId: subject.language?.id ?? 0,
            gradeIds: subject.grades?.map((x) => x.id) ?? [],
            countryId: subject.country?.id ?? 0,
            categoryId: subject?.category.id ?? 0,
            longName: subject.longName,
            iconName: 'microscope.svg',
            examTypeUuid: subject.examType?.uuid ?? null,
            synonyms: subject.synonyms,
            englishName: subject.englishName ?? null,
            useForSearchTermFilter: subject.useForSearchTermFilter,
            isHidden: subject.isHidden,
            schoolTypeIds: subject.schoolTypes?.map((s) => s.id) ?? [],
            mappedSubjectIds: [],
            knowledgeAreaUuid: subject.knowledgeArea?.uuid ?? null,
        }

        trackPromise(
            SubjectService.create(requestBody)
                .then((subject) => {
                    updateSubject(subject)
                    toast.success('The subject have been duplicated saved!')
                    history.push(routes.subjectRoute(subject.id))
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while duplicating experiment.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.duplicate
        )
    }

    return (
        <Container>
            <Meta title="Subject" />
            <h2>Subject</h2>
            <GoBackButton
                route={routes.subjectsOptionsRoute({ countryCode: subject?.country?.code ?? 'DE' })}
                routeName="Subjects"
            />
            {!isNew && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <LoadingArea area={loadingAreas.container}>
                            {subject && (
                                <>
                                    <p>
                                        <strong>Id:</strong>&nbsp;
                                        {subject.id}
                                    </p>
                                    <p>
                                        <strong>Name:</strong>&nbsp;
                                        {subject.name}
                                    </p>
                                    <p>
                                        <strong>English name:</strong>&nbsp;
                                        {subject.englishName ?? 'Not specified'}
                                    </p>
                                    <p>
                                        <strong>Color:</strong>&nbsp;
                                        {getColorIndicator(subject.color)}
                                    </p>
                                    {subject.knowledgeArea ? (
                                        <p>
                                            <strong>Knowledge Area:</strong>&nbsp;
                                            {subject.knowledgeArea.name}
                                        </p>
                                    ) : null}
                                    <p>
                                        <strong>Icon:</strong>&nbsp;
                                        <img src={subject.iconUrl} alt="Icon" />
                                    </p>
                                    <p>
                                        <strong>Country: </strong>&nbsp;
                                        {subject.country?.englishName}
                                    </p>
                                    {subject?.language ? (
                                        <p>
                                            <strong>Language: </strong>&nbsp;
                                            {`${subject.language?.englishName} (${subject.language.code})`}
                                        </p>
                                    ) : null}
                                    <p>
                                        <strong>Category:</strong>&nbsp;
                                        {subject.category.name}
                                    </p>
                                    <p>
                                        <strong>Sort Id:</strong>&nbsp;
                                        {subject.sortId}
                                    </p>
                                    {subject?.grades?.length ? (
                                        <p>
                                            <strong>Grades: </strong>&nbsp;
                                            {subject.grades.map((grade) => returnGradeLabel(grade)).join(', ')}
                                        </p>
                                    ) : null}
                                    {subject?.examType ? (
                                        <p>
                                            <strong>Exam Type: </strong>&nbsp;
                                            {subject.examType.name}
                                        </p>
                                    ) : null}
                                    {subject?.isHidden ? (
                                        <p>
                                            <strong>Is hidden: </strong>&nbsp;
                                            {subject.isHidden ? 'yes' : 'no'}
                                        </p>
                                    ) : null}
                                    <p>
                                        <strong>School Types: </strong>&nbsp;
                                        {subject.schoolTypes?.map((schoolType) => schoolType.name).join(', ') ?? 'none'}
                                    </p>
                                </>
                            )}
                        </LoadingArea>
                        <Button fullWidth={false} onClick={duplicateExperiment} loadingArea={loadingAreas.duplicate}>
                            Duplicate this subject
                        </Button>
                    </Stack>
                </Box>
            )}
            <SubjectForm subject={subject} isNew={isNew} updateSubject={updateSubject} />
        </Container>
    )
}

export default SubjectComponent
