import React, { useCallback, useState } from 'react'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import Stack from 'components/elements/Stack'
import Meta from 'components/modules/Head'
import { ControlWrapper, FilterFormGrid } from 'style'
import routes from 'lib/constants/routes'
import { ButtonLink } from 'components/elements/Button'
import theme from 'lib/constants/theme'
import SchoolHolidaysService from 'services/SchoolHolidaysService'
import { formatDate } from 'utils'
import { useCountryOptions } from 'hooks/useCountryOptions'
import SelectElement from 'components/inputs/SelectElement'

const loadingAreas = {
    container: 'schoolsContainer',
}

const SchoolHolidays = () => {
    const [selectedCountry, setSelectedCountry] = useState<number | null>(null)

    const countryOptions = useCountryOptions({ useId: true, includeNoFilter: true })

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                SchoolHolidaysService.list(page, selectedCountry)
                    .then((data) => {
                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.schoolHolidays.map((s) => ({
                                id: s.uuid,
                                columns: [
                                    s.uuid,
                                    s.name,
                                    formatDate(s.startDate),
                                    formatDate(s.endDate),
                                    s.isActive ? 'Active' : 'Inactive',
                                ],
                                onClick: routes.schoolHolidayRoute(s.uuid),
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting moderation keywords.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [selectedCountry]
    )

    return (
        <section>
            <Meta title="School Holidays" />
            <Stack gutter={5}>
                <h2>School Holidays</h2>
                <FilterFormGrid gridAmount={3}>
                    <SelectElement
                        label="Country"
                        options={countryOptions}
                        onUpdate={(country: number) => setSelectedCountry(country)}
                        noMargin={false}
                    />
                    <br />
                    <ControlWrapper>
                        <ButtonLink
                            to={routes.schoolHolidayCreateRoute}
                            fullWidth={false}
                            icon={<AddIcon />}
                            hoverColor={theme.colors.knowunityBlue}
                            color={theme.colors.knowunityBlueDark}
                            noMargin
                        >
                            Create a School Holiday
                        </ButtonLink>
                    </ControlWrapper>
                </FilterFormGrid>
                <RefactoredTable
                    columns={['#', 'Name', 'Start Date', 'End Date', 'Active']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                />
            </Stack>
        </section>
    )
}

export default SchoolHolidays
