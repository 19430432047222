export interface Experiment {
    uuid: string
    name: string
    description: string
    startOn: string | null
    endOn: string | null
    cohortCode: string | null
    jiraTicketUrl: string
    identifier: string
    status: ExperimentStatuses
    variants: ExperimentVariant[]
    minimumAppBuildNumber: number | null
    rolloutFraction: number | null
    isExclusiveCohort: boolean
    salt: number
    squad: string | null
    group: string | null
    approvedOn: string | null
    approvedByUserUuid: string | null
    isPaused: boolean
}

export interface ExperimentCohort {
    code: string
    description: string
}

export interface UpdateExperiment {
    name: string
    description: string
    cohortCode: ExperimentCohort['code'] | null
    identifier: string
    squad: string
    jiraTicketUrl: string
    variants: ExperimentVariantWithoutIdentifier[]
    minimumAppBuildNumber: number | null
    rolloutFraction: number | null
    isExclusiveCohort: boolean
    group: string | null
}

export interface ExperimentVariant {
    identifier: string
    appConfigFlags: Record<string, string | number | boolean | string[] | number[]> | undefined
    backendToggles: Record<string, string | number | boolean | string[] | number[]> | undefined
    forceAssignForUserUUIDs: string[] | null
    cohortOverride: ExperimentCohort['code'] | null
}

export type ExperimentVariantWithoutIdentifier = Omit<ExperimentVariant, 'identifier'>

export enum ExperimentStatuses {
    All = 'ALL',
    Running = 'RUNNING',
    Scheduled = 'SCHEDULED',
    Completed = 'COMPLETED',
}

export interface ExperimentSquad {
    name: string
}
