import React, { useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Button from 'components/elements/Button'
import { Body1, Body2, ButtonText, Headline5 } from 'components/elements/Text'
import { Checkbox, Textarea } from 'components/inputs/Input'
import Modal from 'components/modules/Modal'
import { useToggleState } from 'utils/hooks/useToggleState'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CollapsibleCard from 'components/features/CollapsibleCard'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import Row from 'components/elements/Row'
import { AdsAttachmentTOS } from 'interfaces/AdAttachmentSet'

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    button {
        height: 30px;
    }
`

type TOSFormData = {
    text: string
    isRequired: boolean
}

type TOSUpdate = AdsAttachmentTOS & { index: number }

interface Props {
    tosList: AdsAttachmentTOS[]
    setTosList: React.Dispatch<React.SetStateAction<AdsAttachmentTOS[]>>
}

const AdAttachmentTOSForm = ({ tosList, setTosList }: Props) => {
    const tosFormRef = useRef<FormHandles>(null)
    const [selectedTOS, setSelectedTOS] = useState<TOSUpdate | null>(null)
    const [showTOSForm, toggleTOSForm] = useToggleState(false)

    const updateTOS = (tos: AdsAttachmentTOS) => setTosList((prevTOS) => [...prevTOS, tos])

    const handleTOSDelete = (indexToDelete: number) => setTosList((terms) => terms.filter((_, i) => i !== indexToDelete))

    const handleTOSEdit = (tos: AdsAttachmentTOS, index: number) => {
        setSelectedTOS({ ...tos, index })
        toggleTOSForm()
    }

    const handleTOSFormSubmit: SubmitHandler<TOSFormData> = (data) => {
        if (!data.text) {
            tosFormRef.current!.setFieldError('text', 'Terms of Service text is required!')
            return
        }

        if (selectedTOS) {
            const { index } = selectedTOS
            setTosList((prevTOS) => {
                const tempTOS = [...prevTOS]
                tempTOS[index] = data
                return tempTOS
            })
        } else {
            updateTOS(data)
        }
        setSelectedTOS(null)
        toggleTOSForm()
    }

    const onClose = () => {
        setSelectedTOS(null)
        toggleTOSForm()
    }

    const initialData = selectedTOS
        ? {
              text: selectedTOS.text,
              isRequired: selectedTOS.isRequired,
          }
        : undefined

    return (
        <>
            <Modal
                show={showTOSForm}
                title={selectedTOS ? 'Update Terms of Service' : 'Add Terms of Service'}
                onClose={onClose}
            >
                <Form ref={tosFormRef} onSubmit={handleTOSFormSubmit} initialData={initialData}>
                    <Textarea name="text" placeholder="Terms of Service Text" />
                    <Checkbox name="isRequired" placeholder="Require users to accept these terms" />
                    <Button fullWidth icon={<SaveIcon />} type="button" onClick={() => tosFormRef.current?.submitForm()}>
                        Save
                    </Button>
                </Form>
            </Modal>
            <Box>
                <Stack>
                    <ButtonRow>
                        <Headline5 color={theme.colors.white} marginBottom={10}>
                            Terms of Service
                        </Headline5>
                        <Button fullWidth={false} onClick={toggleTOSForm} icon={<AddIcon />} type="button">
                            <ButtonText color={theme.colors.white}>Add Terms of Service</ButtonText>
                        </Button>
                    </ButtonRow>

                    {tosList.length ? (
                        <>
                            {tosList.map((tos, i) => (
                                <CollapsibleCard
                                    key={i}
                                    title={
                                        <ButtonRow>
                                            <Body2 color={theme.colors.white}>{`${i + 1}: Terms of Service`}</Body2>
                                            <Row gap={20}>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleTOSDelete(i)}
                                                    type="button"
                                                    icon={<DeleteIcon />}
                                                    color={theme.colors.secondaryRed}
                                                    hoverColor={theme.colors.secondaryRedDarker}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleTOSEdit(tos, i)}
                                                    type="button"
                                                    icon={<EditIcon />}
                                                >
                                                    Edit
                                                </Button>
                                            </Row>
                                        </ButtonRow>
                                    }
                                    openByDefault={false}
                                >
                                    <Stack>
                                        <Body2 color={theme.colors.white}>{`Required: ${
                                            tos.isRequired ? 'Yes' : 'No'
                                        }`}</Body2>
                                        <hr />
                                        <Body1 color={theme.colors.white} fontWeigth={500}>
                                            Terms Text:
                                        </Body1>
                                        <Body2 color={theme.colors.white}>{tos.text}</Body2>
                                    </Stack>
                                </CollapsibleCard>
                            ))}
                        </>
                    ) : (
                        <Body2>No terms of service have been added</Body2>
                    )}
                </Stack>
            </Box>
        </>
    )
}

export default AdAttachmentTOSForm
