import React, { useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import CSVImporter, { CSVData } from 'components/FileUpload/CSVImport'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { FormHandles } from '@unform/core'
import { HeaderLabels, processKnowerBonusPaymentCSVData, STANDARD_HEADERS } from 'lib/features/knowerBonusPayment'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import Button from 'components/elements/Button'
import { ButtonText } from 'components/elements/Text'
import { NoWrapText } from 'style'
import Table, { TableData } from 'components/modules/Table'
import theme from 'lib/constants/theme'
import { KnowerBonusPayment } from 'interfaces/KnowerBonusPayment'
import { formatAmount } from 'utils'
import BonusPaymentsService from 'services/KnowerBonusPaymentsService'
import Meta from 'components/modules/Head'

interface Props {
    onSearch(value: string): void
    fullWidth?: boolean
    label?: string
    defaultValue?: string | null
    type?: 'number' | 'text'
    addResetButton?: boolean
}

const KnowerBonusPayments = (props: Props) => {
    const [knowerBonusPaymentData, setKnowerBonusPaymentData] = useState<KnowerBonusPayment[]>([])
    const bonusPaymentFormRef = useRef<FormHandles>(null)
    const [tableData, setTableData] = useState<TableData[]>()

    const handleCSVUploadComplete = async (csvData: CSVData[]) => {
        const headers = Object.keys(csvData[0])
        const headerLabels = Object.values(HeaderLabels)

        if (headers.length !== STANDARD_HEADERS.length) {
            toast.warn('Make sure to put correct headers (Knower UUID, Amount, Currency) as the first row of the CSV file!')
            return
        }

        for (let i = 0; i < headerLabels.length; i++) {
            if (headers[i] !== headerLabels[i]) {
                toast.warn(
                    `${headers[i]} is not part of the standard headers (Knower UUID, Amount, Currency), check for typos!`
                )
                return
            }
        }

        const knowerBonusPaymentsArray = await processKnowerBonusPaymentCSVData(csvData, toast)

        if (!knowerBonusPaymentsArray || !knowerBonusPaymentsArray.length) {
            toast.info('No knower bonus payments data found')
            return
        }

        setKnowerBonusPaymentData(knowerBonusPaymentsArray)
    }

    const handleCreateTargetingSubmit = async () => {
        if (!knowerBonusPaymentData?.length) return

        const progressText = document.getElementById('progress')
        let count = 0
        const chunkSize = 10
        knowerBonusPaymentData.unshift()
        for (let i = 0; i < knowerBonusPaymentData.length; i += chunkSize) {
            const chunk = knowerBonusPaymentData.slice(i, i + chunkSize)

            await BonusPaymentsService.payKnowers(chunk)
                // eslint-disable-next-line no-loop-func
                .then(() => (count += chunk.length))
                .catch((error: ApiError) => {
                    error.handleUnknown(`An error occurred while making Bonus Knower Payout.`)
                })

            if (progressText) progressText.textContent = `Loading... Progress: ${i}/${knowerBonusPaymentData.length - 1}`
        }
        const text = `Handled payouts for ${count} knowers.`
        if (progressText) progressText.textContent = text
        toast.success(`Payouts were made to ${count} knowers!`)
    }

    useEffect(() => {
        if (!knowerBonusPaymentData?.length) return
        const table = knowerBonusPaymentData.map((k, id) => ({
            id,
            columns: [k.knowerUUID, <NoWrapText key={`payout-${id}`}>{formatAmount(k.amount, k.currency)}</NoWrapText>],
        }))
        setTableData(table)
    }, [knowerBonusPaymentData, setTableData])

    return (
        <section>
            <Meta title="Knower Bonus Payments" />
            <Stack>
                <h2>Knower Bonus Payments</h2>
                <Box>
                    <strong>Requirements:</strong>
                    <ul>
                        <li>
                            {`First row of the CSV file must contain correct Column Name: ${Object.values(HeaderLabels).join(
                                ', '
                            )} (in this order)`}
                        </li>
                        <li>{`Amount should be shown in the smallest currency (e.g. cents), meaning 500 ➔ ${formatAmount(
                            500,
                            'EUR'
                        )}`}</li>
                        <li>{`All cell data values must be an exact match with values stored in the database`}</li>
                    </ul>
                    <br />
                    <strong>Example document:</strong>&nbsp;
                    <a href="/files/ExampleKnowerPayments.csv" download="example_knower_payments.csv">
                        Download
                    </a>
                    <ButtonText textAlign="center" color={theme.colors.white} id="progress" />
                    <Form ref={bonusPaymentFormRef} onSubmit={handleCreateTargetingSubmit}>
                        <br />

                        {!knowerBonusPaymentData.length ? (
                            <CSVImporter
                                title="CSV Upload area for Knower Bonus Payments"
                                handleCSVUploadComplete={handleCSVUploadComplete}
                                showHeader
                            />
                        ) : (
                            <Button fullWidth color={theme.colors.deepSeaBlue} hoverColor={theme.colors.knowunityBlueDark}>
                                <ButtonText color={theme.colors.white}>Handle Knower Bonus Payments</ButtonText>
                            </Button>
                        )}
                    </Form>
                    {tableData?.length ? (
                        <Table
                            columns={['Knower UUID', 'Payout']}
                            data={tableData}
                            page={0}
                            totalPages={1}
                            totalElements={tableData.length}
                        />
                    ) : null}
                </Box>
            </Stack>
        </section>
    )
}

export default KnowerBonusPayments
