import ApiService from './ApiService'
import { Major, MajorGrade } from 'interfaces/Major'
import { Course } from 'interfaces/Course'
import { OffsetPaginationResponse } from 'interfaces/Pagination'

const MajorService = {
    search(query: string, schoolUuid: string, page = 0) {
        return ApiService.get(
            `/majors/search?searchTerm=${encodeURIComponent(query)}&schoolUuid=${encodeURIComponent(
                schoolUuid
            )}&page=${page}&limit=20`
        ).then((response) => {
            return response.data as OffsetPaginationResponse<Major>
        })
    },

    getGrades() {
        return ApiService.get(`/majors/study-start-date?languageId=1`).then((response) => {
            return response.data.content as MajorGrade[]
        })
    },

    searchCourses(majorUuid: string, query: string, schoolUuid: string, page = 0) {
        return ApiService.get(
            `/courses/search?majorUuid=${encodeURIComponent(majorUuid)}&schoolUuid=${encodeURIComponent(
                schoolUuid
            )}&searchTerm=${encodeURIComponent(query)}&page=${page}&limit=20`
        ).then((response) => {
            return response.data as OffsetPaginationResponse<Course>
        })
    },

    listSuggestions(page = 0) {
        return ApiService.get(`/majors/suggestions?limit=20&page=${page}`).then((response) => {
            return response.data as OffsetPaginationResponse<Major>
        })
    },

    deleteSuggestion(uuid: string) {
        return ApiService.delete(`/majors/suggestions/${uuid}`)
    },

    acceptSuggestion(uuid: string) {
        return ApiService.post(`/majors/suggestions/${uuid}/accept`)
    },
}

export default MajorService
