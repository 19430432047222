import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import InputElement from './InputElement'

interface Props {
    name: string
    type?: string
    placeholder: string
    tooltip?: string
    onUpdate?: (value: string) => void
    noMargin?: boolean
    onFocus?: () => void
    autoFocus?: boolean
    showCharactersCount?: boolean
    step?: string
    disabled?: boolean
    autoComplete?: string
}

const FormInput = (props: Props) => {
    const { name } = props
    const inputRef = useRef(null)
    const { fieldName, defaultValue, registerField, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        })
    }, [fieldName, registerField])

    const handleInput = (value: string) => {
        props.onUpdate && props.onUpdate(value)
    }

    return (
        <InputElement
            label={props.placeholder}
            noMargin={!!props.noMargin}
            onInput={handleInput}
            defaultValue={defaultValue}
            errorMessage={error}
            ref={inputRef}
            tooltip={props.tooltip}
            type={props.type}
            autoFocus={props.autoFocus}
            showCharactersCount={props.showCharactersCount}
            disabled={!!props.disabled}
            step={props.step}
            onFocus={props.onFocus}
            autoComplete={props.autoComplete}
        />
    )
}

export default FormInput
