import { FormHandles } from '@unform/core/typings/types'
import * as Sentry from '@sentry/react'
import { ApiError } from './services/ApiService'
import { toast } from 'react-toastify'
import { Currency } from 'interfaces/Transaction'

export function badRequestFormErrors(error: ApiError, formRef: FormHandles) {
    const fields = error.data as {
        [fieldName: string]: {
            type: string
            min: number | null
            max: number | null
        }
    }

    if (Object.keys(fields).length === 0) {
        console.error('The list of fields that failed form validation is empty.')
        toast.error('An error occurred while saving the form.')
    }

    for (const fieldName in fields) {
        // eslint-disable-next-line
        if (!formRef.getData().hasOwnProperty(fieldName)) {
            toast.error(`An error occurred while saving the form (Field: ${fieldName}).`)
            const errorMessage = `There is an unknown field '${fieldName}' in form validation error`
            console.error(errorMessage)
            Sentry.captureException(error, {
                tags: {
                    message: errorMessage,
                },
            })
        }

        const field = fields[fieldName]
        switch (field.type) {
            case 'required':
                formRef.setFieldError(fieldName, 'This field must not be empty.')
                break
            case 'size':
                if (field.min && field.min === 1) {
                    formRef.setFieldError(fieldName, `This field must contain at least 1 element.`)
                } else if (field.min) {
                    formRef.setFieldError(fieldName, `This field must contain at least ${field.min} elements`)
                } else {
                    formRef.setFieldError(fieldName, `The field can contain maximum of ${field.max} elements.`)
                }
                formRef.setFieldError(fieldName, 'This field must not be empty.')
                break
            case 'length':
                if (field.min) {
                    formRef.setFieldError(fieldName, `This field must be atleast ${field.min} characters long.`)
                } else {
                    formRef.setFieldError(fieldName, `The field can contain maximum of ${field.max} characters long.`)
                }
                break
            case 'e164':
                formRef.setFieldError('phoneNumber', 'Please enter a valid phone number with area code (e.g. +49)')
                break
            default:
                // eslint-disable-next-line no-case-declarations
                const errorMessage = `There is an unknown form validation error type: ${error.message}`
                formRef.setFieldError(fieldName, errorMessage)
                Sentry.captureException(error, {
                    tags: {
                        message: errorMessage,
                    },
                })
        }
    }
}

export function formatAmount(amount: number, currency: Currency): string {
    return new Intl.NumberFormat('en', { style: 'currency', currency: currency }).format(amount / 100)
}

export function formatTime(date: string | Date): string {
    const dateObject = new Date(date)
    return dateObject.toLocaleTimeString('de-DE', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })
}

export function formatDate(date: string | Date): string {
    const dateObject = new Date(date)
    return dateObject.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })
}

export function formatDateTime(date: string | Date): string {
    const dateObject = new Date(date)
    const formattedDate = formatDate(dateObject)
    const formattedTime = formatTime(dateObject)

    return `${formattedDate} at ${formattedTime}`
}

export function formatDateTimeUTC(date: string | Date, showDifference?: boolean): string {
    const utcDate = new Date(date)
    const day = String(utcDate.getUTCDate()).padStart(2, '0')
    const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0')
    const year = utcDate.getUTCFullYear()
    const hours = String(utcDate.getUTCHours()).padStart(2, '0')
    const minutes = String(utcDate.getUTCMinutes()).padStart(2, '0')
    const seconds = String(utcDate.getUTCSeconds()).padStart(2, '0')
    const formattedDate = `${day}.${month}.${year} at ${hours}:${minutes}:${seconds} (UTC)`

    const difference = _formatDifference(new Date(date))
    if (difference && showDifference) {
        return `${formattedDate} (${difference})`
    }

    return formattedDate
}

export function getDateDaysDifference(date: string) {
    const diffInMs = new Date(date).getTime() - new Date().getTime()
    return Math.floor(diffInMs / (1000 * 60 * 60 * 24))
}

export function formatTimeDifference(date: string, showUTCTime?: boolean): string {
    const formattedDate = showUTCTime ? `${new Date(date).toUTCString()} (UTC Time)` : formatDate(date)
    const difference = _formatDifference(new Date(date))
    if (!difference) {
        return formattedDate
    }

    return `${formattedDate} (${difference})`
}

export function _formatDifference(date: Date): string | null {
    const diffInDays = getDateDaysDifference(date.toISOString())
    const daysAbsolute = Math.abs(diffInDays)
    const unit = daysAbsolute === 1 ? 'day' : 'days'

    const isToday = new Date(date).toDateString() === new Date().toDateString() || diffInDays === 0
    if (isToday) {
        return null
    }

    const isFuture = diffInDays > 0
    if (isFuture) {
        return `in ${daysAbsolute} ${unit}`
    }

    return `${daysAbsolute} ${unit} ago`
}

export const humanReadableTimeAgoLabel = (date: string) => {
    const lastProcessedDate = new Date(date)
    const dateNow = new Date()

    let seconds = Math.floor((+dateNow - +lastProcessedDate) / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    hours = hours - days * 24
    minutes = minutes - days * 24 * 60 - hours * 60
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

    return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds ago`
}

export function getUrlParam(name: string): string | null {
    const url = new URL(window.location.toString())
    const urlParams = new URLSearchParams(url.search.slice(1))
    return urlParams.get(name)
}

export const formatText = (title: string, maxLength: number) =>
    title.length > maxLength ? `${title.slice(0, 30)}...` : title

export function setUrlParam(name: string, value: string | null) {
    const url = new URL(window.location.toString())
    const urlParams = url.searchParams
    if (value !== null) {
        urlParams.set(name, value)
    } else {
        urlParams.delete(name)
    }
    window.history.pushState(null, window.document.title, url.toString())
}

const redirectUrlParam = 'redirect_ids'

export function getNextRedirectUuid(currentUuid: string) {
    const rawRemainingIds = getUrlParam(redirectUrlParam)
    if (!rawRemainingIds) return undefined

    let remainingUuids = JSON.parse(rawRemainingIds) as string[]
    remainingUuids = remainingUuids.filter((id) => id !== currentUuid)
    if (remainingUuids.length === 0) {
        return null
    }
    return {
        nextUuid: remainingUuids[0],
        remainingUuids,
    }
}

export function isUUID(value: string) {
    const match = value.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')
    return match !== null
}

export const uniqueBy = <T>(uniqueKey: keyof T, objects: T[]): T[] => {
    const ids = objects.map((object) => object[uniqueKey])
    return objects.filter((object, index) => !ids.includes(object[uniqueKey], index + 1))
}

export const formatCamelCase = (text: string) => text.replace(/([A-Z]+)/g, ' $1').replace(/^ /, '')

export const formatScreamingSnakeCase = (s: string) =>
    s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase())).toLowerCase()

export function colorToCssFilter(hex: string) {
    // There is no easy way to change the color of an SVG icon that is loaded from a remote location.
    // Therefore, we have to use css filters to change the color. Since the algorithm is rather complex
    // to calculate the color, we are hard-coding the filter values here.
    // Reference: https://stackoverflow.com/a/53336754/7378726
    // Reference: https://codepen.io/sosuke/pen/Pjoqqp
    const colors: Record<string, string> = {
        '#0fd246': 'invert(58%) sepia(100%) saturate(840%) hue-rotate(84deg) brightness(93%) contrast(94%)',
        '#ffc01e': 'invert(69%) sepia(52%) saturate(697%) hue-rotate(353deg) brightness(106%) contrast(101%)',
        '#ff1887': 'invert(47%) sepia(92%) saturate(7055%) hue-rotate(318deg) brightness(100%) contrast(105%)',
        '#1882ff': 'invert(38%) sepia(83%) saturate(2700%) hue-rotate(199deg) brightness(101%) contrast(101%)',
        '#e8df44': 'invert(86%) sepia(44%) saturate(643%) hue-rotate(4deg) brightness(98%) contrast(94%)',
        '#000000': 'invert(0%) sepia(3%) saturate(0%) hue-rotate(238deg) brightness(99%) contrast(105%)',
        '#ffa401': 'invert(46%) sepia(99%) saturate(661%) hue-rotate(351deg) brightness(103%) contrast(107%)',
        '#36f9cf': 'invert(72%) sepia(56%) saturate(513%) hue-rotate(111deg) brightness(106%) contrast(95%)',
        '#7852ff': 'invert(54%) sepia(74%) saturate(7336%) hue-rotate(240deg) brightness(103%) contrast(101%)',
    }

    const filter = colors[hex.toLowerCase()]
    return filter || colors['#000000']
}

export function complimentingColor(hex: string) {
    const colors: Record<string, string> = {
        '#0fd246': '#cef7da',
        '#ffc01e': '#fff4d7',
        '#ff1887': '#fed1e4',
        '#1882ff': '#e6f2ff',
        '#e8df44': '#faffb0',
        '#ffa401': '#f8ebd4',
        '#000000': ' ',
    }
    const filter = colors[hex]
    return filter || colors['#000000']
}

export function getFileNameFromFileUrl(fileUrl: string) {
    return fileUrl?.split('/')?.pop() ?? ''
}

export const formatUTCDateForRequest = (date: string) => {
    return new Date(`${date}:00.000Z`).toISOString()
}
