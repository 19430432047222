export enum AdAttachmentType {
    Link = 'LINK',
    Event = 'EVENT',
    Survey = 'APPLICATION_SURVEY',
}

export type AdsAttachmentTOS = {
    text: string
    isRequired: boolean
}

export type AdAttachment = {
    uuid: string
    customerUuid: string
    sortId: number
    type: string
    applicationSurvey: AdAttachmentSurvey
    events: AdAttachmentEvent[]
    link: AdAttachmentLink
    externalTOSLink: string | null
}

export type AdAttachmentRequest = {
    customerUuid: string
    sortId: number
    externalTOSLink: string | null
    type: string
    applicationSurvey?: AdAttachmentSurvey
    event?: AdAttachmentEvent
    link?: AdAttachmentLink
}

export enum ApplicationSurveyType {
    Careers = 'CAREERS',
    GPA = 'GPA',
}

export type SurveyQuestion = {
    answerOptions: string[]
    isRequired: boolean
    question: string
    type?: ApplicationSurveyType
}

export type AdAttachmentSurvey = {
    title: string
    typeformUrl: string | null
    additionalQuestions: SurveyQuestion[]
    description: string
    tosList?: AdsAttachmentTOS[]
}

export type AdAttachmentLink = {
    name: string
    url: string
}

export type AdAttachmentEvent = {
    title: string
    startsOn: string
    endsOn: string
    description: string | null
    location: string | null
}
