import React, { useCallback, useEffect, useState } from 'react'
import Search from 'components/elements/Search'
import RefactoredTable, { LoadDataFunction } from 'components/modules/RefactoredTable'
import SelectElement from 'components/inputs/SelectElement'
import {
    reviewGroupOptions,
    formatOptions,
    statusOptions,
    internalRatingOptions,
    isAmbassadorOptions,
} from './filterOptions'
import { Grid, Title, FiltersWrapper } from './KnowsPage.styles'
import { KnowFormat, KnowStatus } from 'interfaces/Know'
import { useSelector } from 'react-redux'
import { InternalRatingType, ReviewGroup, ReviewSettings } from 'interfaces/ReviewSettings'
import { Subject } from 'interfaces/Subject'
import { SelectOptionWithLabel } from 'components/inputs/Input'
import { trackPromise } from 'react-promise-tracker'
import KnowService from 'services/KnowService'
import { ApiError } from 'services/ApiService'
import SubjectService from 'pages/Subjects/SubjectService'
import routes from 'lib/constants/routes'
import { NoWrapText } from 'style'
import { setReviewSettings } from 'redux/actions'
import { AppState } from 'redux/reducer'
import InputElement from 'components/inputs/InputElement'
import Meta from 'components/modules/Head'
import store from 'redux/store'
import { useCountryOptions } from 'hooks/useCountryOptions'
import { useLanguageOptions } from 'hooks/useLanguageOptions'
import { LanguageCode } from 'interfaces/Language'
import { AcademicTier } from 'interfaces/University'

const Knows = () => {
    const reviewSettings = useSelector((state: AppState) => state.reviewSettings)
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [subjectsOptions, setSubjectOptions] = useState<SelectOptionWithLabel<Subject | null>[]>()
    const [status, setStatus] = useState<KnowStatus>(KnowStatus.InReview)
    const [subject, setSubject] = useState<Subject | null>(null)
    const [academicTier, setAcademicTier] = useState<AcademicTier | null>(null)

    const loadingAreas = {
        container: 'knowsContainer',
    }

    const countryOptions = useCountryOptions({ includeNoFilter: true, useId: true })
    const languageOptions = useLanguageOptions({ includeNoFilter: true, showNotLaunched: true })

    const createReviewSettings = useCallback(() => {
        const newReviewSettings = {
            countryId: null,
            reviewGroup: null,
            format: null,
            status: KnowStatus.InReview,
            internalRating: null,
            isAmbassador: null,
            subject: null,
            createdAfter: null,
            createdBefore: null,
            language: null,
        }
        store.dispatch(setReviewSettings(newReviewSettings))
        return newReviewSettings
    }, [])

    const fetchData: LoadDataFunction = useCallback(
        (page: number, limit?: number) => {
            let tempSettings = reviewSettings

            if (!tempSettings) {
                tempSettings = createReviewSettings()
                return new Promise((_, reject) => {
                    reject()
                })
            }
            return trackPromise(
                KnowService.list(
                    page,
                    searchQuery,
                    status,
                    tempSettings?.reviewGroup ?? null,
                    tempSettings?.format ?? null,
                    tempSettings?.language ?? null,
                    tempSettings?.internalRating ?? null,
                    tempSettings?.isAmbassador ?? null,
                    subject ? [subject.id] : [],
                    tempSettings?.createdAfter ?? null,
                    tempSettings?.createdBefore ?? null,
                    academicTier ?? null,
                    limit
                )

                    .then((data) => {
                        if (page !== data.page) return
                        const redirectUuids = data.knows.map((k) => k.uuid)
                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.knows.map((k) => ({
                                id: k.uuid,
                                columns: [
                                    k.uuid,
                                    k.title,
                                    k?.knowType?.name,
                                    <NoWrapText key={k.uuid}>{k.status}</NoWrapText>,
                                    k?.subjects ? k.subjects.map((s) => s.name).join(', ') : 'Not specified',
                                    k?.grades ? k.grades.map((g) => g.shortName).join(', ') : 'Not specified',
                                    k.knower?.user ? `@${k.knower.user.username}` : 'knower',
                                ],
                                onClick: routes.knowRoute(k.uuid, { redirectUuids }),
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting knows.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [reviewSettings, searchQuery, status, subject, academicTier, loadingAreas.container, createReviewSettings]
    )

    useEffect(() => {
        if (!reviewSettings) return

        if (reviewSettings.countryId === null) {
            setSubjectOptions([{ label: 'No filter', identifier: '0', data: null }])
        }
        if (reviewSettings.countryId !== null) {
            SubjectService.list(reviewSettings.countryId)
                .then((subjects) => {
                    let innerSubjectsOptions: SelectOptionWithLabel<Subject | null>[] = []
                    innerSubjectsOptions = subjects
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((s) => ({ label: s.name, identifier: s.id.toString(), data: s }))

                    setSubjectOptions([{ label: 'No filter', identifier: '0', data: null }, ...innerSubjectsOptions])
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting subjects.')
                    } else {
                        throw error
                    }
                })
        }
    }, [reviewSettings])

    const handleSearch = (query: string) => {
        setSearchQuery(query)
    }

    const refetchKey = `${searchQuery}_${JSON.stringify(reviewSettings)}`

    const handleUpdate = (updateFunc: (reviewSettings: ReviewSettings) => ReviewSettings) => {
        if (!reviewSettings) return

        const newReviewSettings = updateFunc(reviewSettings)
        store.dispatch(setReviewSettings(newReviewSettings))
    }

    const handleUpdateSubjects = (newSubject: Subject | null) => {
        setSubject(newSubject)
    }

    const handleUpdateCountry = (countryId: number | null) => {
        handleUpdate((reviewSettings) => ({
            ...reviewSettings,
            subject: null,
            countryId,
        }))
    }

    const handleUpdateReviewGroup = (newReviewGroup: ReviewGroup | null) => {
        handleUpdate((reviewSettings) => ({
            ...reviewSettings,
            reviewGroup: newReviewGroup,
        }))
    }

    const handleUpdateFormat = (knowFormat: KnowFormat | null) => {
        handleUpdate((reviewSettings) => ({
            ...reviewSettings,
            format: knowFormat,
        }))
    }

    const handleUpdateStatus = (newStatus: KnowStatus) => {
        setStatus(newStatus)
    }

    const handleUpdateInternalRating = (newInternalRating: InternalRatingType) => {
        handleUpdate((reviewSettings) => ({
            ...reviewSettings,
            internalRating: newInternalRating,
        }))
    }

    const handleUpdateIsAmbassador = (newIsAmbassador: boolean | null) => {
        handleUpdate((reviewSettings) => ({
            ...reviewSettings,
            isAmbassador: newIsAmbassador,
        }))
    }

    const handleUpdateCreatedAfter = (newCreatedAfter: string | null) => {
        handleUpdate((reviewSettings) => ({
            ...reviewSettings,
            createdAfter: newCreatedAfter,
        }))
    }

    const handleUpdateCreatedBefore = (newCreatedBefore: string | null) => {
        handleUpdate((reviewSettings) => ({
            ...reviewSettings,
            createdBefore: newCreatedBefore,
        }))
    }

    function handleUpdateLanguage(language: LanguageCode): void {
        handleUpdate((reviewSettings) => ({
            ...reviewSettings,
            language,
        }))
    }

    const handleUpdateAcademicTier = (newAcademicTier: AcademicTier | null) => {
        setAcademicTier(newAcademicTier)
    }

    const statusDefaultValue = KnowStatus.InReview

    const academicTierOptions = Object.values(AcademicTier).map((tier) => ({
        label: tier,
        identifier: tier,
        data: tier,
    }))

    return (
        <section>
            <Meta title="Knows" />
            <Title>Knows</Title>
            <Grid>
                <RefactoredTable
                    columns={['#', 'Title', 'Type', 'Status', 'Subjects', 'Grades', 'Knower']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                    refetchKey={refetchKey}
                    allowTableLimitChange
                    defaultItemLimit={50}
                    hideTotalElements
                    hidePaginationOnTop
                />
                <FiltersWrapper>
                    <SelectElement
                        label="Language"
                        noMargin
                        options={languageOptions}
                        onUpdate={handleUpdateLanguage}
                        defaultValue={languageOptions.findIndex((language) => language.data === reviewSettings?.language)}
                    />
                    <SelectElement
                        label="Status"
                        noMargin
                        options={statusOptions}
                        onUpdate={handleUpdateStatus}
                        defaultValue={statusOptions.findIndex((status) => status.data === statusDefaultValue)}
                    />
                    <Search onSearch={handleSearch} fullWidth label="Title Search" hideButton noMarginTop />

                    <SelectElement
                        label="Country (for subject selection)"
                        noMargin
                        options={countryOptions}
                        onUpdate={handleUpdateCountry}
                        defaultValue={countryOptions.findIndex(
                            (country) => country.identifier === reviewSettings?.countryId?.toString()
                        )}
                    />
                    {subjectsOptions ? (
                        <SelectElement
                            noMargin
                            options={subjectsOptions}
                            label="Subject"
                            onUpdate={handleUpdateSubjects}
                            defaultValue={subjectsOptions
                                .findIndex((s) => s.identifier.toString() === subject?.id.toString())
                                .toString()}
                        />
                    ) : null}
                    <SelectElement
                        label="Review Group"
                        noMargin
                        options={reviewGroupOptions}
                        onUpdate={handleUpdateReviewGroup}
                        defaultValue={reviewGroupOptions.findIndex(
                            (reviewGroup) => reviewGroup.data === reviewSettings?.reviewGroup
                        )}
                    />
                    <SelectElement
                        label="Know Format"
                        noMargin
                        options={formatOptions}
                        onUpdate={handleUpdateFormat}
                        defaultValue={formatOptions.findIndex((format) => format.data === reviewSettings?.format)}
                    />
                    <SelectElement
                        label="Academic Tier"
                        noMargin
                        options={academicTierOptions}
                        onUpdate={handleUpdateAcademicTier}
                        defaultValue={academicTierOptions.findIndex((tier) => tier.data === academicTier)}
                    />

                    <SelectElement
                        label="Star rating"
                        noMargin
                        options={internalRatingOptions}
                        onUpdate={handleUpdateInternalRating}
                        defaultValue={internalRatingOptions.findIndex(
                            (rating) => rating.data === reviewSettings?.internalRating
                        )}
                    />
                    <SelectElement
                        label="Is ambassador"
                        noMargin
                        options={isAmbassadorOptions}
                        onUpdate={handleUpdateIsAmbassador}
                        defaultValue={isAmbassadorOptions.findIndex(
                            (option) => option.data === reviewSettings?.isAmbassador
                        )}
                    />
                    <InputElement
                        label="Created after"
                        type="date"
                        noMargin
                        onInput={handleUpdateCreatedAfter}
                        defaultValue={reviewSettings?.createdAfter ?? ''}
                    />
                    <InputElement
                        label="Created before"
                        type="date"
                        noMargin
                        onInput={handleUpdateCreatedBefore}
                        defaultValue={reviewSettings?.createdBefore ?? ''}
                    />
                </FiltersWrapper>
            </Grid>
        </section>
    )
}

export default Knows
