import { CSVData } from 'components/FileUpload/CSVImport'
import { UpdateSubject } from 'interfaces/Subject'

export const STANDARD_HEADERS = [
    'name',
    'sortId',
    'longName',
    'iconName',
    'categoryId',
    'gradeIds',
    'schoolTypeIds',
    'countryId',
    'englishName',
    'languageId',
]

export enum HeaderLabels {
    Name = 'Name',
    SortId = 'Sort Id',
    LongName = 'Long name',
    IconName = 'Icon name',
    CategoryId = 'Category Id',
    GradeId = 'Grade Id',
    SchoolTypeId = 'School type Id',
    CountryId = 'Country Id',
    EnglishName = 'English name',
    LanguageId = 'Language Id',
}

const validateCell = (cell: string | undefined) => cell && cell?.length > 0

export const returnMissingToastMessage = (cellData: string, columnName: string) =>
    `Cell data: (${cellData?.length > 0 ? cellData : 'empty'}) have no mathching values in ${columnName} row`

export const processSubjectBatchCSVData = (
    cvsData: CSVData[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toast: any
) => {
    const array: UpdateSubject[] = []
    for (const row of Object.values(cvsData)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formattedRow: any = {}

        let i = 0
        for (const [columnName, cellData] of Object.entries(row)) {
            switch (columnName) {
                case HeaderLabels.SortId:
                case HeaderLabels.CategoryId:
                case HeaderLabels.CountryId:
                case HeaderLabels.LanguageId:
                    formattedRow[STANDARD_HEADERS[i]] = cellData?.length ? +cellData : null
                    i++
                    break
                case HeaderLabels.GradeId:
                case HeaderLabels.SchoolTypeId:
                    formattedRow[STANDARD_HEADERS[i]] = cellData?.length ? cellData.split(',').map(Number) : null
                    i++
                    break
                default:
                    if (validateCell(cellData)) {
                        formattedRow[STANDARD_HEADERS[i]] = cellData
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    i++
                    break
            }
        }

        array.push(formattedRow)
    }
    return array
}
