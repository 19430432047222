import React, { useEffect, useState, useMemo } from 'react'
import CSVImporter, { CSVData } from 'components/FileUpload/CSVImport'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import { NoWrapText } from 'style'
import Table, { TableData } from 'components/modules/Table'
import Meta from 'components/modules/Head'
import { IconName, Subject, SubjectCategory } from 'interfaces/Subject'
import { processSubjectBatchCSVData, HeaderLabels, STANDARD_HEADERS } from 'lib/features/subjectBatchUpload'
import SubjectService from 'pages/Subjects/SubjectService'
import Modal from 'components/modules/Modal'
import { useToggleState } from 'utils/hooks/useToggleState'
import { useCountryOptions } from 'hooks/useCountryOptions'
import { subjectIconUrl } from 'lib/constants/urls'
import styled from 'styled-components'
import SelectElement from 'components/inputs/SelectElement'
import Button from 'components/elements/Button'
import Row from 'components/elements/Row'

const IconPreviewList = styled.ul`
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;

    li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 0.5rem;
        border: 1px solid #eee;
        border-radius: 4px;

        img {
            width: 24px;
            height: 24px;
        }
    }
`

const BulkSubjectUploadComponent = () => {
    const [subjectBatch, setSubjectBatch] = useState<Subject[]>([])
    const [tableData, setTableData] = useState<TableData[]>()
    const [iconNameOptions, setIconNamesOptions] = useState<IconName[]>([])
    const [categoriesOptions, setCategoriesOptions] = useState<
        { label: string; identifier: string; data: SubjectCategory }[]
    >([])
    const [showCategoriesModal, toggleCategoriesModal] = useToggleState()
    const [showIconsModal, toggleIconsModal] = useToggleState()
    const [selectedCountryId, setSelectedCountryId] = useState<number>(1)
    const countryOptions = useCountryOptions({ useId: true })

    const filteredCategories = useMemo(() => {
        if (!selectedCountryId) return categoriesOptions
        return categoriesOptions.filter((category) => category.data.countryId === selectedCountryId)
    }, [categoriesOptions, selectedCountryId])

    const handleCSVUploadComplete = async (csvData: CSVData[]) => {
        const headers = Object.keys(csvData[0])
        const headerLabels = Object.values(HeaderLabels)

        if (headers.length !== STANDARD_HEADERS.length) {
            toast.warn('Make sure to put correct headers (Name, Sort Id, ...) as the first row of the CSV file!')
            return
        }

        for (let i = 0; i < headerLabels.length; i++) {
            if (headers[i] !== headerLabels[i]) {
                toast.warn(`${headers[i]} is not part of the standard headers (Name, Sort Id, ...), check for typos!`)
                return
            }
        }

        const formattedSubjectBatchArray = await processSubjectBatchCSVData(csvData, toast)

        if (!formattedSubjectBatchArray || !formattedSubjectBatchArray.length) {
            toast.info('No subject batch data found')
            return
        }

        const progressText = document.getElementById('title')
        let count = 0
        const chunkSize = 50
        formattedSubjectBatchArray.unshift()
        for (let i = 0; i < formattedSubjectBatchArray.length; i += chunkSize) {
            const chunk = formattedSubjectBatchArray.slice(i, i + chunkSize)

            await SubjectService.bulkSubjectUpload(chunk)
                // eslint-disable-next-line no-loop-func
                .then((subjects) => {
                    setSubjectBatch((prevSubjectBatch) => (prevSubjectBatch ? [...prevSubjectBatch, ...subjects] : subjects))
                    count += subjects.length
                })

                .catch((error: ApiError) => {
                    error.handleUnknown(`An error occurred while subjects were being uploaded.`)
                })

            if (progressText) progressText.textContent = `Loading... Progress: ${i}/${csvData.length - 1}`
        }
        const text = `Imported ${count} subjects.`
        if (progressText) progressText.textContent = text
        toast.info(text)
    }

    useEffect(() => {
        if (!subjectBatch?.length) return
        const table = subjectBatch.map((c, id) => ({
            id,
            columns: [
                <NoWrapText key={`term-${id}`}>{c.name}</NoWrapText>,
                c.sortId,
                c.longName,
                <img src={c.iconUrl} alt={c.name} key={`icon-${id}`} />,
                c.category.name,
                c.grades.map((g) => g.level).join(', '),
                c.schoolTypes.map((s) => s.name).join(', '),
                c.country.name,
                c.englishName,
                c.language?.name,
            ],
        }))
        setTableData(table)
    }, [subjectBatch, setTableData])

    useEffect(() => {
        // Fetch categories and icons when component mounts
        SubjectService.getCategories().then((categories) =>
            setCategoriesOptions(categories.map((c) => ({ label: c.name, identifier: c.id.toString(), data: c })))
        )
        SubjectService.getIconNames().then(setIconNamesOptions)
    }, [])

    return (
        <section>
            <Meta title="Bulk Subject Upload" />
            <Stack>
                <h2>Bulk Subject Upload</h2>
                <Box>
                    <strong>Requirements:</strong>
                    <ul>
                        <li>
                            {`First row of the CSV file must contain correct Column Name: ${Object.values(HeaderLabels).join(
                                ', '
                            )} (in this order)`}
                        </li>
                        <li>{`All cell data values must be an exact match with values stored in the database`}</li>
                    </ul>
                    <br />
                    <Row>
                        <Button onClick={toggleCategoriesModal} fullWidth={false}>
                            View all available categories
                        </Button>

                        <Button onClick={toggleIconsModal} fullWidth={false}>
                            View all available icons
                        </Button>
                    </Row>
                    <br />
                    <strong>Example document:</strong>&nbsp;
                    <a href="/files/SubjectBatchUpload.csv" download="example_subject_batch_upload.csv">
                        Download
                    </a>
                    <br />
                    <CSVImporter
                        title="CSV Upload area for user subject data (not replaced)"
                        handleCSVUploadComplete={handleCSVUploadComplete}
                        showHeader
                    />
                    {tableData?.length ? (
                        <Table
                            columns={[
                                'Name',
                                'Sort Id',
                                'Long name',
                                'Icon',
                                'Category',
                                'Grades',
                                'School types',
                                'Country',
                                'English name',
                                'Language',
                            ]}
                            data={tableData}
                            page={0}
                            totalPages={1}
                            totalElements={tableData.length}
                        />
                    ) : null}
                    <Modal show={showCategoriesModal} onClose={toggleCategoriesModal} title="Available Categories">
                        <SelectElement
                            label="Country"
                            options={countryOptions}
                            onUpdate={(country: number) => setSelectedCountryId(country)}
                            noMargin={false}
                        />
                        <ul>
                            {filteredCategories
                                .sort((a, b) => a.data.id - b.data.id)
                                .map((category) => (
                                    <li key={category.data.id}>
                                        {category.data.name} (ID: {category.data.id})
                                    </li>
                                ))}
                        </ul>
                    </Modal>
                    <Modal show={showIconsModal} onClose={toggleIconsModal} title="Available Icons">
                        <IconPreviewList>
                            {iconNameOptions.map((icon, index) => (
                                <li key={index}>
                                    <img src={subjectIconUrl(icon.name)} alt={icon.name} />
                                    {icon.name}
                                </li>
                            ))}
                        </IconPreviewList>
                    </Modal>
                </Box>
            </Stack>
        </section>
    )
}

export default BulkSubjectUploadComponent
