import { CSVData } from 'components/FileUpload/CSVImport'
import { SchoolHolidayRequest } from 'interfaces/SchoolHoliday'

export const STANDARD_HEADERS = ['name', 'countryId', 'endDate', 'startDate', 'isActive', 'regionIds']

export enum HeaderLabels {
    Name = 'Name',
    CountryId = 'Country Id',
    EndDate = 'End Date',
    StartDate = 'Start Date',
    IsActive = 'isActive',
    RegionsId = 'Regions Id',
}

const validateCell = (cell: string | undefined) => cell && cell?.length > 0

export const returnMissingToastMessage = (cellData: string, columnName: string) =>
    `Cell data: (${cellData?.length > 0 ? cellData : 'empty'}) have no matching values in ${columnName} row`

export const processSchoolHolidayBatchCSVData = (
    csvData: CSVData[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toast: any
) => {
    const array: SchoolHolidayRequest[] = []
    for (const row of Object.values(csvData)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formattedRow: any = {}

        let i = 0
        for (const [columnName, cellData] of Object.entries(row)) {
            switch (columnName) {
                case HeaderLabels.CountryId:
                    formattedRow[STANDARD_HEADERS[i]] = cellData?.length ? +cellData : null
                    i++
                    break
                case HeaderLabels.RegionsId:
                    formattedRow[STANDARD_HEADERS[i]] = cellData?.length ? cellData.split(',').map(Number) : null
                    i++
                    break
                case HeaderLabels.IsActive:
                    formattedRow[STANDARD_HEADERS[i]] = cellData?.toLowerCase() === 'true'
                    i++
                    break
                case HeaderLabels.StartDate:
                case HeaderLabels.EndDate:
                    formattedRow[STANDARD_HEADERS[i]] = cellData ? new Date(cellData).toISOString() : null
                    i++
                    break
                default:
                    if (validateCell(cellData)) {
                        formattedRow[STANDARD_HEADERS[i]] = cellData
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    i++
                    break
            }
        }

        array.push(formattedRow)
    }
    return array
}
