/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, useRef, useState, useEffect } from 'react'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import { Course } from 'interfaces/Course'
import { School } from 'interfaces/School'
import { Major } from 'interfaces/Major'
import MajorService from 'services/MajorService'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import Spinner from 'components/elements/Spinner'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { toast } from 'react-toastify'
import useDebounce from 'utils/hooks/useDebounce'
import SchoolsService from 'services/SchoolsService'
import { Body2, Caption, ButtonText } from 'components/elements/Text'
import GenericFormElement from 'components/forms/GenericFormElement'
import { Input } from 'components/inputs/Input'
import theme from 'lib/constants/theme'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import InfiniteScroll from 'react-infinite-scroller'
import Button from 'components/elements/Button'
import { getCoursesPreview } from 'utils/university'

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding-top: 20px;
`

const Hidden = styled.div`
    display: none;
`

const SelectorContainer = styled.div`
    min-height: auto;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const InputWrapper = styled.div`
    position: relative;
    width: 100%;
`

const Dropdown = styled.div<{ isDarkTheme?: boolean }>`
    position: absolute;
    top: calc(100% - 15px);
    left: 0;
    right: 0;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.deepSeaBlue};
    border-radius: 0 0 ${theme.borderRadius.normal} ${theme.borderRadius.normal};
    z-index: 9999;
    max-height: 300px;
    overflow-y: auto;
`

const DropdownItem = styled.div<{ isDarkTheme?: boolean }>`
    padding: 16px;
    cursor: pointer;
    color: ${(props) => (props.isDarkTheme ? theme.colors.white : theme.colors.body1Black)};
    transition: background-color 0.2s ease;

    &:hover {
        background: ${theme.colors.deepSeaBlue};
    }
`

const CourseWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 10px 0;
`

const CourseItem = styled.div<{ selected: boolean; isDarkTheme?: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
        position: relative;
        top: 3px;
        path {
            scale: 0.8;
            fill: ${theme.colors.white};
        }
    }
    padding: 12px;
    border-radius: ${theme.borderRadius.normal};
    background-color: ${theme.colors.deepSeaBlue};
    cursor: pointer;
    width: fit-content;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: ${theme.colors.deepSeaBlue};
    }
`

interface Props {
    name: string
    label: string
    defaultCourses?: Course[]
    onConfirm?: (selectedCourses: Course[]) => void
    limitToOneSelectedItem?: boolean
    children?: ReactNode
    onSave?: (university: School | null, major: Major | null, courses: Course[]) => void
    title?: string
    isDarkTheme?: boolean
    defaultUniversity?: School | null
    defaultMajor?: Major | null
}

const UniversityMajorAndCourseSelection = (props: Props) => {
    const [showDialog, toggleShowDialog] = useToggleState(false)
    const [universitySearch, setUniversitySearch] = useState('')
    const [majorSearch, setMajorSearch] = useState('')
    const [courseSearch, setCourseSearch] = useState('')
    const [showDropdown, setShowDropdown] = useState(false)
    const { promiseInProgress } = usePromiseTracker()

    // Data lists
    const [universities, setUniversities] = useState<School[]>([])
    const [majors, setMajors] = useState<Major[]>([])
    const [courses, setCourses] = useState<Course[]>([])

    // Selected values
    const [selectedUniversity, setSelectedUniversity] = useState<School | null>(props.defaultUniversity ?? null)
    const [selectedMajor, setSelectedMajor] = useState<Major | null>(props.defaultMajor ?? null)
    const [selectedCourses, setSelectedCourses] = useState<Course[]>(props.defaultCourses ?? [])

    const inputRef = useRef<HTMLDivElement>(null)
    const universityDropdownRef = useRef<HTMLDivElement>(null)
    const majorDropdownRef = useRef<HTMLDivElement>(null)
    const courseDropdownRef = useRef<HTMLDivElement>(null)

    // Add new state to track which dropdown is active
    const [activeDropdown, setActiveDropdown] = useState<'university' | 'major' | 'course' | null>(null)

    const debouncedUniversitySearch = useDebounce(universitySearch, 300)
    const debouncedMajorSearch = useDebounce(majorSearch, 300)
    const debouncedCourseSearch = useDebounce(courseSearch, 300)

    const [hasMoreCourses, setHasMoreCourses] = useState(true)
    const [hasMoreMajors, setHasMoreMajors] = useState(true)

    useEffect(() => {
        trackPromise(
            SchoolsService.search(debouncedUniversitySearch, 'DE')
                .then((schools) => {
                    const sortedUniversities = schools
                    setUniversities(sortedUniversities)
                })
                .catch(() => {
                    setUniversities([])
                    toast.error('Error occurred while fetching universities')
                })
        )
    }, [debouncedUniversitySearch])

    useEffect(() => {
        if (selectedUniversity?.uuid) {
            setMajors([])
            setHasMoreMajors(true)
            loadMoreMajors(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedMajorSearch, selectedUniversity])

    useEffect(() => {
        if (selectedMajor?.uuid && selectedUniversity?.uuid) {
            setCourses([])
            setHasMoreCourses(true)
            loadMoreCourses(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedCourseSearch, selectedMajor, selectedUniversity])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const isClickInside = [inputRef, universityDropdownRef, majorDropdownRef, courseDropdownRef].some(
                (ref) => ref.current && ref.current.contains(event.target as Node)
            )

            if (!isClickInside) {
                setShowDropdown(false)
                setActiveDropdown(null)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleInputFocus = (type: 'university' | 'major' | 'course') => {
        setActiveDropdown(type)
        setShowDropdown(true)
    }

    const handleInputChange = (value: string, type: 'university' | 'major' | 'course') => {
        switch (type) {
            case 'university':
                setUniversitySearch(value)
                break
            case 'major':
                setMajorSearch(value)
                break
            case 'course':
                setCourseSearch(value)
                break
        }
        setShowDropdown(true)
    }

    const handleUniversitySelect = (university: School) => {
        if (university.uuid !== selectedUniversity?.uuid) {
            setSelectedMajor(null)
            setSelectedCourses([])
            setMajorSearch('')
            setCourseSearch('')
        }
        setSelectedUniversity(university)
        setUniversitySearch('')
        setShowDropdown(false)
        setActiveDropdown(null)
    }

    const handleMajorSelect = (major: Major) => {
        if (major.uuid !== selectedMajor?.uuid) {
            setSelectedCourses([])
            setCourseSearch('')
        }
        setSelectedMajor(major)
        setMajorSearch('')
        setShowDropdown(false)
        setActiveDropdown(null)
    }

    const toggleCourse = (course: Course) => {
        if (props.limitToOneSelectedItem) {
            setSelectedCourses([course])
            return
        }

        const isSelected = selectedCourses.some((c) => c.uuid === course.uuid)

        if (isSelected) {
            setSelectedCourses(selectedCourses.filter((c) => c.uuid !== course.uuid))
        } else {
            setSelectedCourses([...selectedCourses, course])
        }
    }

    const selectedPreview = getCoursesPreview(selectedCourses, true, 'Select Courses')

    const loadMoreCourses = (page: number) => {
        if (!selectedMajor?.uuid || !selectedUniversity?.uuid) return

        trackPromise(
            MajorService.searchCourses(selectedMajor.uuid, debouncedCourseSearch, selectedUniversity.uuid, page)
                .then((response) => {
                    const newCourses = response.content

                    setCourses((prevCourses) => [...prevCourses, ...newCourses])
                    setHasMoreCourses(!response.last)
                })
                .catch(() => {
                    setCourses([])
                    setHasMoreCourses(false)
                    toast.error('Error occurred while fetching courses')
                })
        )
    }

    const loadMoreMajors = (page: number) => {
        if (!selectedUniversity?.uuid) return

        trackPromise(
            MajorService.search(debouncedMajorSearch, selectedUniversity.uuid, page)
                .then((response) => {
                    const newMajors = response.content.sort((a, b) =>
                        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
                    )

                    setMajors((prevMajors) => [...prevMajors, ...newMajors])
                    setHasMoreMajors(!response.last)
                })
                .catch(() => {
                    setMajors([])
                    setHasMoreMajors(false)
                    toast.error('Error occurred while fetching majors')
                })
        )
    }

    const renderContent = () => {
        return (
            <Stack gutter={10}>
                <Stack gutter={10}>
                    <InputWrapper ref={inputRef}>
                        <Input
                            name="university"
                            //defaultValue={universitySearch}
                            onUpdate={(value) => handleInputChange(value, 'university')}
                            onFocus={() => handleInputFocus('university')}
                            placeholder={selectedUniversity?.name || 'Select University'}
                            autoComplete="off"
                            noMargin
                        />
                        {showDropdown && activeDropdown === 'university' && (
                            <Dropdown
                                ref={universityDropdownRef as React.RefObject<HTMLDivElement>}
                                isDarkTheme={props.isDarkTheme}
                            >
                                {promiseInProgress ? (
                                    <DropdownItem isDarkTheme={props.isDarkTheme}>
                                        <Spinner />
                                    </DropdownItem>
                                ) : (
                                    <>
                                        {universities.length > 0
                                            ? universities.map((university) => (
                                                  <DropdownItem
                                                      key={university.uuid}
                                                      onClick={() => handleUniversitySelect(university)}
                                                      isDarkTheme={props.isDarkTheme}
                                                  >
                                                      {university.name}
                                                  </DropdownItem>
                                              ))
                                            : universitySearch.length > 1 && (
                                                  <DropdownItem isDarkTheme={props.isDarkTheme}>
                                                      No Universities Found
                                                  </DropdownItem>
                                              )}
                                    </>
                                )}
                            </Dropdown>
                        )}
                    </InputWrapper>
                </Stack>

                <Stack>
                    <InputWrapper>
                        <Input
                            name="major"
                            //value={majorSearch}
                            onUpdate={(value) => handleInputChange(value, 'major')}
                            onFocus={() => handleInputFocus('major')}
                            placeholder={selectedMajor?.name || 'Select Major'}
                            autoComplete="off"
                            noMargin
                            disabled={!selectedUniversity}
                        />
                        {showDropdown && activeDropdown === 'major' && (
                            <Dropdown
                                ref={majorDropdownRef as React.RefObject<HTMLDivElement>}
                                isDarkTheme={props.isDarkTheme}
                            >
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={loadMoreMajors}
                                    hasMore={hasMoreMajors}
                                    useWindow={false}
                                    initialLoad={false}
                                    threshold={50}
                                    loader={
                                        <DropdownItem key="major-loader" isDarkTheme={props.isDarkTheme}>
                                            <Spinner />
                                        </DropdownItem>
                                    }
                                >
                                    {majors?.length > 0
                                        ? majors.map((major) => (
                                              <DropdownItem
                                                  key={major.uuid}
                                                  onClick={() => handleMajorSelect(major)}
                                                  isDarkTheme={props.isDarkTheme}
                                              >
                                                  <Stack gutter={4}>
                                                      <span>{major.name}</span>
                                                      <Caption>{major.degree}</Caption>
                                                  </Stack>
                                              </DropdownItem>
                                          ))
                                        : majorSearch.length > 1 && (
                                              <DropdownItem isDarkTheme={props.isDarkTheme}>No Majors Found</DropdownItem>
                                          )}
                                </InfiniteScroll>
                            </Dropdown>
                        )}
                    </InputWrapper>
                </Stack>

                <Stack>
                    <InputWrapper>
                        <Input
                            name="course"
                            //value={courseSearch}
                            onUpdate={(value) => handleInputChange(value, 'course')}
                            onFocus={() => handleInputFocus('course')}
                            placeholder={selectedCourses.length > 0 ? selectedPreview : 'Search for a course'}
                            autoComplete="off"
                            noMargin
                            disabled={!selectedMajor}
                        />
                        {showDropdown && activeDropdown === 'course' && (
                            <Dropdown
                                ref={courseDropdownRef as React.RefObject<HTMLDivElement>}
                                isDarkTheme={props.isDarkTheme}
                            >
                                <div style={{ maxHeight: '300px' }}>
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={loadMoreCourses}
                                        hasMore={hasMoreCourses}
                                        useWindow={false}
                                        initialLoad={false}
                                        threshold={50}
                                        loader={
                                            <DropdownItem key="course-loader" isDarkTheme={props.isDarkTheme}>
                                                <Spinner />
                                            </DropdownItem>
                                        }
                                    >
                                        {courses?.length > 0
                                            ? courses.map((course) => (
                                                  <DropdownItem
                                                      key={course.uuid}
                                                      onClick={() => {
                                                          toggleCourse(course)
                                                          setShowDropdown(false)
                                                      }}
                                                      isDarkTheme={props.isDarkTheme}
                                                  >
                                                      {course.name}
                                                  </DropdownItem>
                                              ))
                                            : null}
                                    </InfiniteScroll>
                                </div>
                            </Dropdown>
                        )}
                    </InputWrapper>

                    {selectedCourses.length > 0 && (
                        <CourseWrapper>
                            {selectedCourses.map((course) => (
                                <CourseItem
                                    key={course.uuid}
                                    selected={true}
                                    onClick={() => toggleCourse(course)}
                                    isDarkTheme={props.isDarkTheme}
                                >
                                    <Body2 color={theme.colors.white}>{course.name}</Body2>
                                    <CloseIcon />
                                </CourseItem>
                            ))}
                        </CourseWrapper>
                    )}
                </Stack>
            </Stack>
        )
    }

    return (
        <>
            <CourseSelectionDialog open={showDialog} handleClose={toggleShowDialog}>
                <SelectorContainer>
                    {renderContent()}
                    <ButtonsWrapper>
                        <Button
                            onClick={toggleShowDialog}
                            fullWidth
                            color={theme.colors.deepSeaBlue}
                            disabled={selectedCourses.length === 0}
                        >
                            <ButtonText color={theme.colors.white}>Confirm</ButtonText>
                        </Button>
                    </ButtonsWrapper>
                </SelectorContainer>
            </CourseSelectionDialog>

            <Hidden>
                <GenericFormElement
                    name="university"
                    onClick={toggleShowDialog}
                    label=""
                    placeholder=""
                    value={selectedUniversity}
                    onUpdate={() => {}}
                    selectedPreview=""
                />
            </Hidden>
            {props.children ? (
                <>
                    <div onClick={toggleShowDialog}>{props.children}</div>
                    <Hidden>
                        <GenericFormElement
                            data-id="courses"
                            name={props.name}
                            onClick={toggleShowDialog}
                            label={props.label}
                            placeholder={props.label}
                            value={selectedCourses}
                            selectedPreview={selectedPreview}
                            onUpdate={() => {}}
                        />
                    </Hidden>
                </>
            ) : (
                <GenericFormElement
                    data-id="courses"
                    name={props.name}
                    onClick={toggleShowDialog}
                    label={props.label}
                    placeholder={props.label}
                    value={selectedCourses}
                    selectedPreview={selectedPreview}
                    onUpdate={() => {}}
                />
            )}
        </>
    )
}

interface CourseSelectionDialogProps {
    open: boolean
    handleClose: () => void
    children?: ReactNode
    title?: string
    backgroundColor?: string
    isDarkTheme?: boolean
    headerColor?: string
    showAddDialog?: boolean
    addDialogTitle?: string
    addDialogContent?: ReactNode
}

const CourseSelectionDialog = ({ open, handleClose, children, title, isDarkTheme }: CourseSelectionDialogProps) => (
    <>
        <Modal show={open} title={title ?? 'Select Courses'} onClose={handleClose}>
            {children}
        </Modal>
    </>
)

export default UniversityMajorAndCourseSelection
