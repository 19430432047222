import { useEffect, useState } from 'react'
import { Know, KnowStatus } from 'interfaces/Know'
import routes from 'lib/constants/routes'
import { trackPromise } from 'react-promise-tracker'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import KnowService from 'services/KnowService'
import { getNextRedirectUuid } from 'utils'
import { Know as KnowInterface } from 'interfaces/Know'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { KnowRejectionReason } from 'interfaces/KnowRejectionReasons'

interface UseActionsMenuBarProps {
    isKnowInReview: boolean
    knowUuid: string
    showEditKnowModal: boolean
    updateKnow: (know: Know) => void
}

export const useActionsMenuBar = ({ isKnowInReview, updateKnow, showEditKnowModal, knowUuid }: UseActionsMenuBarProps) => {
    const [showRejectModal, setShowRejectModal] = useState(false)
    const history = useHistory()

    const loadingAreas = {
        save: 'saveKnow',
        history: 'knowReviewHistoryContainer',
        delete: 'deleteKnow',
        plagiarism: 'plagiarismKnow',
    }

    const goToNextReviewKnow = () => {
        const nextRedirect = getNextRedirectUuid(knowUuid)
        if (nextRedirect === undefined) {
            return
        } else if (nextRedirect === null) {
            history.push(routes.knowsRoute())
            return
        }

        history.push(routes.knowRoute(nextRedirect.nextUuid, { redirectUuids: nextRedirect.remainingUuids }))
    }

    const publishKnow = () => {
        trackPromise(
            KnowService.updateStatus(knowUuid, KnowStatus.Public, null)
                .then((know) => {
                    updateKnow(know)
                    goToNextReviewKnow()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while publishing the know.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    const putKnowIntoExtendedReview = () => {
        trackPromise(
            KnowService.updateStatus(knowUuid, KnowStatus.InExtendedReview, null)
                .then((know) => {
                    updateKnow(know)
                    goToNextReviewKnow()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while putting the know in extended review.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    const rejectKnow = (rejectionReason: KnowRejectionReason) => {
        trackPromise(
            KnowService.updateStatus(knowUuid, rejectionReason.newStatus, rejectionReason.name)
                .then((know) => {
                    setShowRejectModal(false)
                    updateKnow(know)
                    goToNextReviewKnow()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while rejecting the know.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    const moveKnow = () => {
        const knowerUuidInput = window.prompt('To whom do you want to transfer the know (Knower-Uuid)?')
        if (knowerUuidInput === null) return
        if (!knowerUuidInput) {
            toast.error('Please enter a valid Knower ID.')
            return
        }

        trackPromise(
            KnowService.move(knowUuid, knowerUuidInput)
                .then((know) => {
                    updateKnow(know)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while moving the know.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    const controlKnow = () => {
        setShowRejectModal(true)
    }

    const deleteKnow = () => {
        if (!window.confirm('Do you really want to delete this know?')) return

        trackPromise(
            KnowService.delete(knowUuid)
                .then(() => {
                    toast.success('The know has been deleted')
                    goToNextReviewKnow()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.KnowIsUndeletable) {
                            toast.error(
                                "Knows that are created for a know demand or during a content contest can't be deleted"
                            )
                        } else {
                            error.handleUnknown('An error occurred while deleting the know.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.delete
        )
    }

    const onKnowInternalRatingUpdate = (know: KnowInterface) => {
        updateKnow(know)
        if ((know.status === KnowStatus.InReview || know.status === KnowStatus.InExtendedReview) && !know.publishedOn) {
            publishKnow()
        }
    }

    const updateInternalRating = (rating: number) => {
        trackPromise(
            KnowService.updateInternalRating(knowUuid, rating)
                .then((know) => {
                    updateKnow(know)
                    onKnowInternalRatingUpdate(know)
                })

                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while updating the internal rating.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (showEditKnowModal) return

            if (event.target && (event.target as Element).nodeName === 'INPUT') {
                return
            }

            if (event.key === 'p') {
                publishKnow()
            } else if (event.key === 'c') {
                setShowRejectModal(true)
            } else if (event.key === 'e' && isKnowInReview) {
                putKnowIntoExtendedReview()
            } else if (event.key === 't' && !isKnowInReview) {
                moveKnow()
            } else if (event.key === 'd') {
                deleteKnow()
            } else if (event.key === 's') {
                updateInternalRating(3)
            }
        }

        document.addEventListener('keydown', handler)

        return () => {
            document.removeEventListener('keydown', handler)
        }
    })

    return {
        handleControlButton: controlKnow,
        handlePublishButton: publishKnow,
        moveKnow,
        putKnowIntoExtendedReview,
        rejectKnow,
        deleteKnow,
        onKnowInternalRatingUpdate,
        showRejectModal,
        setShowRejectModal,
    }
}
