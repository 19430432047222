import { Knower } from './Knower'

export type Currency = string
export interface Transaction {
    createdOn: string
    knower: Knower
    amount: number
    type: string
    currency: Currency
}

export interface AdminTransaction extends Transaction {
    description: string | null
    employeeUserUuid: string | null
}

export enum TransactionType {
    Manual = 'MANUAL',
}
