import React, { useEffect, useState, useRef } from 'react'
import styles from './Table.module.css'
import isValidHttpUrl from 'utils/isValidHttpUrl'
import { usePromiseTracker } from 'react-promise-tracker'
import Spinner from '../elements/Spinner'
import styled from 'styled-components'
import theme from 'lib/constants/theme'
import { toast } from 'react-toastify'

const TextLink = styled.div`
    color: ${theme.colors.white} !important;
    height: 100%;
    width: 100%;
`

const ActionButton = styled.button<{ actionType: 'accept' | 'reject' }>`
    background-color: transparent;
    border: 1px solid ${(props) => (props.actionType === 'accept' ? theme.colors.forestGreen : theme.colors.secondaryRed)};
    color: ${(props) => (props.actionType === 'accept' ? theme.colors.forestGreen : theme.colors.secondaryRed)};
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 8px;
    transition: all 0.2s ease;
    white-space: nowrap;

    &:hover {
        background-color: ${(props) =>
            props.actionType === 'accept' ? theme.colors.forestGreen : theme.colors.secondaryRed};
        color: ${theme.colors.white};
    }
`

const StatusLabel = styled.div<{ status: 'accepted' | 'rejected' }>`
    background-color: ${(props) => (props.status === 'accepted' ? theme.colors.forestGreen : theme.colors.secondaryRed)};
    color: ${theme.colors.white};
    padding: 6px 12px;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
`

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export interface SuggestionData {
    id: string
    columns: (string | React.ReactNode)[]
    processed?: {
        accepted?: boolean
        rejected?: boolean
    }
}

export interface SuggestionPaginationResponse {
    content: SuggestionData[]
    totalPages: number
    totalElements: number
}

interface Props {
    columns: (string | React.ReactNode)[]
    fetchData: (page: number) => Promise<SuggestionPaginationResponse>
    loadingArea: string
    onAcceptClick: (id: string) => Promise<void>
    onRejectClick: (id: string) => Promise<void>
    refetchKey?: number | string | boolean
}

const SuggestionTable = (props: Props) => {
    const { promiseInProgress } = usePromiseTracker({ area: props.loadingArea })
    const [elements, setElements] = useState<SuggestionData[]>([])
    const [processingIds, setProcessingIds] = useState<Set<string>>(new Set())
    const isLoadingRef = useRef(false)

    const { fetchData, onAcceptClick, onRejectClick } = props

    const loadData = async (page = 0) => {
        if (isLoadingRef.current) return

        try {
            isLoadingRef.current = true
            const data = await fetchData(page)
            setElements(data.content)
        } catch (error) {
            toast.error('Failed to load suggestions')
            console.error('Failed to load suggestions:', error)
        } finally {
            isLoadingRef.current = false
        }
    }

    useEffect(() => {
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData, props.refetchKey])

    useEffect(() => {
        const isAllProcessed = elements.every((element) => element.processed?.accepted || element.processed?.rejected)

        if (isAllProcessed) {
            loadData(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements])

    const handleAccept = async (id: string) => {
        if (processingIds.has(id)) return

        try {
            setProcessingIds((prev) => new Set(prev).add(id))
            await onAcceptClick(id)

            setElements((prevElements) => {
                const updated = prevElements.map((element) =>
                    element.id === id
                        ? {
                              ...element,
                              processed: {
                                  accepted: true,
                                  rejected: false,
                              },
                          }
                        : element
                )

                const allProcessed = updated.every((el) => el.processed?.accepted || el.processed?.rejected)
                if (allProcessed) {
                    setTimeout(() => loadData(0), 300)
                }

                return updated
            })

            toast.success('Suggestion accepted')
        } catch (error) {
            toast.error('Failed to accept suggestion')
            console.error('Failed to accept suggestion:', error)
        } finally {
            setProcessingIds((prev) => {
                const updated = new Set(prev)
                updated.delete(id)
                return updated
            })
        }
    }

    const handleReject = async (id: string) => {
        if (processingIds.has(id)) return

        try {
            setProcessingIds((prev) => new Set(prev).add(id))
            await onRejectClick(id)

            setElements((prevElements) => {
                const updated = prevElements.map((element) =>
                    element.id === id
                        ? {
                              ...element,
                              processed: {
                                  accepted: false,
                                  rejected: true,
                              },
                          }
                        : element
                )

                const allProcessed = updated.every((el) => el.processed?.accepted || el.processed?.rejected)
                if (allProcessed) {
                    setTimeout(() => loadData(0), 300)
                }

                return updated
            })

            toast.success('Suggestion rejected')
        } catch (error) {
            toast.error('Failed to reject suggestion')
            console.error('Failed to reject suggestion:', error)
        } finally {
            setProcessingIds((prev) => {
                const updated = new Set(prev)
                updated.delete(id)
                return updated
            })
        }
    }

    const isValid = elements.length > 0 && !promiseInProgress

    const renderCell = (content: string | React.ReactNode) => {
        if (typeof content !== 'string') {
            return content
        }

        if (isValidHttpUrl(content)) {
            return (
                <a href={content} target="_blank" rel="noreferrer">
                    <TextLink>{content}</TextLink>
                </a>
            )
        }
        return <TextLink>{content}</TextLink>
    }

    const renderActionCell = (entry: SuggestionData) => {
        if (entry.processed?.accepted) {
            return <StatusLabel status="accepted">Accepted</StatusLabel>
        }

        if (entry.processed?.rejected) {
            return <StatusLabel status="rejected">Rejected</StatusLabel>
        }

        const isProcessing = processingIds.has(entry.id)

        return (
            <ButtonGroup>
                <ActionButton actionType="accept" onClick={() => handleAccept(entry.id)} disabled={isProcessing}>
                    Accept
                </ActionButton>
                <ActionButton actionType="reject" onClick={() => handleReject(entry.id)} disabled={isProcessing}>
                    Reject
                </ActionButton>
            </ButtonGroup>
        )
    }

    return (
        <div>
            <table className={styles.table}>
                <thead>
                    <tr key="header">
                        {props.columns.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                        <th key="actions">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {isValid
                        ? elements.map((entry, i) => (
                              <tr key={`${entry.id}-${i}`}>
                                  {entry.columns.map((column, i) => (
                                      <td key={`cell-${entry.id}-${i}`}>{renderCell(column)}</td>
                                  ))}
                                  <td key={`actions-${entry.id}`}>{renderActionCell(entry)}</td>
                              </tr>
                          ))
                        : null}
                </tbody>
            </table>
            {!isValid && (
                <div className={styles.empty}>
                    {promiseInProgress ? <Spinner area={props.loadingArea} /> : 'No suggestions found!'}
                </div>
            )}
        </div>
    )
}

export default SuggestionTable
