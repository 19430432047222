import React, { useCallback, useState } from 'react'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import Stack from 'components/elements/Stack'
import Meta from 'components/modules/Head'
import SchoolCreateService from 'services/SchoolCreateService'
import { CountryCode } from 'interfaces/Country'
import SelectElement from 'components/inputs/SelectElement'
import { FilterFormGrid } from 'style'
import routes from 'lib/constants/routes'
import { useCountryOptions } from 'hooks/useCountryOptions'
import { AcademicTier } from 'interfaces/Know'
import { isValidLink } from 'utils/isValidHttpUrl'

const loadingAreas = {
    container: 'moderationKeywordsContainer',
    delete: 'moderationKeywordDelete',
    edit: 'moderationKeywordEdit',
}

const SchoolsSuggestions = () => {
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>('DE')
    const [academicTier, setAcademicTier] = useState<AcademicTier>(AcademicTier.School)

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                SchoolCreateService.list(page, selectedCountry, academicTier)
                    .then((data) => {
                        const redirectUuids = data.schools.map((s) => s.uuid)

                        return {
                            totalPages: 0,
                            totalElements: data.schools.length,
                            elements: data.schools.map((s, i) => ({
                                id: s.uuid,
                                columns: [
                                    s.uuid,
                                    `${s.name} ${s.academicTier ? `(${s.academicTier})` : ''}`,
                                    `${s.city}, ${s.streetWithNumber}`,
                                    s.userUuid,
                                    s.websiteUrl ? (
                                        <>
                                            {isValidLink(s.websiteUrl) ? (
                                                <a
                                                    href={
                                                        s.websiteUrl.startsWith('http')
                                                            ? s.websiteUrl
                                                            : `https://${s.websiteUrl}`
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {s.websiteUrl}
                                                </a>
                                            ) : (
                                                `${s.websiteUrl} (invalid URL)`
                                            )}
                                        </>
                                    ) : (
                                        'N/A'
                                    ),
                                ],
                                onClick: routes.schoolsSuggestionRoute(s.uuid, { redirectUuids }),
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting moderation keywords.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [selectedCountry, academicTier]
    )

    const countryTypeOptions = useCountryOptions()
    const updateCountryCode = (countryCode: CountryCode) => setSelectedCountry(countryCode)

    const academicTypeOptions = Object.values(AcademicTier).map((academicType) => ({
        label: academicType,
        value: academicType,
        data: academicType,
    }))

    const updateAcademicTier = (academicTier: AcademicTier) => setAcademicTier(academicTier)

    return (
        <section>
            <Meta title="School suggestions" />
            <Stack gutter={5}>
                <h2>School suggestions</h2>
                <FilterFormGrid gridAmount={2}>
                    <SelectElement label="Country code" noMargin options={countryTypeOptions} onUpdate={updateCountryCode} />
                    <SelectElement
                        label="Academic tier"
                        noMargin
                        options={academicTypeOptions}
                        onUpdate={updateAcademicTier}
                    />
                </FilterFormGrid>
                <RefactoredTable
                    columns={['#', 'Name', 'Location name', 'User uuid', 'Website URL']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                />
            </Stack>
        </section>
    )
}

export default SchoolsSuggestions
