import { Course } from 'interfaces/Course'

export const getCoursesPreview = (
    courses: Course[],
    fullWidth: boolean,
    defaultText: string,
    maxVisibleCourses = 2
): string => {
    if (courses.length === 0) {
        return defaultText
    }

    if (!fullWidth || courses.length <= maxVisibleCourses) {
        return courses.map((c) => c.name).join(', ')
    }

    const visibleCourses = courses
        .slice(0, maxVisibleCourses)
        .map((c) => c.name)
        .join(', ')
    const remaining = courses.length - maxVisibleCourses
    return `${visibleCourses} +${remaining}`
}
