import React from 'react'
import routes from 'lib/constants/routes'
import { ReactComponent as AdCustomerIcon } from 'assets/icons/navigation/ad_customer.svg'
import { ReactComponent as ChatMessagesIcon } from 'assets/icons/navigation/chat_messages.svg'
import { ReactComponent as CurriculumAnalysisIcon } from 'assets/icons/navigation/curriculum_analysis.svg'
import { ReactComponent as FeedbackDialogIcon } from 'assets/icons/navigation/feedback_dialog.svg'
import { ReactComponent as FinanceIcon } from 'assets/icons/navigation/finance.svg'
import { ReactComponent as GradeIcon } from 'assets/icons/navigation/grade.svg'
import { ReactComponent as KnowIcon } from 'assets/icons/navigation/know.svg'
import { ReactComponent as KnowTypesIcon } from 'assets/icons/navigation/know_types.svg'
import { ReactComponent as KnowsIcon } from 'assets/icons/navigation/knows.svg'
import { ReactComponent as ManualEmailsIcon } from 'assets/icons/navigation/manual_emails.svg'
import { ReactComponent as ManualNotificationsIcon } from 'assets/icons/navigation/manual_notifications.svg'
import { ReactComponent as PayoutRequestIcon } from 'assets/icons/navigation/payout_request.svg'
import { ReactComponent as RegionsIcon } from 'assets/icons/navigation/regions.svg'
import { ReactComponent as ReportsIcon } from 'assets/icons/navigation/reports.svg'
import { ReactComponent as SchoolTypesIcon } from 'assets/icons/navigation/school_types.svg'
import { ReactComponent as SubjectsIcon } from 'assets/icons/navigation/subjects.svg'
import { ReactComponent as TransactionsIcon } from 'assets/icons/navigation/transactions.svg'
import { ReactComponent as UserIcon } from 'assets/icons/navigation/user.svg'
import { ReactComponent as HomeIcon } from 'assets/icons/navigation/home.svg'
import { ReactComponent as CustomerSupportIcon } from 'assets/icons/navigation/customer_support.svg'
import { ReactComponent as CommunicationIcon } from 'assets/icons/navigation/communication.svg'
import { ReactComponent as ExperimentsIcon } from 'assets/icons/navigation/experiments.svg'
import { ReactComponent as EducationIcon } from 'assets/icons/navigation/education.svg'
import { ReactComponent as GlossaryIcon } from 'assets/icons/navigation/glossary.svg'
import { ReactComponent as KnowerBonusPaymentIcon } from 'assets/icons/navigation/knower_bonus_payment.svg'
import { ReactComponent as CompetitionIcon } from 'assets/icons/navigation/competition.svg'
import { ReactComponent as ModerationKeywordIcon } from 'assets/icons/navigation/moderation_keyword.svg'
import { ReactComponent as PublishIcon } from 'assets/icons/navigation/publish.svg'
import { ReactComponent as TaxonomyIcon } from 'assets/icons/navigation/taxonomy.svg'
import { ReactComponent as CareerPartnerIcon } from 'assets/icons/navigation/career_partner.svg'
import { ReactComponent as CampaignsIcon } from 'assets/icons/navigation/campaings.svg'
import { ReactComponent as SchoolSuggestionsIcon } from 'assets/icons/navigation/school_suggestions.svg'
import { ReactComponent as SchoolsIcon } from 'assets/icons/navigation/school.svg'
import { ReactComponent as CourseIcon } from 'assets/icons/navigation/course.svg'
import { ReactComponent as SchoolHolidaysIcon } from 'assets/icons/navigation/holiday.svg'
export const menuSidebarItems = [
    {
        title: 'Home',
        link: routes.dashboardRoute,
        icon: <HomeIcon />,
        hideOnDashboard: true,
    },

    {
        title: 'Customer Support',
        link: '',
        icon: <CustomerSupportIcon />,
        subItems: [
            {
                title: 'Users',
                link: routes.usersRoute,
                icon: <UserIcon />,
            },
            {
                title: 'Reports',
                link: routes.reportsRoute,
                icon: <ReportsIcon />,
            },
        ],
    },
    {
        title: 'Content',
        link: '',
        icon: <KnowsIcon />,
        subItems: [
            {
                title: 'Knows',
                link: routes.knowsRoute(),
                icon: <KnowIcon />,
            },
            {
                title: 'Reports',
                link: routes.reportsRoute,
                icon: <ReportsIcon />,
            },
            {
                title: 'Moderation Keywords',
                link: routes.moderationKeywordsRoute,
                icon: <ModerationKeywordIcon />,
            },
            {
                title: 'Curriculum Analysis',
                link: routes.curriculumAnalysisRoute,
                icon: <CurriculumAnalysisIcon />,
            },
            {
                title: 'Experiments',
                link: routes.experimentsRoute,
                icon: <ExperimentsIcon />,
            },
            {
                title: 'Taxonomy Updates',
                link: routes.taxonomyRoute,
                icon: <TaxonomyIcon />,
            },
            {
                title: 'Taxonomy Creation',
                link: routes.taxonomyImportRoute,
                icon: <PublishIcon />,
            },
            {
                title: 'Glossary',
                link: routes.seoSubjectsRoute,
                icon: <GlossaryIcon />,
            },
        ],
    },
    {
        title: 'Communication',
        link: '',
        icon: <CommunicationIcon />,
        subItems: [
            {
                title: 'Manual Emails',
                link: routes.manualEmailsRoute,
                icon: <ManualEmailsIcon />,
            },
            {
                title: 'Manual Chat Messages',
                link: routes.manualChatMessagesRoute,
                icon: <ChatMessagesIcon />,
            },
            {
                title: 'Manual Push Notifications',
                link: routes.manualPushNotificationsRoute,
                icon: <ManualNotificationsIcon />,
            },
            {
                title: 'Campaigns System',
                link: routes.campaingsRoute,
                icon: <CampaignsIcon />,
            },
            {
                title: 'Competitions',
                link: routes.competitionsRoute,
                icon: <CompetitionIcon />,
            },
            {
                title: 'Feedback Dialogs',
                link: routes.feedbackDialogsRoute,
                icon: <FeedbackDialogIcon />,
            },
            {
                title: 'Courses',
                link: routes.courseFundraiseRoute,
                icon: <CourseIcon />,
            },
            {
                title: 'School Holidays',
                link: routes.schoolHolidaysRoute,
                icon: <SchoolHolidaysIcon />,
            },
            {
                title: 'Bulk School Holiday Upload',
                link: routes.bulkSchoolHolidayUploadRoute,
                icon: <SchoolHolidaysIcon />,
            },
            {
                title: 'Majors Suggestions',
                link: routes.majorsSuggestionsRoute,
                icon: <SchoolSuggestionsIcon />,
            },
        ],
    },
    {
        title: 'Finance',
        link: '',
        icon: <FinanceIcon />,
        subItems: [
            {
                title: 'Payout Requests',
                link: routes.payoutRequestsRoute,
                icon: <PayoutRequestIcon />,
            },
            {
                title: 'Ad System',
                link: routes.adsSystemRoute,
                icon: <AdCustomerIcon />,
            },
            {
                title: 'Career Partners',
                link: routes.careerPartnersRoute,
                icon: <CareerPartnerIcon />,
            },
            {
                title: 'Transactions',
                link: routes.transactionsRoute,
                icon: <TransactionsIcon />,
            },
            {
                title: 'Knower Bonus Payments',
                link: routes.knowerBonusPayments,
                icon: <KnowerBonusPaymentIcon />,
            },
        ],
    },
    {
        title: 'Education system',
        link: '',
        icon: <EducationIcon />,
        subItems: [
            {
                title: 'Know Types',
                link: routes.knowTypesRoute,
                icon: <KnowTypesIcon />,
            },
            {
                title: 'Subjects',
                link: routes.subjectsRoute,
                icon: <SubjectsIcon />,
            },
            {
                title: 'Bulk Subject Upload',
                link: routes.bulkSubjectUploadRoute,
                icon: <SubjectsIcon />,
            },
            {
                title: 'Grades',
                link: routes.gradesRoute,
                icon: <GradeIcon />,
            },

            {
                title: 'School Types',
                link: routes.schoolTypesRoute,
                icon: <SchoolTypesIcon />,
            },
            {
                title: 'Regions',
                link: routes.regionsRoute,
                icon: <RegionsIcon />,
            },
            {
                title: 'School Suggestions',
                link: routes.schoolsSuggestionsRoute,
                icon: <SchoolSuggestionsIcon />,
            },
            {
                title: 'Schools',
                link: routes.schoolsRoute,
                icon: <SchoolsIcon />,
            },
        ],
    },
]

export const filterMenuItemsBySeachQuery = (query: string) =>
    menuSidebarItems.filter(
        (menuItems) =>
            menuItems?.subItems?.length &&
            menuItems.subItems.some((subItem) => subItem.title.toLowerCase().startsWith(query.toLowerCase()))
    )

export const filterMenuSubItemsBySearchQuery = (subItems: typeof menuSidebarItems[number]['subItems'], query: string) =>
    subItems?.filter((subItem) => subItem.title.toLowerCase().startsWith(query.toLowerCase()))
