import React from 'react'
import { Label, InfoWrapper, MetaBox } from '../Know.styles'
import Stack from 'components/elements/Stack'
import { AcademicTier, Know, Know as KnowInterface } from 'interfaces/Know'
import PlagiarismIndicator from 'pages/Knows/PlagiarismIndicator'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import CopyIcon from 'components/elements/CopyIcon'
import { Link } from 'react-router-dom'
import routes from 'lib/constants/routes'
import { formatDate } from 'utils'
import { formatSubjectLabel } from 'lib/features/subjects'
import { useKnowInformationSection } from './useKnowInformationSection'
import KnowMetaForm from 'components/features/knows/KnowMetaForm'
import Modal from 'components/modules/Modal'
import styled from 'styled-components'
import KnowVisualSimilarImages from 'components/features/knows/KnowVisualSimilarImages'

interface InformationSectionProps {
    know: KnowInterface
    onSuccessSubmitKnowEdit: (know: KnowInterface) => void
    toggleKnowEditMode: () => void
    showKnowEditMode: boolean
    isKnowInReview: boolean
    updateKnow: (know: Know) => void
}

interface InfoProps {
    title: string
    children?: React.ReactNode
    isHidden?: boolean
}

const BadgesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`

const Info = ({ title, children, isHidden = false }: InfoProps) => {
    if (isHidden) return <></>
    return (
        <InfoWrapper>
            <strong>{title}:</strong>
            {children}
        </InfoWrapper>
    )
}

export const KnowInformationSection = ({
    know,
    onSuccessSubmitKnowEdit,
    toggleKnowEditMode,
    isKnowInReview,
    showKnowEditMode,
    updateKnow,
}: InformationSectionProps) => {
    const { getKnowerLabels, reviewKnowsCount, publicKnowsCount, handleSubmitKnowEdit, metaFormRef } =
        useKnowInformationSection({
            know,
            onSuccessSubmitKnowEdit,
        })

    const isUniversityKnow = know.academicTier === AcademicTier.University

    return (
        <>
            <MetaBox>
                <Stack>
                    <h3>Information</h3>
                    <EditIcon onClick={toggleKnowEditMode} className="edit" />
                    <Stack>
                        <hr />
                        <BadgesWrapper>
                            {know.advertisementUrl ? <Label isGreen>Is Sponsored</Label> : null}
                            <Label isGreen>{know.knowFormat}</Label>
                            <Label isGreen>{know.status}</Label>
                            {know.isUndeletable ? <Label>Undeleteable</Label> : null}
                            {know.isGenerated ? <Label>Generated by AI</Label> : null}
                        </BadgesWrapper>
                        <Info title="Title">{know.title}</Info>
                        <Info title="Description">{know.description}</Info>
                        <Info title="UUID" isHidden={isKnowInReview}>
                            {know.uuid}
                            <CopyIcon text={know.uuid} />
                        </Info>
                        <Info title="Type">{know?.knowType?.name || '(NOT SPECIFIED)'}</Info>
                        {isUniversityKnow ? (
                            <>
                                <Info title="Major">{know.major?.name}</Info>
                                <Info title="Course">{know.courses?.map((course) => course.name).join(', ')}</Info>
                            </>
                        ) : (
                            <>
                                <Info title="Grade">{know.grades?.map((grade) => grade.shortName).join(', ')}</Info>
                                <Info title="School Types" isHidden={isKnowInReview}>
                                    {know.schoolTypes?.map((school) => school.name).join(', ')}
                                </Info>
                                <Info title="Subject">
                                    {know.subjects?.map((subject) => formatSubjectLabel(subject)).join(', ')}
                                </Info>
                                <Info title="Exam boards" isHidden={!!know.examBoards && isKnowInReview}>
                                    {know.examBoards.map((exam) => exam.longName).join(', ')}
                                </Info>
                            </>
                        )}
                        <Info title="Created on">{formatDate(know.createdOn)}</Info>
                        <Info title="Likes" isHidden={isKnowInReview}>
                            {know.likes}
                        </Info>
                        <Info title="Views" isHidden={isKnowInReview}>
                            {know.views}
                        </Info>
                        <Info title="Content Language" isHidden={isKnowInReview}>
                            {know.contentLanguage?.englishName} ({know.contentLanguage.code})
                        </Info>
                        <PlagiarismIndicator knowUuid={know.uuid} />
                        <KnowVisualSimilarImages
                            knowUuid={know.uuid}
                            isKnowInReview={isKnowInReview}
                            showEditKnowModal={showKnowEditMode}
                            updateKnow={updateKnow}
                        />
                        <hr />
                        <Info title="Knower">
                            <Link to={routes.knowerRoute(know.knower.uuid)}>@{know.knower.user.username}</Link>
                            <CopyIcon text={know.knower.uuid} />
                        </Info>
                        <Info title="Knower Country" isHidden={isKnowInReview}>
                            {know.knowerCountry?.englishName} ({know.knowerCountry.code})
                        </Info>
                        <Info title="Labels">
                            {getKnowerLabels().map((label, index) => (
                                <Label key={index} isGreen={label.isGreen}>
                                    {label.tag}
                                </Label>
                            ))}
                        </Info>
                        <Info title="Knows in review">{reviewKnowsCount}</Info>
                        <Info title="Public knows">{publicKnowsCount}</Info>
                    </Stack>
                </Stack>
            </MetaBox>
            <Modal show={showKnowEditMode} title="Edit Know" onClose={toggleKnowEditMode}>
                <KnowMetaForm formRef={metaFormRef} know={know} handleSubmit={handleSubmitKnowEdit} />
            </Modal>
        </>
    )
}
