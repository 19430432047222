import React from 'react'
import { trackPromise } from 'react-promise-tracker'
import SuggestionTable from 'components/modules/SuggestionTable'
import MajorService from 'services/MajorService'
import { SuggestionData } from 'components/modules/SuggestionTable'
import { Major } from 'interfaces/Major'
import Meta from 'components/modules/Head'
import Stack from 'components/elements/Stack'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(Link)`
    all: unset;
    max-width: 350px !important;
    display: block;
`

const SuggestedMajorsPage: React.FC = () => {
    const fetchSuggestions = async (page: number) => {
        const response = await trackPromise(MajorService.listSuggestions(page), 'suggestions-loading')

        const suggestions: SuggestionData[] = response.content.map((major: Major) => ({
            id: major.uuid,
            columns: [
                major.name,
                major.schoolUuid ? (
                    <StyledLink to={`/schools/${major.schoolUuid.uuid}`} target="_blank">
                        {major.schoolUuid.name || 'N/A'}
                    </StyledLink>
                ) : (
                    'N/A'
                ),
                major.degree || 'N/A',
            ],
        }))

        return {
            content: suggestions,
            totalPages: response.totalPages,
            totalElements: response.totalElements,
        }
    }

    const handleAccept = async (id: string) => {
        await trackPromise(MajorService.acceptSuggestion(id), 'suggestion-action')
    }

    const handleReject = async (id: string) => {
        await trackPromise(MajorService.deleteSuggestion(id), 'suggestion-action')
    }

    return (
        <section>
            <Meta title="Majors Suggestions" />
            <Stack gutter={15}>
                <h2>Majors Suggestions</h2>
                <SuggestionTable
                    columns={['Major Name', 'School', 'Degree']}
                    fetchData={fetchSuggestions}
                    loadingArea="suggestions-loading"
                    onAcceptClick={handleAccept}
                    onRejectClick={handleReject}
                />
            </Stack>
        </section>
    )
}

export default SuggestedMajorsPage
