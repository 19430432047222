import React, { useCallback, useEffect, useRef, useState } from 'react'
import Table, { TableData } from 'components/modules/Table'
import SubjectService from 'pages/Subjects/SubjectService'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import { ButtonLink } from 'components/elements/Button'
import { CountryCode } from 'interfaces/Country'
import Stack from 'components/elements/Stack'
import { ControlWrapper, FilterFormGrid } from 'style'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import routes from 'lib/constants/routes'
import { getColorIndicator } from 'utils/subjects'
import Meta from 'components/modules/Head'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import FormSelect from 'components/inputs/FormSelect'
import { badRequestFormErrors, getUrlParam } from 'utils'
import EditRecordInline from 'components/elements/EditRecordInline'
import { toast } from 'react-toastify'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useCountryOptions } from 'hooks/useCountryOptions'

const loadingAreas = {
    container: 'subjectsContainer',
}

const Subjects = () => {
    const [data, setTableData] = useState<TableData[]>([])
    const [country, setCountry] = useState<CountryCode>('DE')
    const formRef = useRef<FormHandles>(null)

    const countryOptions = useCountryOptions({ includeNoFilter: true })

    const fetchData = useCallback(() => {
        trackPromise(
            SubjectService.list(country)
                .then((subjects) => {
                    const tableData = subjects
                        .sort((s1, s2) => s1.id - s2.id)
                        .map((s) => ({
                            id: s.id,
                            columns: [
                                s.id,
                                s.name,
                                s.color ? getColorIndicator(s.color) : 'No color selected',
                                <img src={s.iconUrl} alt="Icon" key={s.id} />,
                                <EditRecordInline
                                    itemUuid={s.id.toString()}
                                    key={`edit-subject-${s.name}`}
                                    name="sortId"
                                    value={s.sortId}
                                    onSubmit={updateSubjectPartially}
                                >
                                    {s.sortId}
                                </EditRecordInline>,
                                <ButtonLink key={`go-to-subject-${s.name}`} to={routes.subjectRoute(s.id)} fullWidth={false}>
                                    Go to subject
                                </ButtonLink>,
                            ],
                        }))
                    setTableData(tableData)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting subjects.')
                }),
            loadingAreas.container
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country])

    const updateSubjectPartially = useCallback(
        (itemUuid: string, recordName: string, recordValue: string | number, hideForm: () => void) => {
            if (!recordValue) return

            const body = {
                [`${recordName}`]: !isNaN(+recordValue) ? +recordValue : recordValue,
            }

            SubjectService.updatePartially(+itemUuid, body)
                .then(() => {
                    fetchData()
                    hideForm()
                    toast.success('Subject data has been saved.')
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown(`An error occurred while updating an asset.`)
                    }
                })
        },
        [fetchData]
    )

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        const queryParam = getUrlParam('countryCode')

        if (!queryParam) return
        setCountry(queryParam as CountryCode)
        formRef.current?.setFieldValue('country', {
            label: queryParam,
            identifier: queryParam,
            data: queryParam as CountryCode,
        })
    }, [])

    const handleUpdateCountry = (value: CountryCode) => {
        setCountry(value)
    }

    return (
        <section>
            <Meta title="Subjects" />
            <Stack gutter={10}>
                <h2>Subjects</h2>
                <FilterFormGrid gridAmount={2}>
                    <ControlWrapper>
                        <ButtonLink to={routes.subjectsCreateRoute} fullWidth={false} icon={<AddIcon />}>
                            Add Subject
                        </ButtonLink>
                    </ControlWrapper>
                    <Form ref={formRef} onSubmit={() => null}>
                        <FormSelect
                            name="country"
                            options={countryOptions}
                            placeholder="Country"
                            onUpdate={(val) => handleUpdateCountry(val as CountryCode)}
                        />
                    </Form>
                </FilterFormGrid>
                <Table
                    columns={['#', 'Name', 'Color', 'Subject Icon', 'Sorting Number ✎', 'Actions']}
                    data={data}
                    page={0}
                    totalPages={1}
                    totalElements={1}
                    setPage={() => null}
                    area={loadingAreas.container}
                />
            </Stack>
        </section>
    )
}

export default Subjects
