/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useRef, useState } from 'react'
import { Body1, ButtonText, Headline5 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import Button from 'components/elements/Button'
import InformationCard from 'components/features/InformationCard'
import RecordForm from 'components/inputs/RecordForm'
import { ExperimentVariant, ExperimentVariantWithoutIdentifier } from 'interfaces/Experiment'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { FormSelect, Input, SelectOption } from 'components/inputs/Input'
import Stack from 'components/elements/Stack'
import { getVariantDisplayName, validateAndReturnCorrectRecordType } from 'lib/features/experiments'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import CopyIcon from 'components/elements/CopyIcon'

const VariantWrapper = styled.div`
    padding: 15px;
    margin-bottom: 10px;
    border-radius: ${theme.borderRadius.card};
    background-color: ${theme.colors.darkViolet};
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
    }
`

const EditContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const EditButton = styled(EditIcon)`
    cursor: pointer;
    width: 25px;
    height: 25px;
`

const DeleteButton = styled(DeleteIcon)`
    cursor: pointer;
    width: 25px;
    height: 25px;
`

const VariantActions = styled.div`
    display: flex;
    gap: 10px;
`

const loadingAreas = {
    form: 'experimentVariantForm',
    save: 'saveExperimentVariant',
}

type EditFormData = {
    forceAssignForUserUUIDs: string
    [key: string]: string
} & { cohortOverride: SelectOption<string | null> }

interface Props {
    variants: ExperimentVariantWithoutIdentifier[] | null
    disableAddingNewVariants?: boolean
    parentFormRef?: React.RefObject<FormHandles>
    changeVariants?: (variants: ExperimentVariantWithoutIdentifier[]) => void
    cohortsOptions?: { label: string; identifier: string; data: string | null }[]
    selectedGroup?: string | null
}

const VariantForm = ({
    variants,
    disableAddingNewVariants,
    parentFormRef,
    changeVariants,
    cohortsOptions,
    selectedGroup,
}: Props) => {
    const [showEditForm, toggleEditForm] = useToggleState(false)
    const [records, setRecords] = useState<Record<string, string | number | boolean | string[] | number[]>>()
    const [selectedVariant, setSelectedVariant] = useState<{ variant: ExperimentVariantWithoutIdentifier; id: number }>()
    const [initialExperimentAppConfigFlags, setInitialExperimentAppConfigFlags] =
        useState<ExperimentVariant['appConfigFlags']>()
    const [initialExperimentBackendToggles, setInitialExperimentBackendToggles] =
        useState<ExperimentVariant['backendToggles']>()
    const editFormRef = useRef<FormHandles>(null)

    const addRecord = (title: string, value: string) => {
        setRecords((prevRecords) => {
            const tempRecords = prevRecords ?? {}
            tempRecords[`${title.replaceAll(' ', '')}`] = validateAndReturnCorrectRecordType(value)
            return tempRecords
        })
    }

    const handleToggleEditButton = (variant: Omit<ExperimentVariant, 'identifier'>, id: number) => {
        setSelectedVariant({ variant, id })
        toggleEditForm()
    }

    const removeVariants = (index: number) => {
        if (!variants || !changeVariants) return

        changeVariants(variants.filter((_, i) => i !== index))
    }

    const handleEditRecord = () => {
        if (!changeVariants) return
        const data = editFormRef.current?.getData() as EditFormData
        const { cohortOverride, forceAssignForUserUUIDs, ...restRecords } = data

        let selectedUsersUuids = null

        if (forceAssignForUserUUIDs?.length) {
            selectedUsersUuids = forceAssignForUserUUIDs
                .split(',')
                .map((x) => x.trim())
                .filter((x) => x)
        }

        let updatedAppConfigRecords: ExperimentVariant['appConfigFlags'] = {}
        let updatedBackendTogglesRecords: ExperimentVariant['backendToggles'] = {}

        Object.entries(restRecords).forEach(([key, value], i, arr) => {
            if (i % 2 === 0) {
                if (key.startsWith('app')) {
                    updatedAppConfigRecords = {
                        [`${value.replaceAll(' ', '')}`]: validateAndReturnCorrectRecordType(arr[i + 1][1]),
                        ...updatedAppConfigRecords,
                    }
                } else {
                    updatedBackendTogglesRecords = {
                        [`${value.replaceAll(' ', '')}`]: validateAndReturnCorrectRecordType(arr[i + 1][1]),
                        ...updatedBackendTogglesRecords,
                    }
                }
            }
        })

        let tempVariants = variants ?? []

        if (selectedVariant) {
            const {
                id,
                variant: { cohortOverride: selectedCohortOverride, forceAssignForUserUUIDs: selectedForcedUserUuids },
            } = selectedVariant

            tempVariants[id] = {
                appConfigFlags: updatedAppConfigRecords,
                cohortOverride: cohortOverride.data ?? selectedCohortOverride,
                forceAssignForUserUUIDs: selectedUsersUuids ?? selectedForcedUserUuids,
                backendToggles: updatedBackendTogglesRecords,
            } as Omit<ExperimentVariant, 'identifier'>
        } else {
            tempVariants = [
                ...tempVariants,
                {
                    appConfigFlags: updatedAppConfigRecords,
                    cohortOverride: cohortOverride.data,
                    forceAssignForUserUUIDs: selectedUsersUuids,
                    backendToggles: updatedBackendTogglesRecords,
                },
            ]
        }

        tempVariants && changeVariants(tempVariants)
        setSelectedVariant(undefined)
        toggleEditForm()
    }

    useEffect(() => {
        if (!selectedVariant?.variant?.appConfigFlags) return

        let dataRecords = {}
        Object.entries(selectedVariant.variant.appConfigFlags).forEach(([title, value], i) => {
            dataRecords = {
                [`${`app-record-title-${i}-${selectedVariant.id}`}`]: title,
                [`${`app-record-value-${i}-${selectedVariant.id}`}`]: value,
                ...dataRecords,
            }
        })
        setInitialExperimentAppConfigFlags(dataRecords)
    }, [selectedVariant])

    useEffect(() => {
        if (!selectedVariant?.variant?.backendToggles) return

        let dataRecords = {}
        Object.entries(selectedVariant.variant.backendToggles).forEach(([title, value], i) => {
            dataRecords = {
                [`${`backend-record-title-${i}-${selectedVariant.id}`}`]: title,
                [`${`backend-record-value-${i}-${selectedVariant.id}`}`]: value,
                ...dataRecords,
            }
        })
        setInitialExperimentBackendToggles(dataRecords)
    }, [selectedVariant])

    const initialData = {
        identifier: '',
        ...initialExperimentAppConfigFlags,
        ...initialExperimentBackendToggles,
        forceAssignForUserUUIDs: selectedVariant?.variant.forceAssignForUserUUIDs?.join(', ') ?? null,
        cohortOverride:
            selectedVariant?.variant.cohortOverride && cohortsOptions
                ? cohortsOptions.find((cohort) => cohort.data === selectedVariant.variant.cohortOverride)
                : {
                      identifier: 'everyone',
                      data: null,
                  },
    }

    const closeEditForm = () => {
        toggleEditForm()
        setSelectedVariant(undefined)
    }

    return (
        <VariantWrapper>
            <div className="title">
                <Headline5 color={theme.colors.white} fontWeigth={500} marginBottom={10}>
                    Variants
                </Headline5>
                {!disableAddingNewVariants ? (
                    <Button fullWidth={false} noMargin onClick={toggleEditForm} icon={<AddIcon />} type="button">
                        <ButtonText color={theme.colors.white}>Add Variant</ButtonText>
                    </Button>
                ) : null}
            </div>
            <Stack gutter={10}>
                {selectedGroup?.length ? (
                    <Body1
                        color={theme.colors.knowunityBlue}
                        fontWeigth={500}
                        style={{
                            padding: '15px',
                            borderRadius: theme.borderRadius.card,
                            backgroundColor: theme.colors.darkViolet,
                            border: `1px solid ${theme.colors.knowunityBlue}`,
                        }}
                    >
                        There is no need to create a baseline variant. A baseline which is shared with other experiments will
                        be created automatically.
                    </Body1>
                ) : null}
                {variants?.length
                    ? variants.map((variant, i) => (
                          <InformationCard
                              key={i}
                              title={
                                  <EditContainer>
                                      <div>
                                          {getVariantDisplayName(
                                              disableAddingNewVariants
                                                  ? (variant as ExperimentVariant)?.identifier ?? null
                                                  : null,
                                              i,
                                              selectedGroup ?? null
                                          )}
                                          {(variant as ExperimentVariant)?.identifier ? (
                                              <CopyIcon text={(variant as ExperimentVariant).identifier} />
                                          ) : null}
                                      </div>

                                      {!disableAddingNewVariants ? (
                                          <VariantActions>
                                              <EditButton onClick={() => handleToggleEditButton(variant, i)} />
                                              <DeleteButton onClick={() => removeVariants(i)} />
                                          </VariantActions>
                                      ) : null}
                                  </EditContainer>
                              }
                          >
                              <Stack gutter={10}>
                                  {variant.cohortOverride ? (
                                      <Body1
                                          color={theme.colors.white}
                                          fontWeigth={500}
                                      >{`Overriden cohort: ${variant.cohortOverride}`}</Body1>
                                  ) : null}
                                  {variant.forceAssignForUserUUIDs ? (
                                      <Body1
                                          color={theme.colors.white}
                                          fontWeigth={500}
                                      >{`Assigned to: ${variant.forceAssignForUserUUIDs.join(', ')}`}</Body1>
                                  ) : null}
                                  <RecordForm
                                      defaultRecords={variant.appConfigFlags}
                                      addRecord={addRecord}
                                      disableAddingRecords
                                      type="CONFIG_FLAG"
                                  />
                                  <RecordForm
                                      defaultRecords={variant.backendToggles}
                                      addRecord={addRecord}
                                      disableAddingRecords
                                      type="BACKEND_TOGGLE"
                                  />
                              </Stack>
                          </InformationCard>
                      ))
                    : 'No variants added'}
            </Stack>
            <Modal
                show={showEditForm}
                onClose={closeEditForm}
                title={`${selectedVariant ? 'Edit' : 'Add'} ${getVariantDisplayName(
                    (selectedVariant?.variant as ExperimentVariant)?.identifier ?? null,
                    variants?.length ?? 0,
                    selectedGroup ?? null
                )}`}
            >
                <Form ref={editFormRef} onSubmit={() => {}} initialData={initialData}>
                    <Input name="forceAssignForUserUUIDs" placeholder="Assign to selected user uuids" />
                    {cohortsOptions && (
                        <FormSelect name="cohortOverride" options={cohortsOptions} placeholder="Override cohort" />
                    )}
                    <RecordForm
                        type="CONFIG_FLAG"
                        addRecord={addRecord}
                        selectedVariantId={selectedVariant?.id}
                        defaultRecords={selectedVariant ? selectedVariant.variant.appConfigFlags : records}
                    />
                    <RecordForm
                        type="BACKEND_TOGGLE"
                        addRecord={addRecord}
                        selectedVariantId={selectedVariant?.id}
                        defaultRecords={selectedVariant ? selectedVariant.variant.backendToggles : records}
                    />
                    <Button
                        fullWidth
                        icon={<SaveIcon />}
                        loadingArea={loadingAreas.save}
                        hoverColor={theme.colors.deepSeaBlue}
                        onClick={handleEditRecord}
                        type="button"
                    >
                        Update
                    </Button>
                </Form>
            </Modal>
        </VariantWrapper>
    )
}

export default VariantForm
