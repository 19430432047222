import React, { useState } from 'react'
import { Know, KnowDocument as KnowContentInterface } from 'interfaces/Know'
import Tabs from 'components/modules/Tabs'
import KnowDocumentEditor from './KnowDocumentEditor'
import { useToggleState } from 'utils/hooks/useToggleState'
import { ButtonText } from 'components/elements/Text'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import theme from 'lib/constants/theme'
import Button from 'components/elements/Button'
import Stack from 'components/elements/Stack'

interface Props {
    know: Know
    refreshData: () => void
}

const KnowContent = ({ know, refreshData }: Props) => {
    const [showPDFEditor, togglePDFEditor] = useToggleState(false)
    const [selectedDocument, setSelectedDocument] = useState<KnowContentInterface>()

    if (know.documents.length === 0) {
        return <p>The know doesn&apos;t have any content.</p>
    }

    const showContent = (content: KnowContentInterface) => {
        setSelectedDocument(content)
        togglePDFEditor()
    }

    const onClosePDFEditor = () => {
        refreshData()
        togglePDFEditor()
    }
    return (
        <>
            {know.documents?.length > 1 ? (
                <Tabs deactivateUrlParam>
                    {know.documents.map((content, index) => (
                        <div data-label={`${content.title}-${index}`} key={content.uuid}>
                            <Stack gutter={10}>
                                <object data={content.contentUrl} type="application/pdf" width="100%" height="700px">
                                    <p>Unable to show pdf.</p>
                                </object>
                                <Button
                                    fullWidth
                                    noMargin
                                    icon={<EditIcon />}
                                    color={theme.colors.knowunityBlueDark}
                                    height={35}
                                    hoverColor={theme.colors.knowunityBlue}
                                    onClick={() => showContent(content)}
                                >
                                    <ButtonText color={theme.colors.white}>Edit this pdf</ButtonText>
                                </Button>
                            </Stack>
                        </div>
                    ))}
                </Tabs>
            ) : (
                <Stack gutter={10}>
                    <object data={know.documents[0].contentUrl} type="application/pdf" width="100%" height="700px">
                        <p>Unable to show pdf.</p>
                    </object>
                    <Button
                        fullWidth
                        noMargin
                        icon={<EditIcon />}
                        color={theme.colors.knowunityBlueDark}
                        height={35}
                        hoverColor={theme.colors.knowunityBlue}
                        onClick={() => showContent(know.documents[0])}
                    >
                        <ButtonText color={theme.colors.white}>Edit this pdf</ButtonText>
                    </Button>
                </Stack>
            )}
            {selectedDocument ? (
                <KnowDocumentEditor show={showPDFEditor} onClose={onClosePDFEditor} content={selectedDocument} know={know} />
            ) : null}
        </>
    )
}

export default KnowContent
