import { PayoutRequest } from 'interfaces/PayoutRequest'
import { PayoutBulkData } from 'pages/PayoutRequests/PayoutRequests'
import { formatDate } from 'utils'

export const returnPayoutStatus = (payout: PayoutRequest) =>
    payout.isOpen ? 'Open' : `${payout.status === 'REFUSED' ? 'Refused' : 'Closed'} on: ${formatDate(payout.closedOn!)}`

export const getPayoutCSVHeaders = (o?: { useSortCode?: boolean; useClabe?: boolean; usePix?: boolean }) => {
    let headers = [
        'name',
        'recipientEmail',
        'paymentReference',
        'receiverType',
        'amountCurrency',
        'amount',
        'sourceCurrency',
        'targetCurrency',
    ]
    if (o?.useSortCode) {
        headers = [...headers, 'sortCode', 'accountNumber']
    } else if (o?.useClabe) {
        headers = [...headers, 'clabe', 'details.identificationNumber']
    } else if (o?.usePix) {
        headers = [...headers, 'bankCode', 'branchCode', 'accountNumber', 'accountType', 'details.cpf']
    } else {
        headers = [...headers, 'IBAN']
    }
    return headers
}

export const generateCSVRowFromPayout = (
    payout: PayoutBulkData,
    o?: { useSortCode?: boolean; useClabe?: boolean; usePix?: boolean }
) =>
    `${payout.name},${payout.recipientEmail},${payout.invoiceId},PERSON,target,${(payout.amount / 100).toFixed(2)},EUR,${
        payout.currency
    },${
        o?.useSortCode
            ? `${payout.sortCode},${payout.accountNumber}`
            : o?.useClabe
            ? `${payout.accountNumber},`
            : o?.usePix
            ? `${payout.codeBank},${payout.branchCode},${payout.accountNumber},${
                  payout.accountType === 'Checking' ? 'CHECKING' : 'SAVINGS'
              },${payout.taxNumber}`
            : payout.iban
    }\n`

export const sevDeskPayoutUrl = (sevDeskVoucherId: string) => `https://my.sevdesk.de/#/ex/detail/id/${sevDeskVoucherId}`
