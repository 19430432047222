import { Knower } from './Knower'
import { Grade } from './Grade'
import { Subject } from './Subject'
import { SchoolType } from './SchoolType'
import { LanguageInterface } from './Language'
import { Country } from './Country'
import { ExamBoard } from './ExamBoard'
import { Course } from './Course'
import { Major } from './Major'
import { School } from './School'
export interface Know {
    uuid: string
    knower: Knower
    title: string
    description: string
    status: KnowStatus
    grades: Grade[]
    subjects: Subject[]
    createdOn: string
    inLibrary: boolean
    liked: boolean
    viewedToday: boolean
    knowType: KnowType
    likes: number
    views: number | null
    schoolTypes: SchoolType[]
    documents: KnowDocument[]
    contentLanguage: LanguageInterface
    knowerCountry: Country
    internalRating: number | null
    knowFormat: KnowFormat
    thumbnailLargeUrl: string
    examBoards: ExamBoard[]
    publishedOn: string | null
    advertisementUrl: string | null
    isUndeletable: boolean
    isGenerated: boolean
    audioFilePath: string | null
    knowDocumentPages: KnowDocumentPage[]
    academicTier: AcademicTier
    courses: Course[] | null
    universityAuthoredDate: string | null
    major: Major | null
    school: School | null
}

export interface KnowCreateData {
    title: string
    description: string
    knowType: KnowType
    grades: Grade[]
    subjects: Subject[]
    schoolTypes: SchoolType[]
    examBoardUuids: string[]
    universityUuid?: string | null
    majorUuid?: string | null
    courseUuids?: string[] | null
    universityAuthoredDate?: string | null
    schoolUuid?: string | null
}

export enum AcademicTier {
    School = 'SCHOOL',
    University = 'UNIVERSITY',
}

export interface KnowDocumentPage {
    imageUrl: string
    knowDocumentUuid: string
    knowUuid: string
    pageNumber: number
    uuid: string
}

export enum ContentTypes {
    pdf = 'PDF',
    image = 'IMAGE',
    video = 'VIDEO',
}

export enum KnowStatus {
    InReview = 'IN_REVIEW',
    Draft = 'DRAFT',
    InExtendedReview = 'IN_EXTENDED_REVIEW',
    Public = 'PUBLIC',
    Rejected = 'REJECTED',
    PermanentlyRejected = 'PERMANENTLY_REJECTED',
    RejectionAppealed = 'REJECTION_APPEALED',
}

export interface KnowType {
    id: number
    name: string
    languageId: number
}

export type UpdateKnowType = {
    name: string
    languageId: number
}

export interface KnowDocument {
    knowDocumentPages: boolean
    uuid: string
    title: string
    contentType: ContentTypes
    orderNumber: number
    previewUrl: string | null
    contentUrl: string
}

export enum KnowFormat {
    PDF = 'PDF',
    Audio = 'AUDIO',
    Flashcards = 'FLASHCARDS',
}

export type KnowPlagiarismCandidate = {
    score: number
    know: Know
}
