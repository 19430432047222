export enum ErrorTypes {
    Authorization = 'AuthorizationError',
    FormValidation = 'FormValidationError',
    NotFound = 'NotFoundError',
    InvalidCredentials = 'InvalidCredentialsError',
    NoErrorType = 'NoErrorType',
    InvalidMIMEType = 'InvalidMIMETypeError',
    Requires2FA = 'Requires2FAError',
    Invalid2FAToken = 'Invalid2FATokenError',
    EmailInUse = 'EmailInUseError',
    Duplicate = 'DuplicateError',
    KnowIsUndeletable = 'KnowIsUndeletableError',
    BadRequest = 'BadRequestError',
    Forbidden = 'ForbiddenError',
}
