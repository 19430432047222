import ApiService from 'services/ApiService'
import { Experiment, ExperimentCohort, ExperimentSquad, ExperimentStatuses, UpdateExperiment } from 'interfaces/Experiment'

const ExperimentsService = {
    list(page: number, status?: ExperimentStatuses, search?: string | null) {
        return ApiService.get(
            `/experiments?page=${page}${status !== ExperimentStatuses.All ? `&status=${status}` : ''}${
                search ? `&search=${search}` : ''
            }`
        ).then((response) => {
            return {
                experiments: response.data.content as Experiment[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    get(uuid: string) {
        return ApiService.get(`/experiments/${uuid}`).then((response) => {
            return response.data as Experiment
        })
    },

    create(experiment: UpdateExperiment) {
        return ApiService.post('/experiments', experiment).then((response) => {
            return response.data as Experiment
        })
    },

    update(uuid: string, experiment: Partial<UpdateExperiment>) {
        return ApiService.patch(`/experiments/${uuid}`, experiment).then((response) => {
            return response.data as Experiment
        })
    },

    getCohorts() {
        return ApiService.get(`/experiments/cohorts`).then((response) => {
            return response.data.content as ExperimentCohort[]
        })
    },

    approve(uuid: string) {
        return ApiService.post(`/experiments/${uuid}/approve`).then((response) => {
            return response.data as Experiment
        })
    },

    start(uuid: string, startOn: string, endOn: string) {
        return ApiService.patch(`/experiments/${uuid}`, { startOn, endOn }).then((response) => {
            return response.data as Experiment
        })
    },

    stop(uuid: string) {
        return ApiService.patch(`/experiments/${uuid}`, { endOn: new Date().toISOString() }).then((response) => {
            return response.data as Experiment
        })
    },

    pause(uuid: string) {
        return ApiService.patch(`/experiments/${uuid}`, { isPaused: true }).then((response) => {
            return response.data as Experiment
        })
    },

    resume(uuid: string) {
        return ApiService.patch(`/experiments/${uuid}`, { isPaused: false }).then((response) => {
            return response.data as Experiment
        })
    },

    updateEndDate(uuid: string, endOn: string) {
        return ApiService.patch(`/experiments/${uuid}`, { endOn }).then((response) => {
            return response.data as Experiment
        })
    },

    getSquads() {
        return ApiService.get(`/experiments/squads`).then((response) => {
            return response.data.content as ExperimentSquad[]
        })
    },
}

export default ExperimentsService
