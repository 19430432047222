import React, { useCallback, useEffect, useState } from 'react'
import Table, { TableData } from 'components/modules/Table'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import Stack from 'components/elements/Stack'
import routes from 'lib/constants/routes'
import { SchoolType, SchoolTypeOption } from 'interfaces/SchoolType'
import { Region, RegionOption } from 'interfaces/Region'
import { CountryCode } from 'interfaces/Country'
import { fetchGradesBasedOnSchoolType } from 'lib/features/grades'
import { GradeOption } from 'interfaces/Grade'
import SelectElement from 'components/inputs/SelectElement'
import RegionService from 'services/RegionService'
import { FilterFormGrid } from 'style'
import SchoolTypeService from 'services/SchoolTypeService'
import { useCountryOptions } from 'hooks/useCountryOptions'
import CountriesService from 'services/CountriesService'

interface Props {}

const loadingAreas = {
    container: 'schoolTypeContainer',
}

const Grades = (props: Props) => {
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>('DE')
    const [selectedRegion, setSelectedRegion] = useState<Region>()
    const [selectedSchoolType, setSelectedSchoolType] = useState<SchoolType | null>(null)

    const [schoolTypeOptions, setSchoolTypeOptions] = useState<SchoolTypeOption[]>()
    const [regionOptions, setRegionOptions] = useState<RegionOption[]>()

    const [data, setTableData] = useState<TableData[]>([])

    const fetchData = useCallback(async () => {
        trackPromise(
            CountriesService.listLanguages(selectedCountry)
                .then((languages) => {
                    if (languages.length === 0) return

                    return RegionService.listRegionsByCountry(selectedCountry, languages[0].code)
                })
                .then((regions) => {
                    if (!regions) return
                    const options = regions?.map((region) => ({
                        data: region,
                        identifier: region.id.toString(),
                        label: region.regionName,
                    }))
                    options && setRegionOptions(options)
                    setSelectedRegion(regions[0])
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting regions.')
                }),
            loadingAreas.container
        )
    }, [selectedCountry])

    const handleGradesOptionUpdate = (gradeOptions: GradeOption[]) => {
        const tableData = gradeOptions.map(({ data }) => ({
            id: data.id,
            columns: [data.id, data.shortName, data.longName ?? '', data.level],
            onClick: routes.gradeRoute(data.id),
        }))
        setTableData(tableData)
    }

    const fetchSchoolTypes = useCallback(() => {
        if (!selectedRegion) return

        trackPromise(
            SchoolTypeService.listSchoolTypesByRegion(selectedRegion.id)
                .then((schoolTypes) => {
                    const options = schoolTypes.map((type) => ({
                        label: type.name,
                        identifier: type.id.toString(),
                        data: type,
                    }))

                    setSchoolTypeOptions(options)

                    if (schoolTypes[0]) {
                        setSelectedSchoolType(schoolTypes[0])
                    } else {
                        setSelectedSchoolType(null)
                    }
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while fetching the school types.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [selectedRegion])

    useEffect(() => {
        if (selectedRegion) {
            fetchSchoolTypes()
        } else {
            setSchoolTypeOptions([])
            setSelectedSchoolType(null)
            setTableData([])
        }
    }, [fetchSchoolTypes, selectedRegion])

    useEffect(() => {
        selectedSchoolType && fetchGradesBasedOnSchoolType([selectedSchoolType], handleGradesOptionUpdate)
    }, [selectedSchoolType])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const countryOptions = useCountryOptions()

    const updateCountryCode = (countryCode: CountryCode) => setSelectedCountry(countryCode)

    const updateRegion = (region: Region) => setSelectedRegion(region)

    const updateSchoolType = (schoolType: SchoolType) => setSelectedSchoolType(schoolType)

    return (
        <section>
            <Stack gutter={10}>
                <h2>Grades</h2>
                <FilterFormGrid gridAmount={3}>
                    <SelectElement label="Country code" noMargin options={countryOptions} onUpdate={updateCountryCode} />
                    {regionOptions ? (
                        <SelectElement label="Region" noMargin options={regionOptions} onUpdate={updateRegion} />
                    ) : null}
                    {schoolTypeOptions ? (
                        <SelectElement
                            label="School Type"
                            noMargin
                            options={schoolTypeOptions}
                            onUpdate={updateSchoolType}
                        />
                    ) : null}
                </FilterFormGrid>
                <Table
                    columns={['#', 'Short Name', 'Long name', 'Level']}
                    data={data}
                    page={0}
                    totalPages={1}
                    totalElements={1}
                    setPage={() => null}
                    area={loadingAreas.container}
                />
            </Stack>
        </section>
    )
}

export default Grades
