import React from 'react'
import { PDFDocument, degrees, rgb } from 'pdf-lib'
import { useEffect, useRef, useCallback, useState, ChangeEvent } from 'react'
import Modal from 'components/modules/Modal'
import { Know, KnowDocument } from 'interfaces/Know'
import styled from 'styled-components'
import Button from 'components/elements/Button'
import { Body1, ButtonText } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import { FormGroup } from 'components/inputs/Input'
import { toast } from 'react-toastify'
import FileUploadService from 'services/FileUploadService'
import { ContentPrefixes } from 'utils/constants/ContentPrefix'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import KnowService from 'services/KnowService'
import { trackPromise } from 'react-promise-tracker'
import { ReactComponent as RotateLeftIcon } from 'assets/icons/rotate_left.svg'
import { ReactComponent as RotateRightIcon } from 'assets/icons/rotate_right.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'

const Container = styled.div`
    display: grid;
    gap: 15px;
    .toolbar {
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }
    iframe {
        width: 100%;
        min-height: 750px;
        border: none;
    }
    .button-container {
        align-items: center;
        justify-content: end;
        display: flex;
        gap: 10px;
        input {
            width: 150px;
            margin: 0;
        }
    }
    .pdf-container {
        position: relative;
        width: 100%;
        min-height: 750px;
        border: 1px solid #ddd;
        background: #f5f5f5;
    }
    .redaction-container {
        width: 100%;
        max-height: 750px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px 0;
    }
    .page-container {
        position: relative;
        display: flex;
        justify-content: center;
        width: fit-content;
    }
    .page-image {
        max-width: 100%;
        height: auto;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    }
    .page-canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: crosshair;
    }
`

interface Props {
    show: boolean
    onClose: () => void
    content: KnowDocument
    know: Know
}

interface RedactionBox {
    pageIndex: number
    x: number
    y: number
    width: number
    height: number
}

const loadingAreas = {
    upload: 'uploadDocument',
}

const KnowDocumentEditor = ({ show, onClose, know, content }: Props) => {
    const [pdfDoc, setPdfDoc] = useState<PDFDocument | null>(null)
    const [pageNumbers, setPageNumbers] = useState<string>('0')
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [isRedacting, setIsRedacting] = useState<boolean>(false)
    const [redactions, setRedactions] = useState<RedactionBox[]>([])
    const [startPoint, setStartPoint] = useState<{ x: number; y: number; pageIndex: number } | null>(null)
    const [dragging, setDragging] = useState<boolean>(false)
    const [pageDimensions, setPageDimensions] = useState<{ width: number; height: number }[]>([])

    const inputRef = useRef<HTMLInputElement>(null)
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const canvasRefs = useRef<Map<number, HTMLCanvasElement>>(new Map())
    const imageRefs = useRef<Map<number, HTMLImageElement>>(new Map())
    const containerRef = useRef<HTMLDivElement>(null)

    const onCloseEditor = () => {
        onClose()
        setIsRedacting(false)
        setRedactions([])
        setPdfDoc(null)
        setPageNumbers('0')
        setCurrentPage(0)
    }

    const registerCanvas = useCallback((canvas: HTMLCanvasElement | null, pageIndex: number) => {
        if (!canvas) return
        canvasRefs.current.set(pageIndex, canvas)
    }, [])

    const registerImage = useCallback((image: HTMLImageElement | null, pageIndex: number) => {
        if (!image) return
        imageRefs.current.set(pageIndex, image)
    }, [])

    const loadPdfAndPages = useCallback(async () => {
        try {
            const response = await fetch(content.contentUrl)
            const blob = await response.blob()
            const arrayBuffer = await blob.arrayBuffer()

            const doc = await PDFDocument.load(arrayBuffer)
            setPdfDoc(doc)

            const pageCount = doc.getPageCount()

            if (inputRef.current) {
                const pagesRange = `1-${pageCount}`
                inputRef.current.value = pagesRange
                setPageNumbers(pagesRange)
            }

            const dimensions = []
            for (let i = 0; i < pageCount; i++) {
                const page = doc.getPage(i)
                dimensions.push(page.getSize())
            }
            setPageDimensions(dimensions)

            const pdfBytes = await doc.save()
            renderInIframe(pdfBytes)
        } catch (error) {
            console.error('Error loading PDF:', error)
            toast.error('Failed to load PDF document')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content])

    const renderInIframe = useCallback((pdfBytes: Uint8Array) => {
        if (!iframeRef.current) return

        const blob = new Blob([pdfBytes], { type: 'application/pdf' })
        const blobUrl = URL.createObjectURL(blob)

        iframeRef.current.src = blobUrl
        iframeRef.current.onload = () => {
            URL.revokeObjectURL(blobUrl)
        }
    }, [])

    useEffect(() => {
        loadPdfAndPages()
    }, [loadPdfAndPages])

    const handleCanvasMouseDown = (e: React.MouseEvent<HTMLCanvasElement>, pageIndex: number) => {
        const canvas = e.currentTarget
        if (!canvas) return

        const rect = canvas.getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top

        setDragging(true)
        setStartPoint({ x, y, pageIndex })
    }

    const handleCanvasMouseMove = (e: React.MouseEvent<HTMLCanvasElement>, pageIndex: number) => {
        if (!dragging || !startPoint || startPoint.pageIndex !== pageIndex) return

        const canvas = e.currentTarget
        const ctx = canvas.getContext('2d')
        if (!ctx) return

        const rect = canvas.getBoundingClientRect()
        const currentX = e.clientX - rect.left
        const currentY = e.clientY - rect.top

        ctx.clearRect(0, 0, canvas.width, canvas.height)

        redactions.forEach((redaction) => {
            if (redaction.pageIndex === pageIndex) {
                ctx.fillStyle = 'rgba(0, 0, 0, 0.7)'
                ctx.fillRect(redaction.x, redaction.y, redaction.width, redaction.height)
            }
        })

        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'
        ctx.fillRect(
            Math.min(startPoint.x, currentX),
            Math.min(startPoint.y, currentY),
            Math.abs(currentX - startPoint.x),
            Math.abs(currentY - startPoint.y)
        )
    }

    const handleCanvasMouseUp = (e: React.MouseEvent<HTMLCanvasElement>, pageIndex: number) => {
        if (!dragging || !startPoint || startPoint.pageIndex !== pageIndex) return

        const canvas = canvasRefs.current.get(pageIndex)
        const image = imageRefs.current.get(pageIndex)

        if (!canvas || !image) return

        const rect = canvas.getBoundingClientRect()
        const currentX = e.clientX - rect.left
        const currentY = e.clientY - rect.top

        if (Math.abs(currentX - startPoint.x) > 5 && Math.abs(currentY - startPoint.y) > 5) {
            const newRedaction: RedactionBox = {
                pageIndex,
                x: Math.min(startPoint.x, currentX),
                y: Math.min(startPoint.y, currentY),
                width: Math.abs(currentX - startPoint.x),
                height: Math.abs(currentY - startPoint.y),
            }

            setRedactions([...redactions, newRedaction])

            const ctx = canvas.getContext('2d')
            if (ctx) {
                ctx.fillStyle = 'rgba(0, 0, 0, 0.7)'
                ctx.fillRect(newRedaction.x, newRedaction.y, newRedaction.width, newRedaction.height)
            }
        }

        setDragging(false)
        setStartPoint(null)
    }

    const handleCanvasMouseLeave = (e: React.MouseEvent<HTMLCanvasElement>, pageIndex: number) => {
        if (!dragging || !startPoint || startPoint.pageIndex !== pageIndex) return

        setDragging(false)
        setStartPoint(null)
    }

    const redrawRedactions = useCallback(
        (pageIndex: number) => {
            const canvas = canvasRefs.current.get(pageIndex)
            if (!canvas) return

            const ctx = canvas.getContext('2d')
            if (!ctx) return

            ctx.clearRect(0, 0, canvas.width, canvas.height)

            redactions.forEach((redaction) => {
                if (redaction.pageIndex === pageIndex) {
                    ctx.fillStyle = 'rgba(0, 0, 0, 0.7)'
                    ctx.fillRect(redaction.x, redaction.y, redaction.width, redaction.height)
                }
            })
        },
        [redactions]
    )

    const handleImageLoaded = useCallback(
        (pageIndex: number) => {
            const image = imageRefs.current.get(pageIndex)
            const canvas = canvasRefs.current.get(pageIndex)

            if (!image || !canvas) return

            canvas.width = image.clientWidth
            canvas.height = image.clientHeight
            canvas.style.width = `${image.clientWidth}px`
            canvas.style.height = `${image.clientHeight}px`

            redrawRedactions(pageIndex)
        },
        [redrawRedactions]
    )

    const applyRedactions = async () => {
        if (!pdfDoc || redactions.length === 0) {
            toast.warning('No redactions to apply')
            return
        }

        try {
            const newPdfDoc = pdfDoc

            for (const redaction of redactions) {
                const pageIndex = redaction.pageIndex
                const image = imageRefs.current.get(pageIndex)

                if (!image || !pageDimensions[pageIndex]) continue

                const imageWidth = image.clientWidth
                const imageHeight = image.clientHeight
                const pdfWidth = pageDimensions[pageIndex].width
                const pdfHeight = pageDimensions[pageIndex].height

                const pdfX = (redaction.x / imageWidth) * pdfWidth
                const pdfY = pdfHeight - ((redaction.y + redaction.height) / imageHeight) * pdfHeight
                const pdfRectWidth = (redaction.width / imageWidth) * pdfWidth
                const pdfRectHeight = (redaction.height / imageHeight) * pdfHeight

                const page = newPdfDoc.getPage(pageIndex)
                page.drawRectangle({
                    x: pdfX,
                    y: pdfY,
                    width: pdfRectWidth,
                    height: pdfRectHeight,
                    color: rgb(0, 0, 0),
                    borderWidth: 0,
                    opacity: 1,
                })
            }

            setPdfDoc(newPdfDoc)
            setRedactions([])
            setIsRedacting(false)

            const pdfBytes = await newPdfDoc.save()
            renderInIframe(pdfBytes)

            toast.success('Redactions applied successfully')
        } catch (error) {
            console.error('Error applying redactions:', error)
            toast.error('Failed to apply redactions')
        }
    }

    const toggleRedactionMode = () => {
        if (isRedacting) {
            applyRedactions()
        } else {
            setIsRedacting(true)
            setRedactions([])
        }
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPageNumbers(event.target.value)
    }

    const isNumber = (text: string) => !Number.isNaN(+text)

    const handleDocumentAction = async (documentAction: (index: number) => void, o?: { isDelete?: boolean }) => {
        if (!pdfDoc || !pageNumbers) {
            toast.warning('You need to select a page/pages first!')
            return
        }

        const { isDelete = false } = o || {}

        try {
            if (isNumber(pageNumbers)) {
                await documentAction(+pageNumbers - 1)
            } else {
                const range = pageNumbers.split('-')
                if (!(isNumber(range[0] ?? '') && isNumber(range[1] ?? ''))) {
                    toast.warning('You need to select a page/pages first!')
                    return
                }

                for (let i = +range[0]; i <= +range[1]; i++) {
                    await documentAction(isDelete ? +range[0] - 1 : i - 1)
                }
            }

            if (currentPage >= pdfDoc.getPageCount()) {
                setCurrentPage(pdfDoc.getPageCount() - 1)
            }

            const newDimensions = []
            for (let i = 0; i < pdfDoc.getPageCount(); i++) {
                const page = pdfDoc.getPage(i)
                newDimensions.push(page.getSize())
            }
            setPageDimensions(newDimensions)

            const pdfBytes = await pdfDoc.save()
            renderInIframe(pdfBytes)
        } catch (error) {
            console.error('Error performing document action:', error)
            toast.error('Failed to perform operation on document')
        }
    }

    const removeAction = (pageNumber: number) => {
        if (!pdfDoc) return
        pdfDoc.removePage(pageNumber)
    }

    const removePage = async () => {
        if (!pdfDoc) return
        await handleDocumentAction(removeAction, { isDelete: true })
    }

    const rotateAction = (pageNumber: number, type: 'right' | 'left') => {
        if (!pdfDoc) return
        const page = pdfDoc.getPage(pageNumber)
        page.setRotation(degrees(page.getRotation().angle + (type === 'left' ? 90 : -90)))
    }

    const rotatePage = async (type: 'left' | 'right') => {
        if (!pdfDoc) return
        await handleDocumentAction((pageNumber) => rotateAction(pageNumber, type))
    }

    const handleDeleteDocument = async () => {
        const confirmResponse = window.confirm(
            `Do you really want to delete this document? Once deleted, you will not be able to restore it.`
        )
        if (!confirmResponse) return

        try {
            await KnowService.deleteDocument(know.uuid, content.uuid)
            toast.success('The document has been deleted!')
            onCloseEditor()
        } catch (error) {
            if (error instanceof ApiError) {
                toast.error('There was an error during deleting a document from know.')
            } else {
                throw error
            }
        }
    }

    const handleDeletePreviousDocument = async () => {
        try {
            await KnowService.deleteDocument(know.uuid, content.uuid)
            toast.success('The document has been replaced!')
            onCloseEditor()
        } catch (error) {
            if (error instanceof ApiError) {
                toast.error('There was an error during deleting the previous document from know.')
            } else {
                throw error
            }
        }
    }

    const handleUploadNewDocumentToKnow = async (filename: string) => {
        try {
            await KnowService.createDocument(know.uuid, filename, content.title, know.documents.length).then(() =>
                handleDeletePreviousDocument()
            )
        } catch (error) {
            if (error instanceof ApiError) {
                toast.error('There was an error adding the file to the know. Try again!')
            } else {
                throw error
            }
        }
    }

    const replaceDocument = async () => {
        if (!pdfDoc) return

        try {
            const pdfBytes = await pdfDoc.save()
            const blob = new Blob([pdfBytes], { type: 'application/pdf' })

            trackPromise(
                FileUploadService.upload(blob, ContentPrefixes.Content)
                    .then((data) => handleUploadNewDocumentToKnow(data.filename))
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            if (error.type === ErrorTypes.InvalidMIMEType) {
                                toast.error(`The uploaded file needs to be a PDF!`)
                            } else {
                                toast.error('There was an error during uploading the file. Try again!')
                            }
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.upload
            )
        } catch (error) {
            console.error('Error replacing document:', error)
            toast.error('Failed to replace document')
        }
    }

    const renderDocumentPages = () => {
        if (!know.knowDocumentPages?.length) {
            return (
                <div style={{ textAlign: 'center' }}>
                    No Know Document Pages available for redaction. If the know was recently created, please wait for the
                    document pages to be generated.
                    <br />
                    Feel free to put know into extended review and redact it later.
                </div>
            )
        }

        return know?.knowDocumentPages
            .sort((a, b) => a.pageNumber - b.pageNumber)
            .map((page, index) => (
                <div key={page.uuid} className="page-container">
                    <img
                        src={page.imageUrl}
                        alt={`Page ${page.pageNumber}`}
                        className="page-image"
                        ref={(el) => registerImage(el, index)}
                        onLoad={() => handleImageLoaded(index)}
                    />
                    <canvas
                        className="page-canvas"
                        ref={(el) => registerCanvas(el, index)}
                        onMouseDown={(e) => handleCanvasMouseDown(e, index)}
                        onMouseMove={(e) => handleCanvasMouseMove(e, index)}
                        onMouseUp={(e) => handleCanvasMouseUp(e, index)}
                        onMouseLeave={(e) => handleCanvasMouseLeave(e, index)}
                    />
                </div>
            ))
    }

    return (
        <Modal show={show} onClose={onCloseEditor} title="Edit PDF" fullWidth>
            <Container>
                <div className="toolbar">
                    <Button
                        onClick={handleDeleteDocument}
                        fullWidth={false}
                        icon={<DeleteIcon />}
                        color="var(--red)"
                        hoverColor="var(--red-dark)"
                    >
                        <ButtonText color={theme.colors.white}>Remove document</ButtonText>
                    </Button>
                    <FormGroup noMargin className="button-container">
                        <Body1
                            color={theme.colors.body2Black}
                        >{`Selected page numbers (also accepts range i.e. 1-3) :`}</Body1>
                        <input ref={inputRef} min={0} max={5} placeholder="Page numbers" onChange={handleInputChange} />
                        <Button
                            onClick={removePage}
                            fullWidth={false}
                            hoverColor={theme.colors.knowunityBlue}
                            icon={<DeleteIcon />}
                        >
                            <ButtonText color={theme.colors.white}>Remove Page</ButtonText>
                        </Button>
                        <Button
                            onClick={() => rotatePage('left')}
                            fullWidth={false}
                            hoverColor={theme.colors.knowunityBlue}
                            icon={<RotateLeftIcon />}
                        >
                            <ButtonText color={theme.colors.white}>90° Right</ButtonText>
                        </Button>
                        <Button
                            onClick={() => rotatePage('right')}
                            fullWidth={false}
                            hoverColor={theme.colors.knowunityBlue}
                            icon={<RotateRightIcon />}
                        >
                            <ButtonText color={theme.colors.white}>90° Left</ButtonText>
                        </Button>
                        <Button
                            onClick={replaceDocument}
                            fullWidth={false}
                            color={theme.colors.knowunityBlueDark}
                            hoverColor={theme.colors.knowunityBlue}
                            loadingArea={loadingAreas.upload}
                        >
                            <ButtonText color={theme.colors.white}>Replace document</ButtonText>
                        </Button>
                        <Button
                            onClick={toggleRedactionMode}
                            fullWidth={false}
                            color={isRedacting ? 'var(--red)' : theme.colors.knowunityBlue}
                            hoverColor={isRedacting ? 'var(--red-dark)' : theme.colors.knowunityBlueDark}
                        >
                            <ButtonText color={theme.colors.white}>
                                {isRedacting ? 'Apply Redactions' : 'Start Redacting'}
                            </ButtonText>
                        </Button>
                    </FormGroup>
                </div>

                <div ref={containerRef} className={isRedacting ? 'redaction-container' : 'pdf-container'}>
                    {isRedacting ? (
                        <>{renderDocumentPages()}</>
                    ) : (
                        <iframe
                            ref={iframeRef}
                            style={{
                                width: '100%',
                                height: '750px',
                                border: 'none',
                            }}
                            title="PDF Document"
                        />
                    )}
                </div>
            </Container>
        </Modal>
    )
}

export default KnowDocumentEditor
