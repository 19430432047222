export default function isValidHttpUrl(message: string): boolean {
    let url

    try {
        url = new URL(message)
    } catch (_) {
        return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
}

export const hasWhiteSpace = (text: string) => /\s/.test(text)

export function isValidLink(text: string): boolean {
    if (isValidHttpUrl(text)) {
        return true
    }

    if (text.startsWith('www.') && text.length > 4 && text.includes('.', 4)) {
        return true
    }

    const domainPattern = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](\.[a-zA-Z]{2,})+$/
    if (domainPattern.test(text)) {
        return true
    }

    return false
}
