import React, { useCallback, useEffect, useState, useRef } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { Link, RouteComponentProps, useHistory } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import ExperimentForm from './ExperimentForm'
import styled from 'styled-components'
import ExperimentationPlatformService from './ExperimentsService'
import { Experiment as ExperimentInterface, UpdateExperiment, ExperimentStatuses } from 'interfaces/Experiment'
import CopyIcon from 'components/elements/CopyIcon'
import { formatDateTimeUTC } from 'utils'
import VariantForm from './VariantForm'
import { MetaBox, EditButton } from 'style'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import {
    formatJiraTicketsInformation,
    generateABTestingExperimentPlatformLink,
    generateMetabaseAIExperimentPlatformLink,
    generateMetabaseCoreExperimentPlatformLink,
    generateMetabaseMonetizationExperimentPlatformLink,
    generateOverallEvaluationPlatformLink,
    generateTutoringExperimentPlatformLink,
} from 'lib/features/experiments'
import Meta from 'components/modules/Head'
import { toast } from 'react-toastify'
import ExperimentsService from './ExperimentsService'
import Button from 'components/elements/Button'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import theme from 'lib/constants/theme'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Input } from 'components/inputs/Input'

interface Props extends RouteComponentProps<{ uuid: string }> {}

const loadingAreas = {
    container: 'experimentationContainer',
    duplicate: 'experimentDuplicate',
    approve: 'experimentApprove',
    start: 'experimentStart',
    stop: 'experimentStop',
    pause: 'experimentPause',
    resume: 'experimentResume',
    updateEndDate: 'experimentUpdateEndDate',
}

const Container = styled.section`
    padding-bottom: 25px;
    display: grid;
    gap: 10px;
`

const ButtonGroup = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
`

const StatusSection = styled.div`
    background-color: ${theme.colors.darkViolet};
    border-radius: ${theme.borderRadius.card};
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid ${theme.colors.knowunityBlue};

    h4 {
        color: ${theme.colors.knowunityBlue};
        margin: 0 0 15px 0;
    }

    .status-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    .status-item {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .status-label {
        color: ${theme.colors.knowunityBlue};
        font-size: 0.9em;
    }

    .status-value {
        font-weight: 500;
    }

    .approval-warning {
        margin-top: 15px;
        padding: 10px;
        border-radius: ${theme.borderRadius.card};
        background-color: ${theme.colors.darkViolet};
        border: 1px solid ${theme.colors.knowunityBlue};
        color: ${theme.colors.knowunityBlue};
    }
`

const EndDateForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

interface StartFormData {
    endOn: string
}

const Experiment = (props: Props) => {
    const [experiment, setExperiment] = useState<ExperimentInterface | null>(null)
    const [showEditExperimentModal, toggleEditExperimentModal] = useToggleState(false)
    const [showStartModal, toggleStartModal] = useToggleState(false)
    const [showStopModal, toggleStopModal] = useToggleState(false)
    const [showUpdateEndDateModal, toggleUpdateEndDateModal] = useToggleState(false)
    const formRef = useRef<FormHandles>(null)
    const startFormRef = useRef<FormHandles>(null)
    const history = useHistory()
    const uuid = props.match.params.uuid
    const isNew = !uuid || uuid === 'create'

    const updateExperiment = (experiment: ExperimentInterface) => {
        setExperiment(experiment)
        showEditExperimentModal && toggleEditExperimentModal()
    }

    const fetchData = useCallback(() => {
        if (!uuid || isNew) return

        trackPromise(
            ExperimentationPlatformService.get(uuid)
                .then((experiment) => {
                    const sortedVariants = experiment.variants.sort(
                        (a, b) => +a.identifier.slice(-1) - +b.identifier.slice(-1)
                    )
                    setExperiment({ ...experiment, variants: sortedVariants })
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting experiments.')
                    } else {
                        throw error
                    }
                }),

            loadingAreas.container
        )
    }, [uuid, isNew])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const approveExperiment = () => {
        if (!experiment) return null

        trackPromise(
            ExperimentsService.approve(uuid)
                .then((experiment) => {
                    updateExperiment(experiment)
                    toast.success('The experiment have been approved!')
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.Forbidden) {
                            toast.error(`Experiments can only be accepted by Lucas, Gregor or Benedict.`)
                        } else {
                            error.handleUnknown('An error occurred while approving experiment.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.duplicate
        )
    }

    const duplicateExperiment = () => {
        if (!experiment) return null

        const { startOn, endOn, status, uuid, identifier, squad, variants, ...rest } = experiment

        const requestBody: UpdateExperiment = {
            identifier: `${identifier}_duplicate`,
            variants: variants,
            squad: squad ?? '',
            ...rest,
        }

        trackPromise(
            ExperimentsService.create(requestBody)
                .then((experiment) => {
                    updateExperiment(experiment)
                    toast.success('The experiment have been duplicated saved!')
                    history.push(routes.experimentRoute(experiment.uuid))
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while duplicating experiment.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.duplicate
        )
    }

    const startExperiment = (data: StartFormData) => {
        if (!experiment) return null

        if (!data.endOn) {
            startFormRef.current!.setErrors({
                endOn: 'End date is required',
            })
            return
        }

        const startOn = new Date()
        const endOn = new Date(data.endOn)

        if (startOn > endOn) {
            startFormRef.current!.setErrors({
                endOn: 'End date cannot be in the past',
            })
            return
        }

        trackPromise(
            ExperimentsService.start(uuid, startOn.toISOString(), endOn.toISOString())
                .then((experiment) => {
                    updateExperiment(experiment)
                    toggleStartModal()
                    toast.success('The experiment has been started!')
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while starting experiment.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.start
        )
    }

    const stopExperiment = () => {
        if (!experiment) return null

        trackPromise(
            ExperimentsService.stop(uuid)
                .then((experiment) => {
                    updateExperiment(experiment)
                    toggleStopModal()
                    toast.success('The experiment has been stopped!')
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while stopping experiment.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.stop
        )
    }

    const pauseExperiment = () => {
        if (!experiment) return null

        trackPromise(
            ExperimentsService.pause(uuid)
                .then((experiment) => {
                    updateExperiment(experiment)
                    toast.success('The experiment has been paused!')
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while pausing experiment.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.pause
        )
    }

    const resumeExperiment = () => {
        if (!experiment) return null

        trackPromise(
            ExperimentsService.resume(uuid)
                .then((experiment) => {
                    updateExperiment(experiment)
                    toast.success('The experiment has been resumed!')
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while resuming experiment.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.resume
        )
    }

    const updateEndDate = (data: { endOn: string }) => {
        if (!experiment) return null

        const endDate = new Date(data.endOn)
        const startDate = experiment.startOn ? new Date(experiment.startOn) : null

        if (startDate && endDate < startDate) {
            formRef.current!.setErrors({
                endOn: 'End date cannot be before start date',
            })
            return
        }

        trackPromise(
            ExperimentsService.updateEndDate(uuid, new Date(data.endOn).toISOString())
                .then((experiment) => {
                    updateExperiment(experiment)
                    toggleUpdateEndDateModal()
                    toast.success('The end date has been updated!')
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while updating end date.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.updateEndDate
        )
    }

    const renderExperimentControls = () => {
        if (!experiment) return null

        const isApproved = experiment.approvedByUserUuid && experiment.approvedOn
        const isRunning = experiment.status === ExperimentStatuses.Running
        const isPaused = experiment.isPaused
        const isCompleted = experiment.status === ExperimentStatuses.Completed

        if (!isApproved) {
            return (
                <Button fullWidth={false} onClick={approveExperiment} loadingArea={loadingAreas.approve}>
                    Approve this experiment
                </Button>
            )
        }

        if (isCompleted) {
            return null
        }

        if (isPaused) {
            return (
                <ButtonGroup>
                    <Button fullWidth={false} onClick={toggleStopModal} loadingArea={loadingAreas.stop}>
                        Stop experiment
                    </Button>
                    <Button fullWidth={false} onClick={resumeExperiment} loadingArea={loadingAreas.resume}>
                        Resume experiment
                    </Button>
                </ButtonGroup>
            )
        }

        if (isRunning) {
            return (
                <ButtonGroup>
                    <Button fullWidth={false} onClick={toggleStopModal} loadingArea={loadingAreas.stop}>
                        Stop experiment
                    </Button>
                    <Button fullWidth={false} onClick={pauseExperiment} loadingArea={loadingAreas.pause}>
                        Pause experiment
                    </Button>
                    <Button fullWidth={false} onClick={toggleUpdateEndDateModal} loadingArea={loadingAreas.updateEndDate}>
                        Update end date
                    </Button>
                </ButtonGroup>
            )
        }

        return (
            <Button fullWidth={false} onClick={toggleStartModal} loadingArea={loadingAreas.start}>
                Start experiment
            </Button>
        )
    }

    return (
        <Container>
            <Meta title="Experiment" />
            <h2>Experiment</h2>
            <GoBackButton route={routes.experimentsRoute} routeName="Experiments" />
            {!isNew && (
                <Box>
                    <Stack>
                        <MetaBox>
                            <h3>Information</h3>
                            <EditButton onClick={toggleEditExperimentModal} />
                        </MetaBox>
                        <LoadingArea area={loadingAreas.container}>
                            {experiment && (
                                <>
                                    <StatusSection>
                                        <h4>Experiment Status</h4>
                                        {renderExperimentControls()}
                                        <div className="status-grid">
                                            <div className="status-item">
                                                <span className="status-label">Status</span>
                                                <span className="status-value">{experiment.status}</span>
                                            </div>
                                            <div className="status-item">
                                                <span className="status-label">Approval</span>
                                                <span className="status-value">
                                                    {experiment?.approvedOn && experiment?.approvedByUserUuid ? (
                                                        <>
                                                            {formatDateTimeUTC(experiment.approvedOn)} by{' '}
                                                            <Link
                                                                to={routes.userRoute(experiment.approvedByUserUuid)}
                                                                target="_blank"
                                                            >
                                                                user
                                                            </Link>
                                                        </>
                                                    ) : (
                                                        'Not approved'
                                                    )}
                                                </span>
                                            </div>
                                            {experiment?.startOn && (
                                                <div className="status-item">
                                                    <span className="status-label">Starts On</span>
                                                    <span className="status-value">
                                                        {formatDateTimeUTC(experiment.startOn, true)}
                                                    </span>
                                                </div>
                                            )}
                                            {experiment?.endOn && (
                                                <div className="status-item">
                                                    <span className="status-label">Ends On</span>
                                                    <span className="status-value">
                                                        {formatDateTimeUTC(experiment.endOn, true)}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        {!(experiment.approvedByUserUuid && experiment.approvedOn) && (
                                            <div className="approval-warning">
                                                You can only start the experiment after it has been approved by Lucas, Gregor
                                                or Benedict.
                                            </div>
                                        )}
                                    </StatusSection>

                                    <p>
                                        <strong>Uuid:</strong>&nbsp;
                                        {experiment.uuid}
                                        <CopyIcon text={experiment.uuid} />
                                    </p>
                                    <p>
                                        <strong>Name:</strong>&nbsp;
                                        {experiment.name}
                                    </p>
                                    <p>
                                        <strong>Identifier:</strong>&nbsp;
                                        {experiment.identifier}
                                        <CopyIcon text={experiment.identifier} />
                                    </p>
                                    <p>
                                        <strong>Description:</strong>&nbsp;
                                        {experiment.description}
                                    </p>
                                    <p>
                                        <strong>Cohort:</strong>&nbsp;
                                        {experiment?.cohortCode ?? 'Everyone'}
                                    </p>
                                    <p>
                                        <strong>Squad:</strong>&nbsp;
                                        {experiment?.squad ?? 'N/A'}
                                    </p>
                                    <p>
                                        <strong>Group:</strong>&nbsp;
                                        {experiment?.group ?? 'N/A'}
                                    </p>
                                    <p>
                                        <strong>Salt:</strong>&nbsp;
                                        {experiment.salt}
                                    </p>
                                    <p>
                                        <strong>Is cohort exclusive:</strong>&nbsp;
                                        {experiment?.isExclusiveCohort ? 'Yes' : 'No'}
                                    </p>

                                    <p>
                                        <strong>Rollout fraction:</strong>&nbsp;
                                        {experiment.rolloutFraction ?? 'Not specified'}
                                    </p>
                                    {experiment?.minimumAppBuildNumber ? (
                                        <p>
                                            <strong>Minimum App Build Number:</strong>&nbsp;
                                            {experiment.minimumAppBuildNumber}
                                        </p>
                                    ) : null}

                                    <h3>Metabase Evaluation</h3>
                                    <a
                                        href={generateOverallEvaluationPlatformLink(experiment)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link to Overall Evaluation Dashboard
                                    </a>
                                    <h3>AB Testing Platform (deprecated)</h3>
                                    <a
                                        href={generateMetabaseMonetizationExperimentPlatformLink(experiment)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link to Monetization Metabase Evaluation
                                    </a>
                                    <a
                                        href={generateMetabaseCoreExperimentPlatformLink(experiment)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link to Core Metabase Evaluation
                                    </a>
                                    <a
                                        href={generateMetabaseAIExperimentPlatformLink(experiment)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link to AI Metabase Evaluation
                                    </a>
                                    <a
                                        href={generateTutoringExperimentPlatformLink(experiment)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link to Tutoring Dashboard
                                    </a>
                                    <a
                                        href={generateABTestingExperimentPlatformLink(experiment)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link to AB Testing Tool
                                    </a>

                                    <h3>Notion Tickets</h3>
                                    <Stack>{formatJiraTicketsInformation(experiment.jiraTicketUrl)}</Stack>
                                    <br />

                                    <Button
                                        fullWidth={false}
                                        onClick={duplicateExperiment}
                                        loadingArea={loadingAreas.duplicate}
                                    >
                                        Duplicate this experiment
                                    </Button>
                                </>
                            )}
                        </LoadingArea>
                    </Stack>
                </Box>
            )}
            {isNew ? (
                <ExperimentForm experiment={experiment} isNew={isNew} updateExperiment={updateExperiment} />
            ) : (
                <>
                    {experiment ? (
                        <VariantForm
                            selectedGroup={experiment.group}
                            variants={experiment.variants}
                            disableAddingNewVariants
                        />
                    ) : null}
                </>
            )}
            <Modal
                show={showEditExperimentModal}
                title="Update Experiment information"
                onClose={toggleEditExperimentModal}
                fullWidth
            >
                <ExperimentForm experiment={experiment} isNew={isNew} updateExperiment={updateExperiment} />
            </Modal>
            <Modal show={showStartModal} title="Start Experiment" onClose={toggleStartModal}>
                <EndDateForm ref={startFormRef} onSubmit={startExperiment}>
                    <Input name="endOn" placeholder="End date" type="date" />
                    <Button fullWidth loadingArea={loadingAreas.start}>
                        Start
                    </Button>
                </EndDateForm>
            </Modal>
            <Modal show={showStopModal} title="Stop Experiment" onClose={toggleStopModal}>
                <Stack>
                    <p>Do you really want to stop the experiment? You won&apos;t be able to restart the experiment.</p>
                    <Button fullWidth onClick={stopExperiment} loadingArea={loadingAreas.stop}>
                        Yes, stop experiment
                    </Button>
                </Stack>
            </Modal>
            <Modal show={showUpdateEndDateModal} title="Update End Date" onClose={toggleUpdateEndDateModal}>
                <EndDateForm ref={formRef} onSubmit={updateEndDate}>
                    <Input name="endOn" placeholder="End date" type="date" />
                    <Button fullWidth loadingArea={loadingAreas.updateEndDate}>
                        Update
                    </Button>
                </EndDateForm>
            </Modal>
        </Container>
    )
}

export default Experiment
