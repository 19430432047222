import { CSVData } from 'components/FileUpload/CSVImport'
import { KnowerBonusPayment } from 'interfaces/KnowerBonusPayment'
import { isUUID } from 'utils'

export const STANDARD_HEADERS = ['knowerUUID', 'amount', 'currency']

export enum HeaderLabels {
    KnowerUuid = 'Knower UUID',
    Amount = 'Amount',
    Currency = 'Currency',
}

const validateCell = (cell: string | undefined) => cell && cell?.length > 0

const returnNotValidKnowerUuidMessage = (cellData: string) =>
    `Cell data: (${cellData?.length > 0 ? cellData : 'empty'}) is not a valid Knower Uuid value`

export const returnMissingToastMessage = (cellData: string, columnName: string) =>
    `Cell data: (${cellData?.length > 0 ? cellData : 'empty'}) have no mathching values in ${columnName} row`

const parseAndValidateAmountColumn = (text: string) => !Number.isNaN(+text)

const parseAndValidateKnowerUuidColumn = (knowerUuid: string) => isUUID(knowerUuid)

const parseAndValidateCurrencyColumn = (currency: string) => !!currency

export const processKnowerBonusPaymentCSVData = (
    cvsData: CSVData[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toast: any
) => {
    const array: KnowerBonusPayment[] = []
    for (const row of Object.values(cvsData)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formattedRow: any = {}

        let i = 0
        for (const [columnName, cellData] of Object.entries(row)) {
            switch (columnName) {
                case HeaderLabels.KnowerUuid:
                    if (parseAndValidateKnowerUuidColumn(cellData)) {
                        formattedRow[STANDARD_HEADERS[i]] = cellData
                    } else {
                        toast.error(returnNotValidKnowerUuidMessage(cellData))
                        return
                    }
                    i++
                    break
                case HeaderLabels.Currency:
                    if (parseAndValidateCurrencyColumn(cellData)) {
                        formattedRow[STANDARD_HEADERS[i]] = cellData
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    i++
                    break
                case HeaderLabels.Amount:
                    if (parseAndValidateAmountColumn(cellData)) {
                        formattedRow[STANDARD_HEADERS[i]] = +cellData
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    i++
                    break
                default:
                    if (validateCell(cellData)) {
                        formattedRow[STANDARD_HEADERS[i]] = cellData
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    i++
                    break
            }
        }

        array.push(formattedRow)
    }
    return array
}
